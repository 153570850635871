import { useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const GetServiceStuffList = () => {
  const [response, setResponse] = useState("");
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>دریافت اطلاعات کالا و خدمات</h5>
        </div>
        <div className="card-body">
          <button className="btn btn-success" onClick={getData}>
            دریافت اطلاعات <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
          </button>
          <hr />
          <div className="mt-3">
            <div className="text-primary my-3">نتیجه درخواست: </div>
            <span>{response}</span>
          </div>
        </div>
      </div>
    </div>
  );
  //-----------------------------------------
  async function getData(e) {
    const spinner = document.getElementsByClassName("fa-spinner");
    try {
      e.target.classList.add("disabled");
      spinner[0].classList.remove("d-none");

      const result = await axiosConfig.get("/Client/Tax/GetServiceStuffList");
      if (result.data.status === "success") {
        setResponse(result.data.data);
        toast.success(result.data.message);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      if (error.message === "Network Error") toast.error(PublicConsts.Axios_NetworkConnectionError);
      else toast.error(PublicConsts.TryCatch_ErrorMessage + error.message);
    }
    e.target.classList.remove("disabled");
    spinner[0].classList.add("d-none");
  }
  //-----------------------------------------
};

export default GetServiceStuffList;
