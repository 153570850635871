import { useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import FormInput from "../../htmlForm/formInput";
import { PublicConsts } from "../../../publicConsts";

const InquiryByTime = () => {
  const [response, setResponse] = useState("");
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>استعلام با زمان</h5>
        </div>
        <div className="card-body">
          <FormInput label="زمان" name="time" placeHolder="مثال : 1402/02/02" />
          <button className="btn btn-sm btn-success" onClick={getData}>
          <i className="bi bi-search mx-1"></i>
            دریافت اطلاعات <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
          </button>
          <hr />
          <div className="mt-3">
            {response !== "" ? (
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>شماره رفرنس</th>
                      <th>کد Uid</th>
                      <th>وضعیت</th>
                    </tr>
                  </thead>
                  <tbody>
                    {response.map((invoice) => (
                      <tr>
                        <td>{invoice.ReferenceNumber}</td>
                        <td>{invoice.Uid}</td>
                        <td>{invoice.Status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
  //-----------------------------------------
  async function getData(e) {
    const spinner = document.getElementsByClassName("fa-spinner");
    try {
      const time = document.getElementById("time").value;
      if (time === null || time === "") return toast.error(PublicConsts.Validation_EnterTime);

      e.target.classList.add("disabled");
      spinner[0].classList.remove("d-none");

      await axiosConfig
        .get(`/Client/Tax/InqueryByTime?time=${time}`)
        .then((result) => {
          if (result.data.status === "success") {
            setResponse(result.data.data);
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {});
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner[0].classList.add("d-none");
  }
  //-----------------------------------------
};

export default InquiryByTime;
