import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";
import "../../../assets/css/invoice-print.css";



const InvoicePrint = () => {

    const [invoiceDetails, setInvoiceDetails] = useState(null);
    useEffect(() => {
      getDetails();
    }, []);

    return(
        <>
        {invoiceDetails !== null ? (
            <div className="print">
                <div className="page-title-bar col-12">
                    <span className="title-text"></span> 
                    <span className="float-end"> 
                    <button onClick={print} className="btn btn-sm btn-success pl-3">
                        <i className="fa fa-print mx-1" aria-hidden="true"></i> 
                        پرینت
                    </button>
                    </span>
                </div>
                <div className="container-xl" style={{marginTop: '10px'}}>
                    <div id="invoice-header" className="row">
                        {/* <div className="col-4 text-center">
                        </div> */}
                        <div className="col-12">
                            <h1 className="fw-bold pt-4 text-center">صورت حساب فروش</h1>
                        </div>
                        {/* <div className="col-2 text-right pt-3">
                            <p><b>شماره فاکتور: </b>{invoiceDetails.Id}</p>
                            <p><b>تاریخ: </b><bdi>{invoiceDetails.InvoiceDate}</bdi></p>
                        </div> */}
                    </div>
                    <div className="row">
                    <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-center" colSpan="11">مشخصات صورتحساب</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="11" className="text-right">
                                        <div className="row invoice-title-bold">
                                            <div className="col-5">
                                            <p><b>الگوی صورتحساب: </b>{invoiceDetails.InvoicePattern}</p>
                                                <p><b>موضوع صورتحساب: </b>{invoiceDetails.InvoiceSubject}</p>
                                            </div>
                                            <div className="col-3">
                                                <p><b>تاریخ و زمان صدور: </b><bdi>{invoiceDetails.InvoiceDate + ' - ' + invoiceDetails.InvoiceTime}</bdi></p>
                                                <p><b>سریال صورتحساب: </b>{invoiceDetails.Id}</p>
                                            </div>
                                            <div className="col-4">
                                                <p><b>نوع صورتحساب: </b>{invoiceDetails.InvoiceType}</p>
                                                
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-center" colSpan="11">مشخصات فروشنده</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="11" className="text-right">
                                    <div className="row invoice-title-bold">
                                        <div className="col-5">
                                            <p><b>نام: </b>{invoiceDetails.UserFullName}</p>
                                            <p><b>نشانی شرکت: </b></p>
                                        </div>
                                        <div className="col-3">
                                            <p><b>شماره اقتصادی: </b>{invoiceDetails.UserEconomicCode}</p>
                                            <p><b>کد پستی: </b><bdi></bdi></p>
                                        </div>
                                        <div className="col-4">
                                            <p><b>شناسه ملی: </b>-</p>
                                            <p><b>تلفن: </b><bdi></bdi></p>
                                        </div>
                                        <div className="col-4">
                                            <p><b>شماره ثبت: </b></p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-center" colSpan="11">مشخصات خریدار</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="11" className="text-right">
                                    <div className="row invoice-title-bold">
                                        <div className="col-5 ">
                                            <p><b>نام: </b>{invoiceDetails.Fullname}</p>
                                            <p><b>شماره اقتصادی/شماره ملی: </b>{invoiceDetails.NationalCode}</p>
                                        </div>
                                        <div className="col-3">
                                            <p><b>شناسه ملی: </b>-</p>
                                            <p><b>شماره ثبت: </b>-</p>
                                        </div>
                                        <div className="col-4">
                                            <p><b>نشانی: </b>{invoiceDetails.Address}</p>
                                            <p><b>کد پستی: </b>{invoiceDetails.PostCode}</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table order-items-table">
                        <thead>
                            <tr>
                                <th className="text-center" colSpan="12">
                                    مشخصات کالا یا خدمات مورد معامله
                                    <div className="mt-1 text-center fw-normal"><small>تمام قیمت ها به <b>(ریال)</b> می باشد</small></div>
                                </th>
                            </tr>
                        </thead>
                        <thead>
                        <tr className="text-center">
                            <th className="align-middle">ردیف</th>
                            <th className="align-middle">شناسه کالا</th>
                            <th className="align-middle">شرح</th>
                            <th className="align-middle">واحد</th>
                            <th className="align-middle">تعداد/مقدار</th>
                            <th className="align-middle">مبلغ واحد</th>
                            <th className="align-middle">مبلغ قبل از تخفیف</th>
                            <th className="align-middle">مبلغ تخفیف</th>
                            <th className="align-middle">مبلغ بعد از تخفیف</th>
                            <th className="align-middle">نرخ مالیات بر ارزش افزوده</th>
                            <th className="align-middle">مبلغ ارزش افزوده</th>
                            <th className="align-middle">مبلغ کل</th>
                        </tr>
                        </thead>
                        
                        {invoiceDetails.InvoiceProducts.length > 0 ? (
                        <tbody>
                            {invoiceDetails.InvoiceProducts.map((product, index) => (
                                <tr className="text-center" key={product.Id}>
                                    <td>{index + 1}</td>
                                    <td>{product.ProductId}</td>
                                    <td>{product.DescriptionOfID}</td>
                                    <td>{product.Unit}</td>
                                    <td>{product.Quantity}</td>
                                    <td>{product.Price.toLocaleString()}</td>
                                    <td>{product.PriceBeforeDiscount.toLocaleString()}</td>
                                    <td>{product.Discount.toLocaleString()}</td>
                                    <td>{product.PriceAfterDiscount.toLocaleString()}</td>
                                    <td>{product.Vat}%</td>
                                    <td>{product.VatPrice.toLocaleString()}</td>
                                    <td>{product.FinalPrice.toLocaleString()}</td>
                                </tr>
                            ))}
                            <tr>
                                <th colSpan="4" className="text-right">جمع کل</th>
                                <th className="text-center">{invoiceDetails.InvoiceTotals.QuantitySum.toLocaleString()}</th>
                                <th className="text-center">{invoiceDetails.InvoiceTotals.PriceSum.toLocaleString()}</th>
                                <th className="text-center">{invoiceDetails.InvoiceTotals.PriceBeforeDiscountSum.toLocaleString()}</th>
                                <th className="text-center">{invoiceDetails.InvoiceTotals.DiscountSum.toLocaleString()}</th>
                                <th className="text-center">{invoiceDetails.InvoiceTotals.PriceAfterDiscountSum.toLocaleString()}</th>
                                <th className="text-center">-</th>
                                <th className="text-center">{invoiceDetails.InvoiceTotals.VatPriceSum.toLocaleString()}</th>
                                <th className="text-center">{invoiceDetails.InvoiceTotals.FinalPriceSum.toLocaleString()}</th>
                            </tr>
                            
                        </tbody>
                        ):(<div className="alert alert-danger">کالاهای صورتحساب یافت نشد</div>)}
                    </table>
                    </div>
                </div>
            </div>
            ):(<div className="alert alert-danger">صورتحساب یافت نشد</div>)}
        </>
        );
        //------------------------------------------------------------------
        async function getDetails() {

            //document.querySelector("footer").style.display = "none";
            
            try {
                    const invoiceId = window.location.pathname.split("/").pop();

                    await axiosConfig
                    .get(`/Client/Invoices/GetInvoice/${invoiceId}`)
                    .then((result) => {
                    if (result.data.status === "success") {
                        setInvoiceDetails(result.data.data)
                    } else {
                        toast.error(result.data.message);
                    }
                    })
                    .catch((error) => {});

            } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
            }
        }
        function print(){
            window.print()
        }
        };

        export default InvoicePrint;

