const FormRadio = ({
  label,
  lableClassName,
  id,
  name,
  onChange,
  value,
  required,
  defChecked,
  disabled,
}) => {
  return (
    <>
      <input
        type="radio"
        className="form-check-input mx-1"
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        required={required}
        defaultChecked={defChecked}
        disabled = {disabled}
      />
      <label htmlFor={id} className={lableClassName}>
        {label}
      </label>
    </>
  );
};

export default FormRadio;
