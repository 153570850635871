const RegisterResult = () => {

  //-------------------------------------------------------------------------------------------

    return (
      <div className="pt-3">
      <div>
        <div className="card">
          <div className={`card-header bg-info text-white`}>
            <h5>نتیجه ثبت نام</h5>
          </div>
          <div className="card-body">
          <div className="alert alert-success">ثبت نام با موفقیت انجام شد</div>
          <a href="/Login">انتقال به صفحه ورود</a>
          </div>
        </div>
      </div>
      <div className="mt-3">
      <a href='/' className=' btn tbn-sm btn-primary'>صفحه اصلی</a>
    </div>
    </div>
    );
    //---------------------------------------------------
  };
  
  export default RegisterResult;
  