import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";
import axiosConfig from "../../../axiosConfig";
import FormInput from "../../htmlForm/formInput";


const SaleAgents = () => {

  const [agents, setAgents] = useState([]);
  const [validationErrors, setvalidationErrors] = useState([]);
  // for Update------------------------------------------------------------
  const [agentUpdateDetails, setAgentUpdateDetails] = useState([]);

  useEffect(() => {
    getAgents();
  }, []);

  return (
    <>
    <div className="card shadow-lg table-responsive mb-3">
      <div className="card-header">
        <span className="h5">لیست نماینده های فروش</span>
        <button
          type="button"
          className="btn btn-primary float-end"
          data-bs-toggle="modal"
          data-bs-target="#agentModal"
          onClick={() => {
            setAgentUpdateDetails([]);
          }}
        >
          <i className="fa fa-plus mx-1"></i>نماینده جدید
        </button>
      </div>
      <div className="card-body">
        <div id="loadingContainer" className="alert alert-danger d-none">
          در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
        </div>
        <table className="table table-striped text-center">
          <thead>
            <tr>
              <th>نام</th>
              <th>نام خانوادگی</th>
              <th>موبایل</th>
              <th>تاریخ عضویت</th>
              <th>تعداد کد تخفیف</th>
              <th>تعداد فروش</th>
              <th>عملیات</th>
            </tr>
          </thead>
          <tbody>
            {agents.length !== 0 ? (
              <>
                {agents.map((agent) => (
                  <tr key={agent.Id}>
                    <td>{agent.Name}</td>
                    <td>{agent.Family}</td>
                    <td>{agent.Mobile}</td>
                    <td>{agent.CreateDate}</td>
                    <td>{agent.DiscountCodeCount}</td>
                    <td>{agent.SaleCount}</td>
                    <td>
                    <button
                        type="button"
                        className="btn btn-sm btn-warning text-white mx-1 mb-1"
                        title="ویرایش"
                        onClick={() => {
                          updateAgentModal(agent.Id);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#agentModal"
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="12">
                  <div className="alert alert-danger">موردی یافت نشد</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>

    {/* Modal------------------------------------------------------------------- */}
    <div className="modal fade" id="agentModal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                افزودن نماینده فروش
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#agentModal"
              ></button>
            </div>
            <div className="modal-body">
              {validationErrors.length > 0 ? (
                <div className="alert alert-danger">
                  {validationErrors.map((item) => (
                    <ul>
                      <li>{item}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                ""
              )}
              <form id="formData">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                  <div className="col">
                    <FormInput
                      label="نام"
                      name="Name"
                      defValue={agentUpdateDetails.Name}
                      required
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="نام خانوادگی"
                      name="Family"
                      defValue={agentUpdateDetails.Family}
                      required
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="موبایل"
                      name="Mobile"
                      defValue={agentUpdateDetails.Mobile}
                      required
                    />
                  </div>
                </div>

                {agentUpdateDetails.Id ? (
                  <>
                    <input type="hidden" name="Id" value={agentUpdateDetails.Id} />
                    <input type="hidden" name="userId" value={agentUpdateDetails.UserId} />
                    <button
                      type="button"
                      className="btn btn-success mx-1 mb-1"
                      onClick={updateAgent}
                    >
                      <i className="fa fa-edit mx-1"></i>
                      ویرایش <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button>
                  </>
                ) : (
                  <>
                    <input type="hidden" name="userId" value={0} />
                    <button
                      type="button"
                      className="btn btn-success mx-1 mb-1"
                      onClick={createAgent}
                    >
                      <i className="fa fa-send mx-1"></i>
                      ثبت <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  );
  //-------------------------------------------------------
  async function getAgents() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/AdminPanel/SaleAgents/GetAgents/`)
        .then((result) => {
          if (result.data.status === "success") 
            setAgents(result.data.data);
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function createAgent(e) {
    const spinner = document.getElementsByClassName("fa-spinner")[0];

    try {
      e.target.classList.add("disabled");
      spinner.classList.remove("d-none");
      //-----------------------------------
      const form = document.getElementById("formData");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      await axiosConfig
        .post(`/AdminPanel/SaleAgents/CreateAgent/`, formDetails)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            getAgents();
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          const errorsMessages = [];

          if (error.response.data.title === "One or more validation errors occurred.") {
            toast.error(PublicConsts.Validation_CompleteAllRequirements);

            //Convert Errors(json) to Array
            Object.keys(errors).forEach((key) => errorsMessages.push(errors[key][0]));

            setvalidationErrors(errorsMessages);
          }
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  async function updateAgentModal(id) {

    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/AdminPanel/SaleAgents/GetAgent/${id}`)
        .then((result) => {
          if (result.data.status === "success") setAgentUpdateDetails(result.data.data);
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function updateAgent() {
    try {
      const form = document.getElementById("formData");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      let result = await axiosConfig.put(`/AdminPanel/SaleAgents/UpdateAgent`, formDetails);
      if (result.data.status === "success") {
        toast.success(result.data.message);
        // setInvoiceProductList([]);
        // form.reset();
      } else toast.error(result.data.message);
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
  };
  export default SaleAgents;
  