import { useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import FormInput from "../../htmlForm/formInput";
import { PublicConsts } from "../../../publicConsts";

const InquiryByDateRange = () => {
  const [response, setResponse] = useState("");
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>استعلام با بازه ی زمانی</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <span className="col-6">
              <FormInput label="شروع" name="start" placeHolder="مثال : 1402/02/02" />
            </span>
            <span className="col-6">
              <FormInput label="پایان" name="end" placeHolder="مثال : 1402/02/02" />
            </span>
          </div>

          <button className="btn btn-sm btn-success" onClick={getData}>
          <i className="bi bi-search mx-1"></i>
            دریافت اطلاعات <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
          </button>
          <hr />
          <div className="mt-3">
            {response !== "" ? (
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>شماره رفرنس</th>
                      <th>کد Uid</th>
                      <th>وضعیت</th>
                    </tr>
                  </thead>
                  <tbody>
                    {response.map((invoice) => (
                      <tr>
                        <td>{invoice.ReferenceNumber}</td>
                        <td>{invoice.Uid}</td>
                        <td>{invoice.Status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
  //-----------------------------------------
  async function getData(e) {
    const spinner = document.getElementsByClassName("fa-spinner");
    try {
      const start = document.getElementById("start").value;
      const end = document.getElementById("end").value;
      if (start === null || start === "")
        return toast.error(PublicConsts.Validation_EnterStartTime);
      else if (end === null || end === "") return toast.error(PublicConsts.Validation_EnterEndTime);

      e.target.classList.add("disabled");
      spinner[0].classList.remove("d-none");

      await axiosConfig
        .get(`/Client/Tax/InqueryByTimeRange?startDate=${start}&endDate=${end}`)
        .then((result) => {
          if (result.data.status === "success") {
            setResponse(result.data.data);
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {});
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner[0].classList.add("d-none");
  }
  //-----------------------------------------
};

export default InquiryByDateRange;
