import { useEffect, useState } from "react";
import { PublicConsts } from "../../publicConsts";
import {toast} from "react-toastify"
import axiosConfig from "../../axiosConfig";
import "../../assets/css/agent-details.scss";


const AgentDetails = () => {

  const [agentDetails, setAgentDetails] = useState(null);

  //Google Recaptcha ---------------------------------------------------------------------------
  const reCaptchaSiteKey = "6LdV42QnAAAAAMJ8hnnj8hjJpTK7zkUeGvqigQM1";

//Add google recaptcha script to header
  useEffect(() => {

    const loadReCaptchaScript = () => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        getDetails();
      };

      document.head.appendChild(script);
    };

    // Call this function to load the script
    loadReCaptchaScript();
  }, []);

  //-------------------------------------------------------------------------------------------

    return (
      <div className="agentdetails-container pt-3">
        <div id="loadingContainer" className="alert alert-danger d-none">
          در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
        </div>
        <div className="container">
          <div className="main-body">    
            <div className="row gutters-sm">
              <div className="col-md-4 mb-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Agent" className="rounded-circle" width="150" />
                      <div className="mt-3">
                        <h4>{agentDetails? agentDetails.Name: "نامشخص"}</h4>
                        <p className="text-secondary mb-1">سفیر فروش</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card mb-3">
                  <div className="card-body px-4">
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0"><i className="fa fa-user-circle text-muted mx-1"></i>نام کامل</h6>
                      </div>
                      <div className="col-sm-9 text-secondary text-end">
                      {agentDetails? agentDetails.FullName: "نامشخص"}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0"><i className="fa fa-calendar text-muted mx-1"></i>تاریخ عضویت</h6>
                      </div>
                      <div className="col-sm-9 text-secondary text-end">
                        {agentDetails? agentDetails.RegisterDate: "نامشخص"}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0"><i className="fa fa-tags text-muted mx-1"></i>کد های تخفیف</h6>
                      </div>
                      <div className="col-sm-9 text-secondary text-end">
                      {agentDetails? agentDetails.DiscountCodeCount + " عدد": "نامشخص"}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0"><i className="fa fa-shopping-cart text-muted mx-1"></i>تعداد فروش</h6>
                      </div>
                      <div className="col-sm-9 text-secondary text-end">
                      {agentDetails? agentDetails.SaleCount + " فروش": "نامشخص"} 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gutters-sm">
            <div className="col-12 mb-3">
                    <div className="card h-100">
                      <div className="card-body">
                        <h6 className="d-flex align-items-center mb-3 text-info fw-bold">فروش ها</h6>
                        <div className="table-responsive">
                          <table className="table text-center">
                            <thead>
                              <tr>
                                <th>نام</th>
                                <th>تاریخ</th>
                                <th>تعرفه</th>
                                <th>وضعیت</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                              (agentDetails? 
                                
                              agentDetails.Orders.map((item, index)=>(
                                //console.log(agentDetails.Orders[0].Name)
                                <tr key={index}>
                                  <td>{item.Name}</td>
                                  <td>{item.Date}</td>
                                  <td>{item.Plan}</td>
                                  <td className="status"><span className={(item.IsPaid? "success":"waiting")}>{item.Status}</span></td>
                                </tr>
                              )):<tr></tr>)}
                            </tbody>
                          </table>
                          </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>

    </div>
    );
    async function getDetails(){
      const loadingContainer = document.getElementById("loadingContainer");
      loadingContainer.classList.remove("d-none");

      const urlArray = window.location.pathname.split("/");
      const agentId = urlArray[urlArray.length - 1];

      const recaptchaToken = await getRecaptchaToken();
      
      if(agentId !== "")
      {
        try {
          await axiosConfig
            .get(`/Checkout/GetAgentDetails?id=${agentId}&recaptchaToken=${recaptchaToken}`)
            .then((result) => {
              if (result.data.status === "success") {
                setAgentDetails(result.data.data)
              }else {
                toast.error(PublicConsts.Result_NotFound)
              }
            })
            .catch((error) => {
              console.log(error)
            });
        } catch (error) {
          console.log(error)
        }
      }
      else
      {
        toast.error(PublicConsts.Result_NotFound)
      }
      
      loadingContainer.classList.add("d-none");
    }

    function getRecaptchaToken() {
      return new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(reCaptchaSiteKey, { action: 'submit' })
            .then(token => {
              resolve(token);
            })
            .catch(error => {
              reject(error);
            });
        });
      });
    }
    //---------------------------------------------------
  };
  
  export default AgentDetails;
  