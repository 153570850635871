import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";
import axiosConfig from "../../../axiosConfig";



const PaymentResult = () => {

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div className="card shadow-lg table-responsive mb-3">
      <div className="card-header">
        <span className="h5">لیست تعرفه های فروش</span>
      </div>
      <div className="card-body">
        <div id="loadingContainer" className="alert alert-danger d-none">
          در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
        </div>
        <table className="table table-striped text-center">
          <thead>
            <tr>
              <th>عنوان</th>
              <th>توضیحات</th>
              <th>متن نمایشی قیمت</th>
              <th>قیمت</th>
              <th>بازه زمانی</th>
              <th>محدودیت صورتحساب</th>
              <th>محدودیت یوزر زیرمجموعه</th>
              <th>وضعیت</th>
              <th>ویژه</th>
            </tr>
          </thead>
          <tbody>
            {plans.length !== 0 ? (
              <>
                {plans.map((plan) => (
                  <tr key={plan.Id}>
                    <td>{plan.Title}</td>
                    <td>{plan.Description}</td>
                    <td>{plan.PriceDisplayText}</td>
                    <td>{plan.Price}</td>
                    <td>{plan.Period}</td>
                    <td>{plan.InvoiceLimit}</td>
                    <td>{plan.ChildUserLimit}</td>
                    <td>{plan.Status?"فعال":"غیرفعال"}</td>
                    <td>{plan.IsSpecial? "ویژه":""}</td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="12">
                  <div className="alert alert-danger">موردی یافت نشد</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
  //-------------------------------------------------------
  async function getPlans() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/AdminPanel/PricePlans/GetPlans/`)
        .then((result) => {
          if (result.data.status === "success") 
            setPlans(result.data.data);
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  };
  
  export default PaymentResult;
  