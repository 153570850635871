import { useState, useEffect } from "react";
import axiosConfig from "../../axiosConfig.js";
import { PublicConsts } from "../../publicConsts.js";
import "../../assets/css/landing.scss";
import "../../assets/plugin/bootstrap-icons/bootstrap-icons.min.css";
import { Crisp } from "crisp-sdk-web";
import AOS from "aos";
import 'aos/dist/aos.css';
AOS.init();

  const Landing2 = () =>{

    const [pricePlans, setPricePlans] = useState([]);

    useEffect(() => {
      smoothScrollOnCLick();
      getPricePlans();
    }, []);

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const handleScroll = () => {
      backToTopButton();
      activeMenuItemOnScroll();
    };

    useEffect(() => {
      Crisp.configure(PublicConsts.CrispKey);
    }, []);
  

    return(
      <>
      <div className="landing-container">
      <header id="header" className="header fixed-top bg-white">
          <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
            <a href="/" className="logo d-flex align-items-center me-4">
              <img src="assets/img/landing-new/main-logo.png" alt="logo" />
              {/* <span>حساب همراه</span> */}
            </a>

            {/* Nanvar-Desktop */}
            <nav id="navbar" className="navbar d-none d-md-block">
              <ul>
                <li><a className="nav-link invisible" href="#hero">شروع</a></li>
                <li><a className="nav-link" href="#features">درباره سامانه</a></li>
                <li><a className="nav-link" href="#howto">کار با سامانه</a></li>
                <li><a className="nav-link" href="#values">ارزش ها</a></li>
                <li><a className="nav-link" href="#faq">سوالات متداول</a></li>
                <li><a className="nav-link" href="#pricing">تعرفه ها</a></li>
                <li><a className="nav-link" href="#footer">تماس با ما</a></li>
                <li><a className="getstarted" href="/Login">ورود به سامانه</a></li>
              </ul>
            </nav>

            {/* Nanvar-Mobile */}
            <nav className="navbar d-md-none">
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#mainNavContent" aria-controls="mainNavContent">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="offcanvas offcanvas-end w-75" tabIndex="-1" id="mainNavContent" aria-labelledby="offcanvasNavbarLabel">
                  <div className="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>
                  <div className="offcanvas-body">
                    <ul className="navbar-nav">
                      <li><a className="nav-link invisible" href="#hero">شروع</a></li>
                      <li><a className="nav-link" data-bs-dismiss="offcanvas" data-bs-target="#mainNavContent" href="#features">درباره سامانه</a></li>
                      <li><a className="nav-link" data-bs-dismiss="offcanvas" data-bs-target="#mainNavContent" href="#howto">کار با سامانه</a></li>
                      <li><a className="nav-link" data-bs-dismiss="offcanvas" data-bs-target="#mainNavContent" href="#values">ارزش ها</a></li>
                      <li><a className="nav-link" data-bs-dismiss="offcanvas" data-bs-target="#mainNavContent" href="#faq">سوالات متداول</a></li>
                      <li><a className="nav-link" data-bs-dismiss="offcanvas" data-bs-target="#mainNavContent" href="#pricing">تعرفه ها</a></li>
                      <li><a className="nav-link" data-bs-dismiss="offcanvas" data-bs-target="#mainNavContent" href="#footer">تماس با ما</a></li>
                      <li><a className="getstarted" href="/Login">ورود به سامانه</a></li>
                    </ul>
                  </div>
                </div>
            </nav>

          </div>
        </header>
        <section id="hero" className="hero d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex flex-column justify-content-center">
                <h1 data-aos="fade-up">سامانه واسط مودیان مالیاتی</h1>
                <h5 data-aos="fade-up" data-aos-delay="400" className="mt-3">
                  اگر با سامانه مودیان مالیاتی کار میکنید و نیاز دارید صورتحساب های خود را ارسال کنید، سامانه واسط مودیان مالیاتی
                 <span className="company-name"> حساب همراه </span>
                 به شما کمک میکنه خیلی سریع و راحت این کار رو انجام بدید!</h5>
                <div data-aos="fade-up" data-aos-delay="600">
                  <div className="text-center text-lg-start">
                    <a href="#pricing" className="btn-get-started nav-link d-inline-flex align-items-center justify-content-center align-self-center">
                      <span>از اینجا شروع کنید</span>
                      <i className="bi bi-arrow-left"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                <img src="assets/img/landing-new/hero-img.png" className="img-fluid" alt="hero" />
              </div>
            </div>
          </div>
        </section>
        <main id="main">
        <section id="features" className="features">
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <p>چرا حساب همراه</p>
            </header> 
            <div className="row" data-aos="fade-up">
              <div className="col-lg-6 mb-5">
                <h5 className="mb-5">با سامانه <span className="company-name">حساب همراه</span> میتونی خیلی سریع صورتحساب های خودت رو به سامانه مودیانی مالیاتی بفرستی و وضعیت اونا رو پیگیری کنی</h5>
                <div className="row align-self-center gy-4">
                <div className="col-md-6" data-aos="zoom-out" data-aos-delay="600"><div className="feature-box d-flex align-items-center"><i className="bi bi-check"></i><h3>بدون نیاز به نصب </h3></div></div>
                  <div className="col-md-6" data-aos="zoom-out" data-aos-delay="200"><div className="feature-box d-flex align-items-center"><i className="bi bi-check"></i><h3>بدون پیچیدگی</h3></div></div>
                  <div className="col-md-6" data-aos="zoom-out" data-aos-delay="300"><div className="feature-box d-flex align-items-center"><i className="bi bi-check"></i><h3>ظاهری ساده و کاربردی</h3></div></div>
                  <div className="col-md-6" data-aos="zoom-out" data-aos-delay="400"><div className="feature-box d-flex align-items-center"><i className="bi bi-check"></i><h3>بررسی گزارشات ارسال</h3></div></div>
                  <div className="col-md-6" data-aos="zoom-out" data-aos-delay="500"><div className="feature-box d-flex align-items-center"><i className="bi bi-check"></i><h3>لیست محصولات بروز</h3></div></div>
                  <div className="col-md-6" data-aos="zoom-out" data-aos-delay="700"><div className="feature-box d-flex align-items-center"><i className="bi bi-check"></i><h3>تجربه کاربری متفاوت</h3></div></div>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="assets/img/landing-new/features-2.png" className="img-fluid" alt="features" />
              </div>
            </div>
          </div>
          </section>
          <section id="howto" className="values">
            <div className="container" data-aos="fade-up">
              <header className="section-header">
                <p>حساب همراه چجوری کار میکنه</p>
              </header>
              <div className="row">
                <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                  <div className="box">
                    <img src="assets/img/landing-new/hand-point.png" className="img-fluid" alt="values" />
                    <h3>انتخاب تعرفه</h3>
                    <p>یکی از تعرفه های موجود در سایت رو انتخاب کن</p>
                  </div>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
                  <div className="box">
                    <img src="assets/img/landing-new/identity-card.png" className="img-fluid" alt="value" />
                    <h3>ورود اطلاعات</h3>
                    <p>اطلاعات خودت و شرکت رو وارد کن</p>
                  </div>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
                  <div className="box">
                    <img src="assets/img/landing-new/envelope-open.png" className="img-fluid" alt="value" />
                    <h3>ارسال صورتحساب</h3>
                    <p>صورتحساب های خودت رو بساز و ارسال کن</p>
                  </div>
                </div>

              </div>

            </div>

          </section> 
          <section id="values" className="values">
            <div className="container" data-aos="fade-up">
              <header className="section-header">
                <p>چه کسانی به سامانه نیاز دارند</p>
              </header>
              <div className="row">
                <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                  <div className="box">
                    <img src="assets/img/landing-new/values-1.png" className="img-fluid" alt="values" />
                    <h3>اشخاص حقوقی</h3>
                    <p>شرکت‌ها شامل کلیه اشخاص حقوقی دارای صورتحساب رسمی خرید و فروش</p>
                  </div>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
                  <div className="box">
                    <img src="assets/img/landing-new/values-2.png" className="img-fluid" alt="value" />
                    <h3>اشخاص حقیقی دارای کسب و کار</h3>
                    <p>کلیه اشخاص حقیقی دارای پروانه کسب و پایانه فروشگاهی و دستگاه pos</p>
                  </div>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
                  <div className="box">
                    <img src="assets/img/landing-new/values-3.png" className="img-fluid" alt="value" />
                    <h3>اشخاص حقیقی دارای فروشگاه آنلاین</h3>
                    <p>کلیه اشخاص حقیقی دارای درگاه فروش اینترنتی و کد مالیاتی</p>
                  </div>
                </div>

              </div>

            </div>

          </section>              
          <section id="faq" className="faq">
            <div className="container" data-aos="fade-up">
              <header className="section-header">
                <p>سوالات متداول</p>
              </header>
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion accordion-flush" id="faqlist1">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                        چه تعداد صورتحساب میتونم ارسال کنم؟
                        </button>
                      </h2>
                      <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                        <div className="accordion-body">
                        تعداد صورتحساب ارسالی به تعرفه انتخابی شما بستگی داره
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                        چطور می تونم از صورتحساب خود استعلام بگیرم؟
                        </button>
                      </h2>
                      <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                        <div className="accordion-body">
                        هر صورتحساب یک شناسه یکتا داره که به وسیله اون می تونید استعلام بگیرید. البته ما کار رو راحت کردیم و جلوی هر صورتحساب گزینه استعلام قرار گرفته.
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                          آیا امکان ویرایش یا حذف صورتحساب وجود دارد؟
                        </button>
                      </h2>
                      <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                        <div className="accordion-body">
                        بله. امکان ویرایش، ابطال و مرجوع کردن صورتحساب وجود دارد
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion accordion-flush" id="faqlist2">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-1">
                          آیا برای استفاده از سامانه نیاز به مدارک خاصی هست؟
                        </button>
                      </h2>
                      <div id="faq2-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                        <div className="accordion-body">
                        خیر. فقط اطلاعاتی که سامانه مودیان مالیاتی نیاز دارد. از جمله شناسه یکتا، کلید خصوصی، شناسه ملی و نام شرکت
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-2">
                        سامانه نیاز به راه اندازی خاصی دارد؟
                        </button>
                      </h2>
                      <div id="faq2-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                        <div className="accordion-body">
                          خیر. بعد از ثبت نام در سامانه، فقط کافیه مشخصات خود را ثبت کرده و شروع به استفاده کنید
                          </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-3">
                        آیا امکان ارتقا تعرفه وجود دارد؟
                        </button>
                      </h2>
                      <div id="faq2-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                        <div className="accordion-body">
                        بله. شما در هر زمان می تونید تعرفه انتخابی خود را ارتقا بدید 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="pricing" className="pricing">
            <div className="container" data-aos="fade-up">
              <header className="section-header">
                <p>تعرفه های فروش</p>
              </header>
              <div className="row gy-4" data-aos="fade-left">
              {pricePlans.length? 
                pricePlans.map((plan,index) => (
                  <div className="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100" key={plan.Id}>
                    <div className="box">
                      {(plan.IsSpecial? <span className="featured">ویژه</span>:"")}
                      <h3 className={`pricing-color-${index+1}`}>{plan.Title}</h3>
                      <div className="price">{parseInt(plan.Price).toLocaleString()}<sup>تومان</sup><span> / {plan.Period}</span></div>
                      <img src={`assets/img/landing-new/pricing-${index+1}.png`} className="img-fluid" alt="features" />
                      <ul>
                        {plan.Description.split('/n').map((item, idx) => (
                          <li key={idx}>{item}</li>
                          // <li className="na">Deleted item</li>
                        ))}
                          
                      </ul>
                      <a href={`/Checkout?id=${plan.Id}&pname=${plan.Title}&price=${plan.Price}`} className="btn-buy">انتخاب</a>
                    </div>
                  </div>
                  ))
                :
                <div className="alert alert-warning fw-bold"><i className="fa fa-info-circle mx-1"></i>خطا در نمایش تعرفه ها</div>
              }

              </div>
            </div>
          </section>
          {/* <section id="contact" className="contact">

            <div className="container" data-aos="fade-up">

              <header className="section-header">
                <p>تماس با ما</p>
              </header>

              <div className="row gy-4">

                <div className="col-lg-6">

                  <div className="row gy-4">
                    <div className="col-md-6">
                      <div className="info-box">
                        <i className="bi bi-geo-alt"></i>
                        <h3>آدرس</h3>
                        <p>A108 Adam Street,<br />New York, NY 535022</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="info-box">
                        <i className="bi bi-telephone"></i>
                        <h3>تماس</h3>
                        <p>+1 5589 55488 55<br />+1 6678 254445 41</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="info-box">
                        <i className="bi bi-envelope"></i>
                        <h3>ایمیل</h3>
                        <p>info@example.com<br />contact@example.com</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="info-box">
                        <i className="bi bi-clock"></i>
                        <h3>ساعات کاری</h3>
                        <p>Monday - Friday<br />9:00AM - 05:00PM</p>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-6">
                  <form action="forms/contact.php" method="post" className="php-email-form">
                    <div className="row gy-4">

                      <div className="col-md-6">
                        <input type="text" name="name" className="form-control" placeholder="نام" required />
                      </div>

                      <div className="col-md-6 ">
                        <input type="email" className="form-control" name="email" placeholder="ایمیل" required />
                      </div>

                      <div className="col-md-12">
                        <input type="text" className="form-control" name="subject" placeholder="موضوع" required />
                      </div>

                      <div className="col-md-12">
                        <textarea className="form-control" name="message" rows="6" placeholder="متن پیام" required></textarea>
                      </div>

                      <div className="col-md-12">
                        <div className="loading">در حال ارسال</div>
                        <div className="error-message"></div>
                        <div className="sent-message">پیام شما ارسال شد. با تشکر!</div>

                        <button type="submit">ارسال پیام</button>
                      </div>

                    </div>
                  </form>

                </div>

              </div>

            </div>

          </section> */}
        </main>
        <footer id="footer" className="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row gy-4">
                <div className="col-lg-5 col-md-12 footer-info">
                  <a href="/" className="logo d-flex align-items-center mb-4">
                    {/* <img src="assets/img/landing-new/logo.png" alt="logo" /> */}
                    <span>حساب همراه</span>
                  </a>
                  <p>اگر با سامانه مودیان مالیاتی کار میکنید و نیاز دارید صورتحساب های خود را ارسال کنید، سامانه واسط مودیان مالیاتی حساب همراه به شما کمک میکنه خیلی سریع و راحت این کار رو انجام بدید!</p>
                </div>

                <div className="col-lg-2 col-6 footer-links">
                  <h4>شبکه های اجتماعی</h4>
                  <div className="social-links mt-3">
                    <a href="https://t.me/Hesab_Hamrah" target="_blank" rel="noreferrer" className="telegram" title="تلگرام"><i className="bi bi-telegram"></i></a>
                    <a href="https://youtube.com/@hesab_hamrah" target="_blank" rel="noreferrer" className="youtube" title="بوتیوب"><i className="bi bi-youtube"></i></a>
                    <a href="https://instagram.com/hesabhamrah" target="_blank" rel="noreferrer" className="instagram" title="اینستاگرام"><i className="bi bi-instagram"></i></a>
                    <a href="https://aparat.com/hesabhamrah" target="_blank" rel="noreferrer" className="youtube" title="آپارات"><i className="bi bi-film"></i></a>
                  </div>
                </div>

                <div className="col-lg-2 col-6 footer-links">
                  <h4>ارتباط با ما</h4>
                  <ul>
                    <li><a href="https://t.me/Hesab_Hamrah" target="_blank" rel="noreferrer" className="telegram" title="">انجمن پرسش و پاسخ</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                  <span className="border rounded d-inline-block">
                    <a referrerPolicy='origin' target='_blank' rel="noreferrer" href='https://trustseal.enamad.ir/?id=366166&Code=Zx9knw1bRumVskWjCuXhFkPJdoMYNbAS'>
                      <img referrerPolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=366166&Code=Zx9knw1bRumVskWjCuXhFkPJdoMYNbAS' alt='' style={{cursor:'pointer'}} Code='Zx9knw1bRumVskWjCuXhFkPJdoMYNbAS' /></a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="copyright">
              &copy; Copyright <strong><span>HesabHamrah.ir</span></strong>. All Rights Reserved
            </div>
          </div>
        </footer>
        <a id="backToTop" href="#hero" className="back-to-top d-flex align-items-center justify-content-center d-none nav-link"><i className="bi bi-arrow-up-short"></i></a>
      
      </div>
        </>
    );
    //.....................................
    async function getPricePlans(){

      try {
       await axiosConfig
         .get(`/Checkout/GetPricePlans`)
         .then((result) => {
           if (result.data.status === "success") {
             setPricePlans(result.data.data)
           } else {
             console.log(PublicConsts.Result_Plans_Error);
           }
         })
         .catch((error) => {
           console.log(error)
         });
     } catch (error) {
       console.log(error)
     }
   }
   function smoothScrollOnCLick() {
    //console.log(document.querySelectorAll('a').length)
    document.querySelectorAll('a.nav-link').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
  
        const targetId = this.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);  
        
        if (targetElement) {
          document.querySelectorAll('a.nav-link').forEach(a => {a.classList.remove("active");})
          e.target.classList.add("active");
          window.scrollTo({
            behavior: 'smooth',
            top:
            document.getElementById(targetId).getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              40,
          });
        }
      });
    });
  };
  function backToTopButton(){
    var lastScrollTop = 0;
    var element = document.getElementById("backToTop");
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop) {
        // Desktop Menu bar(hide)
        if (scrollTop > 80) {
          element.classList.remove("d-none");
        }
    } else {
        if (scrollTop < 81) {
            element.classList.add("d-none");
        }
    }

    lastScrollTop = scrollTop;
  }
  function activeMenuItemOnScroll() {
    const menuItems = document.querySelectorAll('nav a');
  const sections = document.querySelectorAll('section');
    sections.forEach((section, index) => {
      if (index !== 0 && isElementInViewport(section)) {
        // Clear the 'active' class from all menu items
        menuItems.forEach(item => item.classList.remove('active'));
        // Add the 'active' class to the corresponding menu item
        menuItems[index].classList.add('active');
      }
    });
  }
  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top <= 50 && // Adjusted this condition to make it work for the first item
      rect.bottom >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }
  };

  export default Landing2;
  
