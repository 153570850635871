import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";
import NewTicketPartial from "./partialViews/newTicketPartial";

const TicketList = () => {
  const [tickets, setTickets] = useState([]);
  const [modalType, setmodalType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    getTickets();
  }, [currentPage]);
  return (
    <>
      <div className="card shadow-lg">
        <div className="card-header py-3">
          <span className="h5 text-primary m-0 fw-bold">لیست تیکت ها</span>
          <span className="text-warning" onClick={getTickets}>
            <i className="fa fa-refresh mx-2"></i>
          </span>
          
          <button
              type="button"
              className="btn btn-sm btn-primary float-end"
              data-bs-toggle="modal"
              data-bs-target="#publicModal"
              onClick={() => openModal("newTicket")}
            >
              <i className="fa fa-plus mx-1"></i>جدید
            </button>
        </div>
        <div className="card-body">
          <div id="loadingContainer" className="alert alert-danger d-none mb-3">
            در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
          </div>
          <div className="table-responsive">
            <table className="table table-hover text-center">
              <thead>
                <tr>
                  <th>شماره</th>
                  <th>موضوع</th>
                  <th>تاریخ</th>
                  <th>وضعیت</th>
                  <th>آخرین ویرایش</th>
                  <th>عملیات</th>
                </tr>
              </thead>
              <tbody>
                {tickets.length > 0 ? (
                  tickets.map((ticket) => (
                    <tr key={ticket.Id}>
                      <td>{ticket.Id}</td>
                      <td>{ticket.Title}</td>
                      <td><bdi>{ticket.Date}</bdi></td>
                      <td>
                        <span
                          className={
                            ticket.Status === "بسته" ? "badge bg-secondary" : "badge bg-success"
                          }
                        >
                          {ticket.Status}
                        </span>
                      </td>
                      <td><bdi>{ticket.LastUpdateDate}</bdi></td>
                      <td>
                        <a
                          href={"/Client/TicketDetails/" + ticket.Id}
                          className="btn btn-sm btn-primary text-white mx-1 mb-1"
                          title="مشاهده تیکت"
                        >
                          <i className="fa fa-eye"></i>
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="13">
                      <div className="alert alert-danger">موردی یافت نشد</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <nav>
          <ul className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
              <li className="page-item" key={index + 1}>
                <button className={`page-link ${currentPage === index+1? "active":""}`} onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
          ))}
          </ul>
        </nav>
          </div>
        </div>
      </div>
      {/* Modal------------------------------------------------------------------- */}
      <div className="modal fade" id="publicModal">
        <div className="modal-dialog">
          <div className="modal-content">
            {modalType === "newTicket"?
              <NewTicketPartial addTicketFunction={addTicket}></NewTicketPartial>
              :
              ""
                }
          </div>
        </div>
      </div>
    </>
  );
  
  //------------------------------------------------------------------
  async function getTickets() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/Client/Tickets/GetTickets?page=${currentPage}`)
        .then((result) => {
          if (result.data.status === "success"){
            setTickets(result.data.data.Tickets);
            setTotalPages(result.data.data.TotalPages)
          } 
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function addTicket(e) {

    const spinner = document.getElementsByClassName("fa-spinner")[0];

    try {
      e.target.classList.add("disabled");
      spinner.classList.remove("d-none");
      //-----------------------------------
      const form = document.getElementById("newTicketForm");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      await axiosConfig
        .post(`/Client/Tickets/NewTicket/`, formDetails)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            form.reset();
            getTickets();
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          return error.message;
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  function openModal(modalType){
    setmodalType(modalType);
  }
  //------------------------------------------------------------------
};

export default TicketList;
