import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import FormInput from "../../htmlForm/formInput";
import { PublicConsts } from "../../../publicConsts";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [validationErrors, setvalidationErrors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // for Update------------------------------------------------------------
  const [customerUpdateDetails, setcustomerUpdateDetails] = useState([]);
  //----------------------------------------------------------------------
  useEffect(() => {
    getCustomers();
  }, [currentPage]);

  return (
    <div className="card shadow-lg mb-3">
      <div className="card-header">
        <span className="h5 text-primary">لیست طرف حساب ها</span>
        <button
          type="button"
          className="btn btn-sm btn-primary float-end"
          data-bs-toggle="modal"
          data-bs-target="#addCustomerModal"
          onClick={() => {
            setcustomerUpdateDetails([]);
          }}
        >
          <i className="fa fa-plus mx-1"></i>طرف حساب جدید
        </button>
      </div>
      <div className="card-body">
        <div id="loadingContainer" className="alert alert-danger d-none">
          در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
        </div>
        <div className="table-responsive">
        <table className="table text-center">
          <thead>
            <tr>
              <th>ردیف</th>
              <th>نام کامل</th>
              <th>کد ملی</th>
              <th>کد پستی</th>
              <th>استان</th>
              <th>شهر</th>
              <th>شماره تماس</th>
              <th>آدرس</th>
              <th>عملیات</th>
            </tr>
          </thead>
          <tbody>
            {/* Get invoice Products in Update Mode */}
            {customers.length !== 0 ? (
              <>
                {customers.map((customer, index) => (
                  <tr key={customer.Id}>
                    <td>{index + 1}</td>
                    <td>{customer.Fullname}</td>
                    <td>{customer.NationalCode}</td>
                    <td>{customer.PostCode}</td>
                    <td>{customer.Province}</td>
                    <td>{customer.City}</td>
                    <td>{customer.PhoneNumber}</td>
                    <td>{customer.Address}</td>
                    <td>
                      {/* <a
                        href={"/#/"}
                        className="btn btn-sm btn-primary text-white mx-1 mb-1"
                        title="مشاهده"
                      >
                        <i className="fa fa-eye"></i>
                      </a> */}
                      <button
                        type="button"
                        className="btn btn-sm btn-warning text-white mx-1 mb-1"
                        onClick={() => {
                          updateCustomerModal(customer.Id);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#addCustomerModal"
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button
                        className="btn btn-sm btn-danger mx-1 mb-1"
                        onClick={() => removeCustomer(customer.Id)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="12">
                  <div className="alert alert-danger">موردی یافت نشد</div>
                </td>
              </tr>
            )}
          </tbody>
          
        </table>
        </div>
        <nav>
          <ul className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
              <li className="page-item" key={index + 1}>
                <button className={`page-link ${currentPage === index+1? "active":""}`} onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
          ))}
          </ul>
        </nav>
      </div>

      {/* Modal------------------------------------------------------------------- */}
      <div className="modal fade" id="addCustomerModal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header bg-info">
              <h5 className="modal-title text-white" id="addProductModal">
                افزودن طرف حساب
              </h5>
              <button type="button" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#addCustomerModal"><i className="fa fa-close text-white"></i></button>
            </div>
            <div className="modal-body">
              {validationErrors.length > 0 ? (
                <div className="alert alert-danger">
                  {validationErrors.map((item) => (
                    <ul>
                      <li>{item}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                ""
              )}
              <form id="formData">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                  <div className="col">
                    <FormInput
                      label="نام کامل/ نام شرکت"
                      name="Fullname"
                      defValue={customerUpdateDetails.Fullname}
                      required
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="شماره ملی/ شناسه ملی"
                      name="NationalCode"
                      defValue={customerUpdateDetails.NationalCode}
                      required
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="کد پستی"
                      name="PostCode"
                      defValue={customerUpdateDetails.PostCode}
                      required
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="استان"
                      name="Province"
                      defValue={customerUpdateDetails.Province}
                      required
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="شهر"
                      name="City"
                      defValue={customerUpdateDetails.City}
                      required
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="شماره تماس"
                      name="PhoneNumber"
                      defValue={customerUpdateDetails.PhoneNumber}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <FormInput
                      label="آدرس"
                      name="Address"
                      defValue={customerUpdateDetails.Address}
                      required
                    />
                  </div>
                </div>

                {customerUpdateDetails.Id ? (
                  <>
                    <input type="hidden" name="Id" value={customerUpdateDetails.Id} />
                    <input type="hidden" name="userId" value={customerUpdateDetails.UserId} />
                    <button
                      type="button"
                      className="btn btn-success mx-1 mb-1"
                      onClick={updateCustomer}
                    >
                      <i className="fa fa-edit mx-1"></i>
                      ویرایش <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button>
                  </>
                ) : (
                  <>
                    <input type="hidden" name="userId" value={0} />
                    <button
                      type="button"
                      className="btn btn-success mx-1 mb-1"
                      onClick={createCustomer}
                    >
                      <i className="fa fa-send mx-1"></i>
                      ثبت <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  //-------------------------------------------------------
  async function getCustomers() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/Client/Customers/GetCustomers?page=${currentPage}`)
        .then((result) => {
          if (result.data.status === "success"){
            setCustomers(result.data.data.Customers);
            setTotalPages(result.data.data.TotalPages);
          } 
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function removeCustomer(id) {
    const loadingContainer = document.getElementById("loadingContainer");
    const confirmDelete = window.confirm("آیا از حذف این مورد اطمینان دارید؟");
    if (confirmDelete) {
      try {
        loadingContainer.classList.remove("d-none");
        const result = await axiosConfig.delete(`/Client/Customers/DeleteCustomer/${id}`);
        if (result.data.status === "success") {
          toast.success(result.data.message);
          getCustomers();
        } else toast.error(result.data.message);
      } catch {
        toast.error(PublicConsts.TryCatch_ErrorMessage);
      }
      loadingContainer.classList.add("d-none");
    }
  }
  async function createCustomer(e) {
    const spinner = document.getElementsByClassName("fa-spinner")[0];

    try {
      e.target.classList.add("disabled");
      spinner.classList.remove("d-none");
      //-----------------------------------
      const form = document.getElementById("formData");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      await axiosConfig
        .post(`/Client/Customers/CreateCustomer/`, formDetails)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            getCustomers();
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          const errorsMessages = [];

          if (error.response.data.title === "One or more validation errors occurred.") {
            toast.error(PublicConsts.Validation_CompleteAllRequirements);

            //Convert Errors(json) to Array
            Object.keys(errors).forEach((key) => errorsMessages.push(errors[key][0]));

            setvalidationErrors(errorsMessages);
          }
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  async function updateCustomerModal(id) {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/Client/Customers/GetCustomer/${id}`)
        .then((result) => {
          if (result.data.status === "success") setcustomerUpdateDetails(result.data.data);
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function updateCustomer() {
    try {
      const form = document.getElementById("formData");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      let result = await axiosConfig.put(`/Client/Customers/UpdateCustomer`, formDetails);
      if (result.data.status === "success") {
        toast.success(result.data.message);
        // setInvoiceProductList([]);
        // form.reset();
      } else toast.error(result.data.message);
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
  //-------------------------------------------------------
};

export default Customers;
