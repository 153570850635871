import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const InvoiceList = () => {
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    getInvoices();
  }, [currentPage]);
  return (
    <>
      <div className="card shadow-lg">
        <div className="card-header py-3">
          <span className="h5 text-info m-0 fw-bold">لیست صورتحساب ها</span>
          <span className="btn btn-sm btn-primary float-end" onClick={getInvoices}>
            <i className="fa fa-refresh mx-1"></i>بروزرسانی
          </span>
        </div>
        <div className="card-body">
          <div id="loadingContainer" className="alert alert-danger d-none">
            در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-hover text-center">
              <thead>
                <tr>
                  <th>شماره</th>
                  <th>شماره فاکتور</th>
                  <th>نام کاربر</th>
                  <th>نام طرف حساب</th>
                  <th>نوع</th>
                  <th>موضوع</th>
                  <th>طرف حساب</th>
                  <th>ماهیت صورت حساب</th>
                  <th>وضعیت</th>
                  <th>مبلغ کل</th>
                  <th>تاریخ</th>
                  <th>ساعت</th>
                  <th>وضعیت ویرایش</th>
                  <th>عملیات</th>
                </tr>
              </thead>
              <tbody>
                {invoices.length > 0 ? (
                  invoices.map((invoice) => (
                    <tr key={invoice.Id}>
                      <td>{invoice.Id}</td>
                      <td>{invoice.InvoiceNumber}</td>
                      <td>{invoice.UserFullName}</td>
                      <td>{invoice.Fullname}</td>
                      <td>{invoice.InvoiceType}</td>
                      <td>{invoice.InvoiceSubject}</td>
                      <td>{invoice.BuyerType}</td>
                      <td>{invoice.SellingType}</td>
                      <td>
                        <span
                          className={
                            invoice.Status === "ارسال شده" ? "badge bg-success" : "badge bg-danger"
                          }
                        >
                          {invoice.Status}
                        </span>
                      </td>
                      <td>{invoice.FinalPrice.toLocaleString()}</td>
                      <td>{invoice.InvoiceDate}</td>
                      <td>{invoice.InvoiceTime}</td>

                      <td>
                        {invoice.IsInUpdateMode ? (
                          <span className="badge bg-warning">ویرایش شده</span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <a
                          href={"/AdminPanel/InvoiceDetails/" + invoice.Id}
                          className="btn btn-sm btn-primary text-white mx-1 mb-1"
                          title="مشاهده صورت حساب"
                        >
                          <i className="fa fa-eye"></i>
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="14">
                      <div className="alert alert-danger">موردی یافت نشد</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <nav>
              <ul className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                  <li className="page-item" key={index + 1}>
                    <button className={`page-link ${currentPage === index+1? "active":""}`} onClick={() => setCurrentPage(index + 1)}>
                      {index + 1}
                    </button>
                  </li>              
              ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
  //------------------------------------------------------------------
  async function getInvoices() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/AdminPanel/Invoices/GetInvoices?page=${currentPage}`)
        .then((result) => {
          if (result.data.status === "success")
          {
            setInvoices(result.data.data.Invoices);
            setTotalPages(result.data.data.TotalPages)
          }
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  //------------------------------------------------------------------
};

export default InvoiceList;
