import { useState, useRef } from "react";
import axiosConfig from "../../axiosConfig";
import FormInput from "../htmlForm/formInput";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { ReCaptchaProvider, ReCaptchaV3 } from "react-recaptcha-x";
import { PublicConsts } from "../../publicConsts";
const containerStyle = {
  background:
    "linear-gradient(180deg, rgba(232,246,247,1) 0%, rgba(221,240,241,1) 51%, rgba(171,213,216,1) 100%)",
};
const inputStyle = {
  width: "100%",
  margin: "10px 0",
  height: "41px",
  border: "1px solid #e1e9ea",
  padding: "5px",
  backgroundColor: "#f7fbfc",
};
const Register = () => {
  //Google Recaptcha ---------------------------------------------------------------------------
  const reCaptchaSiteKey = "6LdV42QnAAAAAMJ8hnnj8hjJpTK7zkUeGvqigQM1";
  const [reCaptchaToken, setReCaptchaToken] = useState("");
  const refreshReCaptchaToken = useRef("");
  //-------------------------------------------------------------------------------------------
  return (
    <div className="vh-100" style={containerStyle}>
      <ToastContainer rtl />

      {/* Google ReCaptcha tag
      ............................................................................ */}
      <ReCaptchaProvider siteKeyV3={reCaptchaSiteKey} hideV3Badge={false}>
        <ReCaptchaV3 action="submit" callback={reCaptchaCallback} />
      </ReCaptchaProvider>
      {/* ........................................................................ */}

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4 col-xxl-5 mt-5">
            <div className="card shadow-lg border-0 my-5 rounded-4">
              <div className="card-body p-0">
                <div className="p-5">
                  <div className="text-center">
                    <h4 className="text-dark mb-4">ثبت نام در سامانه</h4>
                  </div>
                  <form className="user">
                    <FormInput
                      label="شماره موبایل"
                      type="tel"
                      id="username"
                      name="username"
                      style={inputStyle}
                      maxLength="11"
                      onChange={usernameCheck}
                    />
                    <div id="usernameError" className="d-none text-danger mb-3">
                      <small>شماره موبایل صحیح نمی باشد</small>
                    </div>
                    <FormInput
                      id="password"
                      name="password"
                      type="password"
                      label="رمز عبور"
                      style={inputStyle}
                      autoComplete="off"
                      onChange={passwordCheck}
                    />
                    <div className="text-danger">
                      <div id="passwordCharCount">
                        <small>حداقل 6 کاراکتر داشته باشد</small>
                      </div>
                      <div id="passwordLowerCase">
                        <small>حداقل 1 حرف کوچک داشته باشد</small>
                      </div>
                      <div id="passwordUpperCase">
                        <small>حداقل 1 حرف بزرگ داشته باشد</small>
                      </div>
                      <div id="passwordNonAlphaNumberic">
                        <small>حداقل 1 علائم داشته باشد</small>
                      </div>
                    </div>
                    <button
                      id="registerButton"
                      type="button"
                      className="btn btn-success text-white d-block
                       w-100 py-3 mt-4"
                      onClick={registerProcess}
                    >
                      ثبت نام
                      <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button>
                    <hr />
                    <a
                      href="/Login"
                      className="btn btn-link text-decoration-none fw-bold text-info"
                    >
                      ورود به سامانه
                    </a>
                    <button
                      id="verifyMobileModalOpenerButton"
                      type="button"
                      className="btn btn-link text-decoration-none fw-bold text-info float-end"
                      data-bs-toggle="modal"
                      data-bs-target="#verifyMobileModal"
                    >
                      تایید موبایل
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal------------------------------------------------------------------- */}
      <div className="modal fade" id="verifyMobileModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">تایید شماره موبایل</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#verifyMobileModal"
              ></button>
            </div>
            <div className="modal-body">
              <FormInput
                id="modalUsername"
                name="modalUsername"
                placeHolder="شماره موبایل را وارد کنید"
                style={inputStyle}
                maxLength="11"
                onChange={usernameCheck}
              />
              <div id="modalUsernameError" className="d-none text-danger mb-3">
                <small>شماره موبایل صحیح نمی باشد</small>
              </div>
              <FormInput
                id="verifyCode"
                name="verifyCode"
                style={inputStyle}
                autoComplete="off"
                placeHolder="کد تایید دریافت شده را وارد کنید"
              />
              <button
                id="verifyMobile"
                type="button"
                className="btn btn-success"
                onClick={() => verifyMobile()}
              >
                تایید موبایل
                <i id="verifyMobileSpinner" className="fa fa-spin fa-spinner mx-1 d-none"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  //-----------------------------------------
  async function registerProcess(e) {
    const spinner = document.getElementsByClassName("fa-spinner")[0];
    try {
      const username = document.getElementById("username").value;
      const password = document.getElementById("password").value;
      e.target.classList.add("disabled");
      spinner.classList.remove("d-none");
      //---------------------------------------------------------------------
      const registerDetails = {
        Username: username,
        Password: password,
        ReCaptchaToken: reCaptchaToken,
      };
      await axiosConfig
        .post(`/Account/Register/`, registerDetails)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            document.getElementById("modalUsername").value = username;
            document.getElementById("verifyMobileModalOpenerButton").click();
          } else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner.classList.add("d-none");

    //refresh recaptcha token
    refreshReCaptchaToken.current();

  }
  function passwordCheck(e) {
    const text = e.target.value;

    const submitButton = document.getElementById("registerButton");
    const passwordCharCount = document.getElementById("passwordCharCount");
    const passwordLowerCase = document.getElementById("passwordLowerCase");
    const passwordUpperCase = document.getElementById("passwordUpperCase");
    const passwordNonAlphaNumberic = document.getElementById("passwordNonAlphaNumberic");

    if (text.length >= 6) passwordCharCount.classList.add("text-success");
    else passwordCharCount.classList.remove("text-success");
    if (/[a-z]/.test(text)) passwordLowerCase.classList.add("text-success");
    else passwordLowerCase.classList.remove("text-success");
    if (/[A-Z]/.test(text)) passwordUpperCase.classList.add("text-success");
    else passwordUpperCase.classList.remove("text-success");
    if (text.search(/[^a-zA-Z\d\s:]/) > 0) passwordNonAlphaNumberic.classList.add("text-success");
    else passwordNonAlphaNumberic.classList.remove("text-success");

    if (
      text.length >= 6 &&
      /[a-z]/.test(text) &&
      /[A-Z]/.test(text) &&
      text.search(/[^a-zA-Z\d\s:]/) > 0
    ) {
      submitButton.classList.remove("disabled");
    } else {
      submitButton.classList.add("disabled");
    }

  }
  function usernameCheck(e) {
    const text = e.target.value;

    if (e.target.id === "username") {
      const usernameError = document.getElementById("usernameError");
      const registerButton = document.getElementById("registerButton");

      if (text.length !== 11 || text.startsWith("09") === false) {
        usernameError.classList.remove("d-none");
        registerButton.classList.add("disabled");
      } else {
        usernameError.classList.add("d-none");
        registerButton.classList.remove("disabled");
      }
    } else if (e.target.id === "modalUsername") {
      const registerButton = document.getElementById("verifyMobile");
      const modalUsernameError = document.getElementById("modalUsernameError");

      if (text.length !== 11 || text.startsWith("09") === false) {
        modalUsernameError.classList.remove("d-none");
        registerButton.classList.add("disabled");
      } else {
        modalUsernameError.classList.add("d-none");
        registerButton.classList.remove("disabled");
      }
    }
  }
  async function verifyMobile() {
    const submitButton = document.getElementById("verifyMobile");
    const spinner = document.getElementById("verifyMobileSpinner");
    try {
      submitButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      const username = document.getElementById("username").value;
      const verifyCode = document.getElementById("verifyCode").value;
      //---------------------------------------------------------------------
      const details = {
        username: username,
        verifyCode: verifyCode,
        reCaptchaToken: reCaptchaToken,
      };
      await axiosConfig
        .post(`/Account/VerifyMobile`, details)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    submitButton.classList.remove("disabled");
    spinner.classList.add("d-none");

    //refresh recaptcha token
    refreshReCaptchaToken.current();

  }
  function reCaptchaCallback(token, refreshToken) {
    if (typeof token === "string") {
      setReCaptchaToken(token);
    }
    if (typeof refreshToken === "function") {
      refreshReCaptchaToken.current = refreshToken;
    }
  }
  //-----------------------------------------
};
export default Register;
