export const PublicConsts = {
  Axios_NetworkConnectionError: "خطا در ارتباط با سرور",
  Axios_SessionExpired: "نشست شما به پایان رسیده. لطفا مجدد وارد شوید",
  Axios_Error500: "خطای سمت سرور",
  Axios_Error403: "خطای دسترسی",
  //---------------------------------------------------------------------------
  Result_Success: "success",
  Result_Fail: "fail",
  Result_Plans_Error: "Plans Error",
  Result_NotFound: "اطلاعات یافت نشد",
  //---------------------------------------------------------------------------
  Validation_CompleteAllRequirements: "لطفا همه موارد خواسته شده را تکمیل کنید",
  Validation_EnterEconomicCode: "کد اقتصادی را وارد کنید",
  Validation_EnterReferenceNumber: "شماره رفرنس را وارد کنید",
  Validation_EnterTime: "زمان را وارد کنید",
  Validation_EnterStartTime: "زمان شروع را وارد کنید",
  Validation_EnterEndTime: "زمان پایان را وارد کنید",
  Validation_EnterUidNumber: "شماره uid را وارد کنید",
  //---------------------------------------------------------------------------
  Info_CopyReferenceNumber: "شماره رفرنس کپی شد",
  Info_CopyUidNumber: "کد UId کپی شد",
  Info_AddProductToInvoice: "کالا به لیست صورتحساب اضافه شد",
  Info_RemoveProductFromInvoice: "کالا از لیست صورتحساب حذف شد",
  Info_InvoiceRegistrationError: "خطا در ثبت صورتحساب",
  Info_GettingDataError: "خطا در ثبت صورتحساب",
  Confirm_RemoveInvoice: "آیا از حذف این صورتحساب در سامانه واسط اطمینان دارید؟",
  Confirm_SendInvoice: "آیا از ارسال این صورتحساب به سامانه مودیان اطمینان دارید؟",
  Confirm_ReturnInvoice: "آیا از تغییر این صورتحساب به بازگشتی در سامانه مودیان اطمینان دارید؟",
  Confirm_CancelInvoice: "آیا از ابطال این صورتحساب در سامانه مودیان اطمینان دارید؟",
  Confirm_DeleteDiscountCode: "از حذف کد تخفیف اطمینان دارید؟",
  //------------------------------------------------------------------------------------
  Paymeny_Info_GoingToGateway: "در حال انتقال به درگاه بانکی",
  Payment_Result_Success: "پرداخت با موفقیت انجام شد",
  Payment_Result_Fail: "پرداخت ناموفق",
  //------------------------------------------------------------------------------------
  Invoice_Subject_En_New: "new",
  Invoice_Subject_En_Edit: "edit",
  Invoice_Subject_En_Cancel: "cancel",
  Invoice_Subject_En_Return: "return",
  Invoice_Subject_Fa_New: "اصلی",
  Invoice_Subject_Fa_Edit: "اصلاحی",
  Invoice_Subject_Fa_Cancel: "ابطالی",
  Invoice_Subject_Fa_Return: "بازگشتی",
  //-------------------------------------------
  Invoice_Status_NotSend: "ارسال نشده",
  Invoice_Status_Sent: "ارسال شده",
  Invoice_Status_Confirmed: "موفق",
  Invoice_Status_Failed: "ناموفق",

  //Google Recaptcha ---------------------------------------------------------------------------
  ReCaptchaSiteKey: "6LdV42QnAAAAAMJ8hnnj8hjJpTK7zkUeGvqigQM1",
  
  //Crispt Key ---------------------------------------------------------------------------
  CrispKey: "0d3f7fb7-29ab-412b-aeea-2a32d9ee3268",
};
