import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setselectedUserId] = useState("");
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      <div className="card shadow-lg">
        <div className="card-header py-3">
          <span className="h5 text-info m-0 fw-bold">لیست کاربر ها</span>
          <span className="btn btn-sm btn-primary float-end" onClick={getUsers}>
            <i className="fa fa-refresh mx-1"></i>بروزرسانی
          </span>
        </div>
        <div className="card-body">
          <div id="loadingContainer" className="alert alert-danger d-none">
            در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-hover my-0 text-center">
              <thead>
                <tr>
                  <th>نام</th>
                  <th>نام شرکت</th>
                  <th>موبایل</th>
                  <th>تاریخ ثبت نام</th>
                  <th>تایید موبایل</th>
                  <th>تایید حساب</th>
                  <th>عملیات</th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 ? (
                  users.map((user) => (
                    <tr key={user.Id}>
                      <td>{user.FullName}</td>
                      <td>{user.CompanyName}</td>
                      <td>{user.UserName}</td>
                      <td>{user.RegistrationDate}</td>
                      <td>
                        {user.PhoneNumberConfirmed === true ? (
                          <i className="fa fa-check text-success"></i>
                        ) : (
                          <i className="fa fa-close text-danger"></i>
                        )}
                      </td>
                      <td>
                        {user.IsApprovedByAdmin === true ? (
                          <i className="fa fa-check text-success"></i>
                        ) : (
                          <i className="fa fa-close text-danger"></i>
                        )}
                      </td>
                      <td>
                        <a
                          href={"/UserDetails/" + user.Id}
                          className="btn btn-sm btn-primary text-white mx-1 mb-1"
                          title="مشاهده جزییات"
                        >
                          <i className="fa fa-eye"></i>
                        </a>
                        <button
                          type="button"
                          className="btn btn-sm btn-danger text-white mx-1 mb-1"
                          title="تغییر وضعیت"
                          data-bs-toggle="modal"
                          data-bs-target="#changeApproveStatusModal"
                          onClick={() => {
                            changeApproveStatusModal(user.Id);
                          }}
                        >
                          <i className="fa fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12">
                      <div className="alert alert-danger">موردی یافت نشد</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Modal------------------------------------------------------------------- */}
      <div className="modal fade" id="changeApproveStatusModal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title" id="addProductModal">
                افزودن طرف حساب
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#changeApproveStatusModal"
              ></button>
            </div>
            <div className="modal-body">
              <h5 className="alert alert-danger">آیا از تغییر وضعیت کاربر اطمینان دارید؟</h5>
              <button className="btn btn-success" onClick={changeApproveStatus}>
                تغییر بده
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  //------------------------------------------------------------------
  async function getUsers() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/AdminPanel/Users/GetUsers/`)
        .then((result) => {
          if (result.data.status === "success") setUsers(result.data.data);
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  function changeApproveStatusModal(id) {
    setselectedUserId(id);
  }
  async function changeApproveStatus() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .post(`/AdminPanel/Users/ChangeApproveStatus/${selectedUserId}`)
        .then((result) => {
          if (result.data.status === "success") toast.success(result.data.message);
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  //------------------------------------------------------------------
};

export default UserList;
