import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import FormInput from "../../htmlForm/formInput";
import { PublicConsts } from "../../../publicConsts";

const ChildCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [validationErrors, setvalidationErrors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  let [countdown, setCountdown] = useState(120);
  let [countdownInterval, setCountdownInterval] = useState(null);
  useEffect(() => {
    const companyId = localStorage.getItem("cid");
    if(companyId !== null)
    {
      window.location.href = '/Error403';
    }

    getCompanies();
  }, [currentPage]);


   //Google Recaptcha
  //---------------------------------------------------------------------------
   //---------------------------------------------------------------------------

//Add google recaptcha script to header
useEffect(() => {
  const loadReCaptchaScript = () => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${PublicConsts.ReCaptchaSiteKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  };

  // Call this function to load the script
  loadReCaptchaScript();
});
//-------------------------------------------------------------------------------------------

  return (
    <div className="card shadow-lg mb-3">
      <div className="card-header">
        <span className="h5 text-primary">لیست شرکت ها</span>
        <button
          type="button"
          className="btn btn-sm btn-primary float-end"
          data-bs-toggle="modal"
          data-bs-target="#addCompanyModal"
        >
          <i className="fa fa-plus mx-1"></i>شرکت جدید
        </button>
      </div>
      <div className="card-body">
        <div id="loadingContainer" className="alert alert-danger d-none">
          در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
        </div>
        <div className="table-responsive">
        <table className="table text-center">
          <thead>
            <tr>
              <th>ردیف</th>
              <th>نام کامل</th>
              <th>نام شرکت</th>
              <th>عملیات</th>
            </tr>
          </thead>
          <tbody>
            {/* Get Data in Update Mode */}
            {companies.length !== 0 ? (
              <>
                {companies.map((company, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{company.FullName}</td>
                    <td>{company.CompanyName}</td>
                    <td>
                      {/* <a
                        href={"/#/"}
                        className="btn btn-sm btn-primary text-white mx-1 mb-1"
                        title="مشاهده"
                      >
                        <i className="fa fa-eye"></i>
                      </a> */}
                      {/* <button
                        type="button"
                        className="btn btn-sm btn-warning text-white mx-1 mb-1 disabled"
                        onClick={() => {
                          updateCompanyModal(company.Id);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#addCompanyModal"
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button
                        className="btn btn-sm btn-danger mx-1 mb-1 disabled"
                        onClick={() => removeCompany(company.Id)}
                      >
                        <i className="fa fa-trash"></i>
                      </button> */}
                      <button
                        className="btn btn-sm btn-primary mx-1 mb-1"
                        title="انتقال به پروفایل"
                        onClick={() => switchCompany(company.Id, company.CompanyName)}
                      >
                        <i className="fa fa-external-link"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="12">
                  <div className="alert alert-danger">موردی یافت نشد</div>
                </td>
              </tr>
            )}
          </tbody>
          
        </table>
        </div>
        <nav>
          <ul className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
              <li className="page-item" key={index + 1}>
                <button className={`page-link ${currentPage === index+1? "active":""}`} onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
          ))}
          </ul>
        </nav>
      </div>

      {/* Modal------------------------------------------------------------------- */}
      <div className="modal fade" id="addCompanyModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-primary py-2">
              <h5 className="modal-title text-white" id="addProductModal">
                افزودن شرکت
              </h5>
              <button type="button" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#addCompanyModal"><i className="fa fa-close text-white"></i></button>
            </div>
            <div className="modal-body">
              {validationErrors.length > 0 ? (
                <div className="alert alert-danger">
                  {validationErrors.map((item) => (
                    <ul>
                      <li>{item}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                ""
              )}
              <form id="formData">
                <div className="row row-cols-1">
                <div className="col">
                    <FormInput id="mobile" placeHolder="شماره موبایل" name="Username" maxLength={11} required onChange={mobileCheck} />
                    <div id="mobileError" className="d-none text-danger mb-3">
                      <small>شماره موبایل صحیح نمی باشد</small>
                    </div>
                    <FormInput placeHolder="نام کامل" name="FullName" required/>
                    <FormInput placeHolder="نام شرکت" name="CompanyName" required />
                    {/*<select id="pricePlanId" name="PricePlanId" className="form-control mb-3">
                      <option value="">انتخاب تعرفه</option>
                      {pricePlans.length? 
                        pricePlans.map((plan) => (
                          <option value={plan.Id}>{plan.Title} / {parseInt(plan.Price).toLocaleString()} تومان</option>
                      )):""};
                    </select>*/}
                  </div>
                </div>
                <FormInput
                id="verifyMobileCode"
                name="verifyMobileCode"
                className="d-none"
                autoComplete="off"
                placeHolder="کد تایید دریافت شده را وارد کنید"
                />
                <button
                  id="verifyMobileCodeButton"
                  type="button"
                  className="btn btn-sm btn-success mx-1 d-none"
                  onClick={() => verifyMobile()}
                >
                  تایید و ثبت نهایی
                  <i id="verifyMobileCodeSpinner" className="fa fa-spin fa-spinner mx-1 d-none"></i>
                </button>      
                <button
                  id="sendVerifyCodeButton"
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={verifyFormInputs}
                >
                  ارسال کد تایید
                  <i id="sendVerifyCodeSpinner" className="fa fa-spin fa-spinner mx-1 d-none"></i>
                  <span id="countDownDisplay" className='mx-1'></span>
                </button>
                          
              </form>
              <div id="registrationSuccessResult" className="d-none">
                <div className="alert alert-success">شرکت با موفقیت ثبت شد</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  //-------------------------------------------------------
  function switchCompany(id, name){
    localStorage.setItem("cid", id);
    localStorage.removeItem("name");
    localStorage.setItem("name", name);
    window.open("/Client/Dashboard", "_blank")

  }
  async function getCompanies() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/Client/ChildCompanies/GetCompanies?page=${currentPage}`)
        .then((result) => {
          if (result.data.status === "success"){
            setCompanies(result.data.data.Companies);
            setTotalPages(result.data.data.TotalPages);
          } 
          else console.log(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  function mobileCheck(e) {
    const text = e.target.value;

    if (e.target.id === "mobile") {
      const moblieError = document.getElementById("mobileError");
      const submitButton = document.getElementById("verifyMobileCode");

      if (text.length !== 11 || text.startsWith("09") === false) {
        moblieError.classList.remove("d-none");
        submitButton.classList.add("disabled");
      } else {
        moblieError.classList.add("d-none");
        submitButton.classList.remove("disabled");
      }
    }
  }
  function verifyFormInputs(e){
    e.preventDefault()
    const username = document.getElementById("mobile").value;
    const fullName = document.getElementById("FullName").value;
    const cName = document.getElementById("CompanyName").value;
    // const planId = document.getElementById("pricePlanId").value;
      if(username === "" || fullName === "" || cName === "")
        return toast.error("مشخصات خواسته شده را وارد کنید");
      else
      registerAndSendVerifyCode();
  }
  async function registerAndSendVerifyCode() {
    const sendCodeButton = document.getElementById("sendVerifyCodeButton");
    const spinner = document.getElementById("sendVerifyCodeSpinner");
    const username = document.getElementById("mobile").value;
    try {

      let recaptchaToken = await getRecaptchaToken();
      sendCodeButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      //---------------------------------------------------------------------
      const details = {
        Username: username,
        ReCaptchaToken: recaptchaToken,
      };
      await axiosConfig
        .post(`/Client/ChildCompanies/CreateCompany`, details)
        .then((result) => {
          if (result.data.status === PublicConsts.Result_Success) {
            toast.success(result.data.message);
            
            //countdown
            //----------------------------------
            verifyButtonCountdown();
            setCountdownInterval(setInterval(verifyButtonCountdown, 1000));
            setTimeout(() => {
              clearInterval(countdownInterval);
              sendCodeButton.classList.remove("disabled");
            }, countdown * 1000);
            //--------------------------------

            const verifyCodeInput = document.getElementById("verifyMobileCode");
            const verifyMobileButton = document.getElementById("verifyMobileCodeButton");
            verifyCodeInput.classList.remove("d-none");
            verifyMobileButton.classList.remove("d-none");
          } else {
            toast.error(result.data.message);
            sendCodeButton.classList.remove("disabled");
          }
        })
        .catch((error) => {
          console.log(error);
          sendCodeButton.classList.remove("disabled");
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
      sendCodeButton.classList.remove("disabled");

    }
    spinner.classList.add("d-none");
  }
  async function verifyMobile() {
    const submitButton = document.getElementById("verifyMobileCodeButton");
    const spinner = document.getElementById("verifyMobileCodeSpinner");
    try {
      let reCaptchaToken = await getRecaptchaToken();
      submitButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      const username = document.getElementById("mobile").value;
      const verifyCode = document.getElementById("verifyMobileCode").value;

      if(verifyCode === ""){
        toast.error("کد تایید را وارد کنید")
        submitButton.classList.remove("disabled");
        spinner.classList.add("d-none");
        return
      }
      //---------------------------------------------------------------------
      const details = {
        username: username,
        verifyCode: verifyCode,
        reCaptchaToken: reCaptchaToken,
      };
      await axiosConfig
        .post(`/Account/VerifyMobile`, details)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            updateCompanyDetails(result.data.data);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    submitButton.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  async function updateCompanyDetails(id) {

    try {
      //-----------------------------------
      const resultMessage = document.getElementById("registrationSuccessResult");
      const form = document.getElementById("formData");
      const formData = new FormData(form);
      formData.append('userId', id);
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      await axiosConfig
        .post(`/Client/ChildCompanies/UpdateCompany/`, formDetails)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            form.classList.add("d-none");
            resultMessage.classList.remove("d-none");
            getCompanies();
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          const errorsMessages = [];

          if (error.response.data.title === "One or more validation errors occurred.") {
            toast.error(PublicConsts.Validation_CompleteAllRequirements);

            //Convert Errors(json) to Array
            Object.keys(errors).forEach((key) => errorsMessages.push(errors[key][0]));

            setvalidationErrors(errorsMessages);
          }
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
  function verifyButtonCountdown() {
    const element = document.getElementById('countDownDisplay');
    setCountdown(120);
    if (countdown > 0) {
      element.innerHTML = `${countdown}`;
      setCountdown(countdown--);
    } else {
      element.innerHTML = '';
    }
  }
  function getRecaptchaToken() {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(PublicConsts.ReCaptchaSiteKey, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  }
  //-------------------------------------------------------
};

export default ChildCompanies;
