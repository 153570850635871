import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axiosConfig from "../../axiosConfig";
import FormInput from "../htmlForm/formInput";
import { toast } from "react-toastify";
import { Crisp } from "crisp-sdk-web";
import "../../assets/css/client/panel.scss";
import "../../assets/css/client/panel-custom.scss";
import { PublicConsts } from "../../publicConsts";

const UserPanelLayout = ({ mainContent }) => {

  const [companyName, setCompanyName] = useState(null);
  const [companyId, setCompanyId] = useState(null);

    useEffect(() => {
        autoCloseSidebarOnMobile();
        setCompanyName(localStorage.getItem("name"));
        setCompanyId(localStorage.getItem("cid"));
      }, []);

      useEffect(() => {
        Crisp.configure(PublicConsts.CrispKey);
      }, []);

  return (
    <>

<div className="panel-container d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
    {/* SideBar */}
    <nav id="sidebarMenu" className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg">
        <div className="container-fluid">
            <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand py-lg-2 mb-lg-2 px-lg-6 me-0 text-center" target="_blank" href="/">
                <img src="/assets/img/landing-new/main-logo.png" alt="..."/>
            </a>
            <hr className="navbar-divider opacity-20" />
            <div className="navbar-user justify-content-lg-start ms-lg-4">
                <div className="dropdown">
                    <button id="sidebarAvatar" className="bg-transparent" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div className="avatar-parent-child">
                            <img alt="avatar" src="/assets/img/landing-new/avatar.jpg" className="avatar avatar- rounded-circle"/>
                            <span className="avatar-child avatar-badge bg-success"></span>
                        </div>
                    </button>
                    <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                      <h6 className="mx-2 text-primary d-block d-lg-none border-bottom pb-2">{companyName}</h6>
                      <NavLink className="nav-link" to="/Client/Settings"><span>تنطیمات</span></NavLink>
                      <NavLink  className="nav-link" to="/ChangePassword" data-bs-toggle="modal" data-bs-target="#changePasswordModal">
                        تغییر رمز عبور
                      </NavLink>
                      <hr className="dropdown-divider"/>
                      <a className="nav-link text-danger" href="/logout" onClick={logout}>خروج</a>
                    </div>
                </div>
                <h5 className="mx-2 text-muted d-none d-lg-block">{companyName}</h5>
            </div>
            <div className="collapse navbar-collapse ms-3" id="sidebarCollapse">
                <ul className="navbar-nav">
                <hr className="navbar-divider opacity-20"/>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/Client/Dashboard">
                    <i className="bi bi-house"></i>
                    <span>داشبورد</span>
                  </NavLink>
                    </li>
                    <li className="nav-item">
                  <NavLink className="nav-link" to="/Client/CreateInvoice">
                    <i className="bi bi-send"></i>
                    <span>ثبت صورتحساب</span>
                  </NavLink>
                </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/Client/InvoiceList">
                        <i className="bi bi-credit-card"></i>
                    <span>صورتحساب ها</span>
                  </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/Client/SentInvoiceReport">
                        <i className="bi bi-clipboard2-data"></i>
                    <span>گزارشات ارسال</span>
                  </NavLink>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#sidebar-inquery" data-bs-toggle="collapse" role="button">
                            <i className="bi bi-binoculars"></i> استعلام
                        </a>
                        <div className="collapse" id="sidebar-inquery">
                            <ul className="nav nav-sm flex-column">
                            <li>
                                <NavLink className="nav-link" to="/Client/InquiryByUid">
                                <i className="bi bi-search"></i>
                                <span>استعلام با UID</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="nav-link" to="/Client/InquiryByRfn">
                                <i className="bi bi-search"></i>
                                <span>استعلام با شماره رفرنس</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                className="nav-link"
                                to="/Client/InquiryByTime"
                                >
                                <i className="bi bi-search"></i>
                                <span>استعلام بر اساس زمان</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                className="nav-link"
                                to="/Client/InquiryByTimeRange"
                                >
                                <i className="bi bi-search"></i>
                                <span>استعلام با بازه زمانی</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                className="nav-link"
                                to="/Client/GetEcoCodeInfo"
                                >
                                <i className="bi bi-search"></i>
                                <span>استعلام کد اقتصادی</span>
                                </NavLink>
                            </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/Client/Customers">
                            <i className="bi bi-people"></i>
                            <span>طرف حساب ها</span>
                        </NavLink>
                    </li>
                    {companyId == null?
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/Client/Companies">
                              <i className="bi bi-building"></i>
                              <span>شرکت ها</span>
                          </NavLink>
                      </li>
                      :""
                    }
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/Client/GetFiscalInfo">
                            <i className="bi bi-sd-card"></i>
                            <span>اطلاعات حافظه</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#sidebar-support" data-bs-toggle="collapse" role="button">
                            <i className="bi bi-headset"></i> پشتیبانی
                        </a>
                        <div className="collapse" id="sidebar-support">
                        <ul className="nav nav-sm flex-column">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Client/Tickets">
                                    <i className="bi bi-journals"></i>
                                    <span>تیکت</span>
                                    {/* <span className="badge bg-soft-primary text-primary rounded-pill d-inline-flex align-items-center ms-auto">1</span> */}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#sidebar-settings" data-bs-toggle="collapse" role="button">
                            <i className="bi bi-sliders2"></i> تنظیمات
                        </a>
                        <div className="collapse" id="sidebar-settings">
                            <ul className="nav nav-sm flex-column">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Client/Settings">
                                    <i className="bi bi-person-circle"></i>
                                    <span>اطلاعات کاربری</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                    className="nav-link"
                                    to="/ChangePassword"
                                    data-bs-toggle="modal"
                                    data-bs-target="#changePasswordModal"
                                    >
                                    <i className="bi bi-unlock" />
                                    تغییر رمز عبور
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-danger" href="/logout" onClick={logout}>
                            <i className="bi bi-box-arrow-left text-danger"></i> خروج
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    {/* Main Content */}
    <div className="h-screen flex-grow-1 overflow-y-lg-auto">
        <header className="bg-surface-primary border-bottom py-2 text-end">
            <div className="container-fluid py-2">
            {companyId? <button className="btn btn-sm btn-warning" onClick={()=> returnToParentUser()}>
              <i className="fa fa-arrow-left mx-1"></i>
              بازگشت به یوزر والد
              </button>:""}

            </div>
        </header>
        <main className="py-6 bg-surface-secondary">
            <div className="container-fluid">
            {mainContent}
            </div>
        </main>
    </div>
</div>

{/* Modal------------------------------------------------------------------- */}
<div className="modal fade" id="changePasswordModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">تغییر پسورد</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#changePasswordModal"
              ></button>
            </div>
            <div className="modal-body">
              <form>
              <FormInput
                id="username"
                name="username"
                placeHolder="شماره موبایل را وارد کنید"
              />
              <FormInput
                type="password"
                id="currentPassword"
                name="currentPassword"
                autoComplete="off"
                placeHolder="رمز عبور فعلی را وارد کنید"
              />
              <FormInput
                type="password"
                id="newPassword"
                name="newPassword"
                autoComplete="off"
                placeHolder="رمز عبور جدید را وارد کنید"
              />
              <button
                id="changePasswordButton"
                type="button"
                className="btn btn-success"
                onClick={() => changePassword()}
              >
                تغییر رمز عبور{" "}
                <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
              </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
      )
      //----------------------------------------------------------------------
  function logout() {
    localStorage.removeItem("token");
    window.location = "/";
  }
  async function changePassword() {
    const spinner = document.getElementsByClassName("fa-spinner")[0];
    const button = document.getElementById("changePasswordButton");
    try {
      button.classList.add("disabled");
      spinner.classList.remove("d-none");

      const username = document.getElementById("username").value;
      const curPass = document.getElementById("currentPassword").value;
      const newPass = document.getElementById("newPassword").value;

      // const passwordDetails = {
      //   username: username,
      //   currentPassword: curPass,
      //   newPassword: newPass,
      // };

      await axiosConfig
        .post(
          `/Account/ChangePassword?username=${username}&currentPassword=${curPass}&newPassword=${newPass}`
        )
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          toast.error("رخداد خطا");
        });
    } catch (error) {
      if (error.message === "Network Error")
        toast.error("خطا در ارتباط با سرور");
      else toast.error("رخداد خطا");
    }

    button.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  function autoCloseSidebarOnMobile() {
    const screenWidth = window.screen.width;
    const menuItems = document.getElementsByClassName("nav-item");
    if (screenWidth < 768) {
      for (let item of menuItems) {
        item.setAttribute("data-bs-toggle", "collapse");
        item.setAttribute("data-bs-target", "#sidebarCollapse");
      }
    }
  }
  function returnToParentUser(){
    localStorage.removeItem("cid");
    window.location = "/Client/Dashboard"
  }
  //----------------------------------------------------------------------
};

export default UserPanelLayout;
