import { useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import FormInput from "../../htmlForm/formInput";
import { PublicConsts } from "../../../publicConsts";

const InquiryByRefrenceNumber = () => {
  const [response, setResponse] = useState("");
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>استعلام با شماره رفرنس</h5>
        </div>
        <div className="card-body">
          <FormInput label="شماره رفرنس" name="referenceNumber" />
          <button className="btn btn-sm btn-success" onClick={getData}>
          <i className="bi bi-search mx-1"></i>
            دریافت اطلاعات <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
          </button>
          <hr />
          <div className="mt-3">
            <span className="text-primary">نتیجه درخواست: </span>
            <hr />
            {response !== "" ? (
              <div>
                <div className="mb-3">
                  <span>شماره رفرنس: </span>
                  <strong>{response.ReferenceNumber}</strong>{" "}
                </div>
                <div className="mb-3">
                  <bdi>کد UId: </bdi>
                  <strong>{response.Uid}</strong>{" "}
                </div>
                <div className="mb-3">
                  <span>شناسه یکتا: </span>
                  <strong>{response.FiscalId}</strong>{" "}
                </div>
                <div className="mb-3">
                  <span>وضعیت: </span>
                  <strong>{response.Status}</strong>{" "}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
  //-----------------------------------------
  async function getData(e) {
    const spinner = document.getElementsByClassName("fa-spinner");
    try {
      const referenceNumber = document.getElementById("referenceNumber").value;
      if (referenceNumber === null || referenceNumber === "")
        return toast.error(PublicConsts.Validation_EnterReferenceNumber);

      e.target.classList.add("disabled");
      spinner[0].classList.remove("d-none");

      await axiosConfig
        .get(`/Client/Tax/InqueryByReferenceNumber?referenceNumber=${referenceNumber}`)
        .then((result) => {
          if (result.data.status === "success") {
            setResponse(result.data.data[0]);
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {});
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner[0].classList.add("d-none");
  }
  //-----------------------------------------
};

export default InquiryByRefrenceNumber;
