import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const InvoiceList = () => {
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [invoiceCount, setInvoiceCount] = useState(0);

  useEffect(() => {
    getInvoices(false, false);
  }, []);
  return (
    <>
          {process.env.NODE_ENV === "development"? <div className="alert alert-danger mb-3">در حالت Development  صورتحساب ها در SandBox ذخیره می شوند</div>:""}
      <div className="alert alert-warning p-3 mb-3">
        <i className="fa fa-info-circle mx-1"></i>
        صورتحساب های ویرایش شده نیاز به ارسال مجدد به سامانه مودیان را دارند
      </div>
      <div className="card shadow-lg">
        <div className="card-header py-3">
          <span className="h5 text-primary m-0 fw-bold">لیست صورتحساب ها <span className="text-warning">({invoiceCount})</span></span>
          <span className="btn btn-sm btn-outline-primary float-end" onClick={() => getInvoices(false,false)}>
            <i className="fa fa-refresh mx-1"></i>بروزرسانی
          </span>
        </div>
        <div className="card-body">
          <div id="loadingContainer" className="alert alert-danger mb-3 d-none sticky-top">
            در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
          </div>
          <div className="input-group mb-3">
            <span><b>فیلتر بر اساس:</b> </span>
            <span>
              <button type="button" className="btn btn-sm btn-outline-primary mx-1" onClick={()=>getInvoices(false, '1')}>ارسال نشده</button>
              <button type="button" className="btn btn-sm btn-outline-primary mx-1" onClick={()=>getInvoices(false, '2')}>ارسال شده</button>
              <button type="button" className="btn btn-sm btn-outline-primary mx-1" onClick={()=>getInvoices(false, '3')}>موفق</button>
              <button type="button" className="btn btn-sm btn-outline-primary mx-1" onClick={()=>getInvoices(false, '4')}>ناموفق</button>
            </span>
              
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control form-control-sm" id="search" placeholder="شماره یا سریال صورتحساب را وارد کنید"/>
              <button type="button" className="btn btn-sm btn-primary" onClick={()=>getInvoices(true, false)}><i className="fa fa-search mx-1"></i>جستجو کن</button>
          </div>
          <div className="table-responsive">
            <table className="table table-hover table-nowrap text-center">
              <thead className="thead-light">
                <tr>
                  <th>شماره</th>
                  <th>شماره فاکتور</th>
                  <th>نام</th>
                  <th>نوع</th>
                  <th>موضوع</th>
                  <th>طرف حساب</th>
                  <th>ماهیت صورت حساب</th>
                  <th>آخرین وضعیت</th>
                  <th>مبلغ کل</th>
                  <th>تاریخ صدور</th>
                  <th>تاریخ ثبت</th>
                  <th>وضعیت ویرایش</th>
                  <th>عملیات</th>
                </tr>
              </thead>
              <tbody>
                {invoices.length > 0 ? (
                  invoices.map((invoice) => (
                    <tr key={invoice.Id}>
                      <td>{invoice.Id}</td>
                      <td>{invoice.InvoiceNumber}</td>
                      <td className="text-heading font-semibold">{invoice.Fullname}</td>
                      <td>{invoice.InvoiceType}</td>
                      <td>
                      <span className="badge badge-lg badge-dot">
                        <i className={
                            invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_New
                            ? "bg-primary"
                            : invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_Edit
                            ? "bg-warning"
                            : invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_Cancel
                            ? "bg-danger"
                            : invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_Return
                            ? "bg-secondary"
                            : ""
                          }></i>
                          {invoice.InvoiceSubject}
                        </span>
                      </td>
                      <td>{invoice.BuyerType}</td>
                      <td>{invoice.SellingType}</td>
                      <td>
                        <span
                          className={
                            invoice.Status === "ارسال شده" || invoice.Status === "ارسال نشده"
                              ? "badge bg-warning"
                              : invoice.Status === "موفق"
                              ? "badge bg-success"
                              : "badge bg-danger"
                          }
                        >
                          {invoice.Status}
                        </span>
                      </td>
                      <td>{invoice.FinalPrice.toLocaleString()}</td>
                      <td>{invoice.IssueDate}</td>
                      <td>{invoice.InvoiceDate}</td>

                      {/* Update Mode */}
                      <td>
                        {invoice.IsInUpdateMode ? (
                          <span className="badge bg-warning">ویرایش شده</span>
                        ) : (
                          ""
                        )}
                      </td>

                      {/* Functions */}
                      <td>
                        {/* View Button---------------------------------------------------------------------------------- */}
                        <a
                          href={"/Client/InvoiceDetails/" + invoice.Id}
                          className="btn btn-sm btn-primary text-white mx-1 mb-1"
                          title="مشاهده صورت حساب"
                        >
                          <i className="fa fa-eye"></i>
                        </a>
                        {/* Update Button---------------------------------------------------------------------------------- */}
                        {/* if */}
                        {invoice.InvoiceSubject !== PublicConsts.Invoice_Subject_Fa_Cancel && 
                          invoice.InvoiceSubject !== PublicConsts.Invoice_Subject_Fa_Return?
                          <a
                          href={`/Client/CreateInvoice?id=${invoice.Id}`}
                          className="btn btn-sm btn-warning text-white mx-1 mb-1"
                          title="ویرایش"
                        >
                          <i className="fa fa-edit"></i>
                        </a>
                        :
                        // else
                        ""
                        }
                        {/* Delete Button---------------------------------------------------------------------------------- */}
                        {/* if */}
                        {invoice.ReferenceNumber === null ||
                        invoice.ReferenceNumber === "null" ||
                        invoice.ReferenceNumber === "" ? (
                          <button
                            className="btn btn-sm btn-danger mx-1 mb-1"
                            onClick={() => removeInvoice(invoice.Id)}
                            title="حذف در سامانه واسط"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        ) :
                        // else
                          ""
                        }
                        {/* Send,return,cancel buttons---------------------------------------------------------------------------------- */}

                        {/* if */}
                        {invoice.ReferenceNumber === null ||
                        invoice.ReferenceNumber === "null" ||
                        invoice.ReferenceNumber === "" ||
                        invoice.IsInUpdateMode === true ? (

                          //Send button for New & Update Conditions----------------------------------------------------------------------------------

                          //if(Update invoice mode)
                          invoice.IsInUpdateMode === true ?(
                          <button
                            className="btn btn-sm btn-info text-white mx-1 mb-1"
                            onClick={() => {sendInvoice(invoice.Id, PublicConsts.Invoice_Subject_En_Edit);}}
                            title="ارسال مجدد به سامانه مودیان"
                          >
                            <i className="fa fa-send"></i>
                          </button>
                          ):
                          //else(New invoice mode)
                          <button
                            className="btn btn-sm btn-success mx-1 mb-1"
                            onClick={() => {sendInvoice(invoice.Id, PublicConsts.Invoice_Subject_En_New);}}
                            title="ارسال به سامانه مودیان"
                          >
                            <i className="fa fa-send"></i>
                          </button>
                        ) : 
                        // else if
                        (
                          invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_Cancel || 
                          invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_Return ||
                          (invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_New || 
                            invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_Edit &&
                            invoice.Status === PublicConsts.Invoice_Status_Confirmed)?
                          <>
                            <button
                            className="btn btn-sm btn-danger text-white mx-1 mb-1"
                            onClick={() => {sendInvoice(invoice.Id, PublicConsts.Invoice_Subject_En_Cancel);}}
                            title="ابطال"
                          >
                            <i className="fa fa-times"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-secondary text-white mx-1 mb-1"
                            onClick={() => {sendInvoice(invoice.Id, PublicConsts.Invoice_Subject_En_Return);}}
                            title="بازگشتی"
                          >
                            <i className="fa fa-rotate-left"></i>
                          </button>
                          </>
                          :
                          // else
                          ""
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="14">
                      <div className="alert alert-warning">موردی یافت نشد</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <nav>
              <ul className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                  <li className="page-item" key={index + 1}>
                    <button className={`page-link ${currentPage === index+1? "active":""}`} onClick={() => setPaginationNumber(index + 1)}>
                      {index + 1}
                    </button>
                  </li>              
              ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
  //------------------------------------------------------------------
  async function getInvoices(isSearch = false, filterStatus = "") {

    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");

      let searchText = "";
      let filter ="";
      if(isSearch) searchText = document.getElementById("search").value;
      if(filterStatus != "") filter = filterStatus;

      await axiosConfig
        .get(`/Client/Invoices/GetAllInvoices?search=${searchText}&filter=${filter}&page=${currentPage}`)
        .then((result) => {
          if (result.data.status === "success"){
            setInvoices(result.data.data.Invoices);
            setTotalPages(result.data.data.TotalPages)
            setInvoiceCount(result.data.data.InvoiceCount)
          }
          else{
            if(result.data.message !== "")toast.error(result.data.message);
          }
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function removeInvoice(id) {
    const loadingContainer = document.getElementById("loadingContainer");
    const confirmDelete = window.confirm(PublicConsts.Confirm_RemoveInvoice);
    if (confirmDelete) {
      try {
        loadingContainer.classList.remove("d-none");
        const result = await axiosConfig.delete(`/Client/Invoices/DeleteInvoice/${id}`);
        if (result.data.status === "success") {
          toast.success(result.data.message);
          getInvoices(false, false);
        } else toast.error(result.data.message);
      } catch {
        toast.error(PublicConsts.TryCatch_ErrorMessage);
      }
      loadingContainer.classList.add("d-none");
    }
  }
  async function sendInvoice(id,sendingType) {
    const loadingContainer = document.getElementById("loadingContainer");
    let confirmMessage="";
    switch(sendingType) {
      case "new":
        confirmMessage = PublicConsts.Confirm_SendInvoice;
        break;
      case "return":
        confirmMessage = PublicConsts.Confirm_ReturnInvoice;
        break;
      case "cancel":
        confirmMessage = PublicConsts.Confirm_CancelInvoice;
        break;
      default:
        confirmMessage = PublicConsts.Confirm_SendInvoice;
    }
    const confirmSending = window.confirm(confirmMessage);
    if (confirmSending) {
      try {
        loadingContainer.classList.remove("d-none");
        const result = await axiosConfig.post(`/Client/Tax/SendInvoices?id=${id}&subjectType=${sendingType}`);
        if (result.data.status === "success") {
          toast.success(result.data.message);
          getInvoices(false, false);
        } else toast.error(result.data.message);
      } catch {
        toast.error(PublicConsts.TryCatch_ErrorMessage);
      }
      loadingContainer.classList.add("d-none");
    }
  }
  function setPaginationNumber(page)
  {
    setCurrentPage(page);
    getInvoices(false,false)

  }
  //------------------------------------------------------------------
};

export default InvoiceList;
