import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const InvoiceReport = () => {
  const [itemStatus, setItemStatus] = useState("");
  const [invoiceTrackingCodes, setInvoiceTrackingCodes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    getInvoiceTrackingCodes();
  }, [currentPage]);
  return (
    <>
    <div id="result-error-message" className="alert alert-danger d-none"></div>
      <div className="card shadow-lg">
        <div className="card-header py-3">
          <span className="h5 text-info m-0 fw-bold">گزارشات ارسال صورتحساب</span>
          <span className="btn btn-sm btn-primary float-end" onClick={getInvoiceTrackingCodes}>
            <i className="fa fa-refresh mx-1"></i>بروزرسانی
          </span>
        </div>
        <div className="card-body">
          <div id="loadingContainer" className="alert alert-danger d-none">
            در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-hover text-center">
              <thead>
                <tr>
                <th>شماره</th>
                  <th>شماره فاکتور</th>
                  <th>سریال</th>
                  <th>نام کاربر</th>
                  <th>نام طرف حساب</th>
                  <th>مبلغ کل</th>
                  <th>تاریخ</th>
                  <th>ساعت</th>
                  <th>کد uid</th>
                  <th>شماره رفرنس</th>
                  <th>وضعیت</th>
                  <th>عملیات</th>
                </tr>
              </thead>
              <tbody>
                {invoiceTrackingCodes.length > 0 ? (
                  invoiceTrackingCodes.map((item) => (
                    <tr key={item.Id}>
                      <td>{item.InvoiceId}</td>
                      <td>{item.Invoice.InvoiceNumber}</td>
                      <td>{item.InvoiceSerial}</td>
                      <td>{item.UserFullName}</td>
                      <td>{item.Invoice.Fullname}</td>
                      <td>{item.Invoice.FinalPrice.toLocaleString()}</td>
                      <td>{item.InvoiceDate}</td>
                      <td>{item.InvoiceTime}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-outline-success"
                          onClick={(e) => copyUidNumber(e)}
                        >
                          <span className="uidNumber d-none">{item.Uid}</span>
                          کپی کن
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-outline-success"
                          onClick={(e) => copyRefNumber(e)}
                        >
                          <span className="refNumber d-none">{item.ReferenceNumber}</span>
                          کپی کن
                        </button>
                      </td>
                      <td>
                        {item.Status === "موفق" ? (
                          <span className="badge bg-success">{item.Status}</span>
                        ) : item.Status === "ناموفق" ? (
                          <span className="badge bg-danger">{item.Status}</span>
                        ) : (
                          <span key={item.Id} className="badge bg-primary">
                            {itemStatus[item.Id]}
                          </span>
                        )}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-warning"
                          onClick={(e) => invoiceInquery(e, item.Id)}
                        >
                          <span className="refNumber d-none">{item.ReferenceNumber}</span>
                          <span className="userId d-none">{item.UserId}</span>
                          <i className="fa fa-info-circle mx-1"></i>استعلام وضعیت
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="11" className="text-warning">
                      <div className="alert alert-danger">موردی یافت نشد</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <nav>
              <ul className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                  <li className="page-item" key={index + 1}>
                    <button className={`page-link ${currentPage === index+1? "active":""}`} onClick={() => setCurrentPage(index + 1)}>
                      {index + 1}
                    </button>
                  </li>
              ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
  //------------------------------------------------------------------
  async function getInvoiceTrackingCodes() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/AdminPanel/Invoices/GetTrackingCodes?page=${currentPage}`)
        .then((result) => {
          if (result.data.status === "success"){
            setInvoiceTrackingCodes(result.data.data.TrackingCodes);
            setTotalPages(result.data.data.TotalPages);
          } 
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function invoiceInquery(e, invoiceId) {
    const loadingContainer = document.getElementById("loadingContainer");
    const errorMessageContainer = document.getElementById("result-error-message");
    try {
      loadingContainer.classList.remove("d-none");
      errorMessageContainer.classList.add("d-none");
      const refNumber = e.target.firstChild.innerHTML;
      const userId = e.target.children[1].innerHTML;

      await axiosConfig
        .get(
          `/Client/Tax/InqueryByReferenceNumber?referenceNumber=${refNumber}&userId=${userId}&onlyReturnStatus=true`
        )
        .then((result) => {
          if (result.data.status === "success") {
            const resultStatus = result.data.data;
            toast.success(resultStatus);
            setItemStatus({ [invoiceId]: resultStatus });
          } 
          else{
            toast.error(result.data.message);
            const errorMessage = result.data.message.replace(/\r\n/g, "<br>")
            errorMessageContainer.innerHTML = errorMessage;
            errorMessageContainer.classList.remove("d-none")
          } 
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  function copyRefNumber(e) {
    // const refNumber = document.getElementById("refNumber").innerHTML;
    const refNumber = e.target.firstChild.innerHTML;
    navigator.clipboard.writeText(refNumber);
    toast.success(PublicConsts.Info_CopyReferenceNumber);
  }
  function copyUidNumber(e) {
    // const uidNumber = document.getElementById("uidNumber").innerHTML;
    const uidNumber = e.target.firstChild.innerHTML;
    navigator.clipboard.writeText(uidNumber);
    toast.success(PublicConsts.Info_CopyUidNumber);
  }
  //------------------------------------------------------------------
};

export default InvoiceReport;
