const FormInput = ({
  label,
  id,
  name,
  type,
  defValue,
  onChange,
  onKeyDown,
  placeHolder,
  style,
  required,
  className,
  maxLength,
  pattern,
  min,
  max,
  autoComplete,
  disabled,
}) => {
  return (
    <div className="mb-3">
      {label ? (
        <label htmlFor={name} className="form-label">
          {label}
        </label>
      ) : (
        ""
      )}

      <input
        type={type}
        id={id ? id : name}
        name={name}
        defaultValue={defValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeHolder}
        style={style}
        required={required}
        maxLength={maxLength}
        pattern={pattern}
        min={min}
        max={max}
        autoComplete={autoComplete}
        disabled={disabled}
        className={`form-control ${className ? className : ""}`}
      />
    </div>
  );
};

export default FormInput;
