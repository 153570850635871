import { useState, useEffect } from "react";
import axiosConfig from "../../axiosConfig";
import { PublicConsts } from "../../publicConsts";
import FormInput from "../htmlForm/formInput";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
// import { ReCaptchaProvider, ReCaptchaV3 } from "react-recaptcha-x";
import "../../assets/css/login.css";


const Supervisor = () => {
  const setToken = (token) => {
    localStorage.setItem("token", token);
  };

//Google Recaptcha ---------------------------------------------------------------------------
const reCaptchaSiteKey = "6LdV42QnAAAAAMJ8hnnj8hjJpTK7zkUeGvqigQM1";
// const [reCaptchaToken, setReCaptchaToken] = useState("");
// const refreshReCaptchaToken = useRef("");
//-------------------------------------------------------------------------------------------


useEffect(() => {
  const loadReCaptchaScript = () => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  };

  // Call this function to load the script
  loadReCaptchaScript();

});

let [countdown, setCountdown] = useState(120);
let [countdownInterval, setCountdownInterval] = useState(null);

let [isLoginByPassword, setIsLoginByPassword] = useState(false);

  return (
    <div className="main-container vh-100">
      <ToastContainer rtl />

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4 col-xxl-5 mt-5">
            <div className="card shadow-lg border-0 my-5 rounded-4">
              <div className="card-body p-0">
                <div className="p-5">
                  <div className="text-center">
                    <h4 className="text-dark mb-4">ورود به پنل</h4>
                  </div>
                  <form className="user">
                    <FormInput
                      label="شماره موبایل"
                      type="tel"
                      name="username"
                      className="input-text"
                      maxLength="11"
                      onChange={usernameCheck}
                    />
                    <div id="usernameError" className="d-none text-danger mb-3">
                      <small>شماره موبایل صحیح نمی باشد</small>
                    </div>
                    <FormInput
                      name="verifyCode"
                      type="number"
                      placeHolder="کد تایید"
                      autoComplete="off"
                      className="input-text d-none"
                    />
                    <FormInput
                      name="password"
                      type="password"
                      label="رمز عبور"
                      autoComplete="off"
                      className="input-text d-none"
                    />
                    <button
                      id="loginButton"
                      type="button"
                      className="btn btn-success text-white d-none w-100 py-3 mt-4"
                      onClick={loginProcess}
                    >
                      ورود 
                      <i id="loginButtonSpinner" className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button>
                    <button
                      id="sendCodeButton"
                      type="button"
                      className="btn btn-primary text-white d-block w-100 py-3 mt-4"
                      onClick={sendVerifyCode}
                    >
                      ارسال کد تایید 
                      <i id="sendCodeSpinner" className="fa fa-spin fa-spinner mx-1 d-none"></i>
                      <span id="countDownDisplay" className='mx-1'></span>
                    </button>
                    <hr />
                    <a
                      className="btn btn-link text-decoration-none text-info float-start p-0"
                      href="/"
                    >
                      بازگشت به سایت
                      <i className="fa fa-arrow-left mx-1"></i>
                    </a>
                    <button
                      id="changeloginMethod"
                      type="button"
                      className="passLogin btn text-info float-end p-0"
                      onClick={changeloginMethod}
                    >
                      <i className="fa fa-key mx-1"></i>
                      <span>ورود با رمز عبور</span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-link text-decoration-none fw-bold text-info"
                      data-bs-toggle="modal"
                      data-bs-target="#resetPasswordModal"
                    >
                      فراموشی رمز عبور
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal------------------------------------------------------------------- */}
      <div className="modal fade" id="resetPasswordModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">بازیابی رمز عبور</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#resetPasswordModal"
              ></button>
            </div>
            <div className="modal-body">
              <FormInput
                id="modalUsername"
                name="modalUsername"
                placeHolder="شماره موبایل را وارد کنید"
                className="input-text"
                maxLength="11"
                onChange={usernameCheck}
              />
              <div id="modalUsernameError" className="d-none text-danger mb-3">
                <small>شماره موبایل صحیح نمی باشد</small>
              </div>
              <FormInput
                id="verifyCode"
                name="verifyCode"
                className="input-text d-none"
                placeHolder="کد تایید دریافت شده را وارد کنید"
              />
              <button
                id="sendNewCode"
                type="button"
                className="btn btn-primary"
                onClick={() => sendVerifyCode()}
              >
                ارسال کد تایید
                <i id="sendVerifyCodeSpinner" className="fa fa-spin fa-spinner mx-1 d-none"></i>
              </button>
              <button
                id="verifyMobile"
                type="button"
                className="btn btn-success d-none"
                onClick={() => ResetPassword()}
              >
                بازیابی رمز عبور
                <i id="verifyMobileSpinner" className="fa fa-spin fa-spinner mx-1 d-none"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  //-----------------------------------------
  function usernameCheck(e) {
    const text = e.target.value;

    if (e.target.id === "username") {
      const usernameError = document.getElementById("usernameError");
      const sendCodeButton = document.getElementById("sendCodeButton");
      const loginButton = document.getElementById("loginButton");

      if (text.length !== 11 || text.startsWith("09") === false) {
        usernameError.classList.remove("d-none");
        sendCodeButton.classList.add("disabled");
        loginButton.classList.add("disabled");
      } else {
        usernameError.classList.add("d-none");
        sendCodeButton.classList.remove("disabled");
        loginButton.classList.remove("disabled");
      }
    }
  }
  async function sendVerifyCode() {
    const sendCodeButton = document.getElementById("sendCodeButton");
    const spinner = document.getElementById("sendCodeSpinner");
    try {
      let recaptchaToken = await getRecaptchaToken();
      sendCodeButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      const username = document.getElementById("username").value;
      //---------------------------------------------------------------------
      const details = {
        Username: username,
        ReCaptchaToken: recaptchaToken,
      };
      await axiosConfig
        .post(`/Account/SendVerifyCode`, details)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);

             //countdown
            //----------------------------------
            verifyButtonCountdown();
            setCountdownInterval(setInterval(verifyButtonCountdown, 1000));
            setTimeout(() => {
              clearInterval(countdownInterval);
              sendCodeButton.classList.remove("disabled");
            }, countdown * 1000);
            //--------------------------------

            const verifyCodeInput = document.getElementById("verifyCode");
            const loginButton = document.getElementById("loginButton");
            verifyCodeInput.classList.remove("d-none");
            loginButton.classList.remove("d-none");
            sendCodeButton.classList.add("disabled");
          } else {
            toast.error(result.data.message);
            sendCodeButton.classList.remove("disabled");
          }
        })
        .catch((error) => {
          console.log(error);
          sendCodeButton.classList.remove("disabled");
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
      sendCodeButton.classList.remove("disabled");
    }
    spinner.classList.add("d-none");

  }
  async function loginProcess() {
    const loginButton = document.getElementById("loginButton");
    const spinner = document.getElementById("loginButtonSpinner");
    try {
      let reCaptchaToken = await getRecaptchaToken();
      const username = document.getElementById("username").value;
      const password = document.getElementById("password").value;
      const verifyCode = document.getElementById("verifyCode").value;
      loginButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      //---------------------------------------------------------------------
      const loginDetails = {
        Username: username,
        Password: password,
        VerifyCode: verifyCode,
        ReCaptchaToken: reCaptchaToken,
        IsLoginByPassword: isLoginByPassword,
      };
      await axiosConfig
        .post(`/Account/GetToken`, loginDetails)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            setToken(result.data.data);
            window.location = "/AdminPanel/InvoiceList";
          } else {
            toast.error(result.data.message);
            if(result.data.data === "mobileNotConfirmed")
            {
              var button = document.getElementById("verifyMobileModalOpenerButton");
              button.click();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
      console.log(error)
    }
    loginButton.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  function verifyButtonCountdown() {
    const element = document.getElementById('countDownDisplay');
    setCountdown(120);
    if (countdown > 0) {
      element.innerHTML = `${countdown}`;
      setCountdown(countdown--);
    } else {
      element.innerHTML = '';
    }
  }
  function getRecaptchaToken() {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(reCaptchaSiteKey, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  }  
  function changeloginMethod(){
    const targetButton = document.getElementById("changeloginMethod");
    const targetButtonIcon = document.querySelector("#changeloginMethod i")
    const targetButtonText = document.querySelector("#changeloginMethod span")
    const sendCodeButton = document.getElementById("sendCodeButton");
    const loginButton = document.getElementById("loginButton");
    const verifyCodeInput = document.getElementById("verifyCode");
    const passwordInput = document.getElementById("password");
  
    if(targetButton.classList.contains("passLogin"))
    {
      sendCodeButton.classList.add("d-none");
      verifyCodeInput.classList.add("d-none");
      loginButton.classList.remove("d-none");
      passwordInput.classList.remove("d-none");
      targetButton.classList.remove("passLogin")
      targetButtonIcon.classList.remove("fa-key")
      targetButtonIcon.classList.add("fa-lock")
      targetButtonText.innerHTML = "ورود با کد تایید"
      setIsLoginByPassword(true);
    }
    else{
      sendCodeButton.classList.remove("d-none");
      verifyCodeInput.classList.remove("d-none");
      loginButton.classList.add("d-none");
      passwordInput.classList.add("d-none");
      targetButton.classList.add("passLogin")
      targetButtonIcon.classList.remove("fa-lock")
      targetButtonIcon.classList.add("fa-key")
      targetButtonText.innerHTML = "ورود با رمز عبور"
      setIsLoginByPassword(false);
    }
  }
  async function ResetPassword() {
    const submitButton = document.getElementById("verifyMobile");
    const spinner = document.getElementById("verifyMobileSpinner");
    try {
      submitButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      const username = document.getElementById("modalUsername").value;
      const verifyCode = document.getElementById("verifyCode").value;
      //---------------------------------------------------------------------
      await axiosConfig
        .post(`/Account/ResetPassword?username=${username}&verifyCode=${verifyCode}`)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    submitButton.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  //-----------------------------------------
};
export default Supervisor;
