import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Routing from "./Routing";
import AdminLayout from "./components/layout/adminLayout";
import ClientLayout from "./components/layout/clientLayout";
import NotFound from "./components/pages/notFound";
import Error403 from "./components/pages/error403";
import Login from "./components/pages/login";
import AgentDetails from "./components/pages/agentDetails";
// import Register from "./components/pages/register";
import Supervisor from "./components/pages/supervisor";
import Landing from "./components/pages/landing";
import Checkout from "./components/pages/checkout";
import PaymentResult from "./components/pages/paymentResult";
import RegisterResult from "./components/pages/registerResult";
import Dashboard from "./components/pages/Client/dashboard";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const App = () => {
  const currentPath = useLocation().pathname;

 

  const getToken = () => {
    const tokenFromStorage = localStorage.getItem("token");
    const userToken = tokenFromStorage;
    return userToken;
  };

  const token = getToken();

  //Return to Login if Token is Null
  //=============================================================================================
  if (token === null &&
     currentPath !== "/Register" && 
      currentPath !== "/Supervisor" && 
      currentPath !== "/Landing" && 
      currentPath !== "/Checkout" &&
      currentPath !== "/PaymentResult" &&
      currentPath !== "/RegisterResult" &&
      currentPath !== "/Error403" &&
      !currentPath.startsWith("/AgentDetails/") &&
      currentPath !== "/" ) {
    return <Login />;
  }
  //Prevent Showing Account pages if Token is not null
  //=============================================================================================
  else if (
    token !== null &&
    ( currentPath === "/Login" ||
      currentPath === "/Register" ||
      currentPath === "/Supervisor")
  ) {
    //Go to Client Panel
    //---------------------------------------------------------------------------------------------
    if (currentPath === "/Login" || currentPath === "/Register" || currentPath.startsWith("/Client")) {
      return <ClientLayout mainContent={<Dashboard />} />;
    }

    //Go to Admin Panel
    //---------------------------------------------------------------------------------------------
    if (currentPath === "/Supervisor" || currentPath.startsWith("/AdminPanel")) {
      return <AdminLayout mainContent={<Dashboard />} />;
    }
  }

  //Main Routing Proccess
  //=============================================================================================
  else {
    return (
      <>
        {/* Toast Message Container */}
        <ToastContainer rtl />

        {/* Routing
      --------------------------------------------- */}
        {!currentPath.startsWith("/Client") &&
        !currentPath.startsWith("/AdminPanel")? (
          <Routes>
            <Route path="/NotFound" exact Component={NotFound} />
            <Route path="/Error403" exact Component={Error403} />
            <Route path="/AgentDetails/:id" exact Component={AgentDetails} />
            <Route path="/Login" exact Component={Login} />
            {/* <Route path="/Register" exact Component={Register} /> */}
            <Route path="/Supervisor" exact Component={Supervisor} />
            <Route path="/Landing" exact Component={Landing} />
            <Route path="/Checkout" exact Component={Checkout} />
            <Route path="/PaymentResult" exact Component={PaymentResult} />
            <Route path="/RegisterResult" exact Component={RegisterResult} />
            <Route path="/" exact Component={Landing} />
          </Routes>
        ) :

        //Go to Client Panel
        //---------------------------------------------------------------------------------------------
        currentPath.startsWith("/Client") ? (
          <ClientLayout mainContent={<Routing />} />
        ) : //Go to Admin Panel
        //---------------------------------------------------------------------------------------------
        currentPath.startsWith("/AdminPanel") ? (
          <AdminLayout mainContent={<Routing />} />
        ) : (
          <Route path="/" exact Component={Landing} />
        )}
      </>
    );
  }
};

export default App;
