import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const Products = () => {
  return (
    <>
      <div className="card shadow-lg">
        <div className="card-header py-3">
          <span className="h5 text-info m-0 fw-bold">بروزرسانی کالاها و خدمات</span>          
        </div>
        <div className="card-body">
          <div className="alert alert-danger"><i className="fa fa-info-circle mx-1"></i>این پروسه بسیار طولانی و زمان بر می باشد</div>
          <div className="alert alert-warning"><i className="fa fa-info-circle mx-1"></i>تمام کالا از دیتابیس حذف شده، شمارنده آی دی دیتابیس ریست شده و کالاهای جدید اضافه می شوند</div>
          <div className="alert alert-info"><i className="fa fa-info-circle mx-1"></i>
          <p>- آپلود فایل zip که دانلود شده از سایت stuffid.tax.gov.ir که شامل فایل csv محصولات می باشد.</p>
          <p>- نوع ستون ID  در فایل اکسل باید number شود(دقت شود موقع تغییر تایپ، اکسل عدد آی دی ها را round نکند).</p>
          <p>- ستون بدون هدر نباید در فایل اکسل وجود داشته باشد(ممکن است بعضی ستون های انتهایی فایل که عنوان ندارند، به عنوان ستون بدون هدر شناخته شود. میشه اونا رو حذف کرد)</p>
          </div>
          <div id="loadingContainer" className="alert alert-danger d-none">
            در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
          </div>
          <form id="productsForm" method="post" encType="multipart/form-data">
              <label>انتخاب فایل کالاها</label>
            <div className="my-3">
            <input type="file" className="form-control" id="csvFile" name="file" accept=".zip" required />
            </div>
            <button className="btn btn-success" type="button" onClick={updateProducts}
            >بروزرسانی کالاها
            <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
            </button>
          </form>
        </div>
      </div>
    </>
  );
  
  //------------------------------------------------------------------

  async function updateProducts(e) {
    
    const spinner = document.getElementsByClassName("fa-spinner")[1];

    try {
      e.target.classList.add("disabled");
      spinner.classList.remove("d-none");
      //-----------------------------------
      var csvFile = document.querySelector('#csvFile');
      const formData = new FormData();
      formData.append('file', csvFile.files[0]);
      //----------------------------------

      await axiosConfig
        .post(`/AdminPanel/Products/UpdateProducts/`, formData)
        .then((result) => {

          if (result.data.status === "success") {
            toast.success(result.data.message);
            csvFile.value = "";
          } 
          else {
            toast.error(result.data.message);
            console.log(result.data.message)
          }
          
        })
        .catch((error) => {
          toast.error(error);
            console.log(error)
        });
    } catch {
      
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  //------------------------------------------------------------------
};

export default Products;
