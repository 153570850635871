import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const InvoiceDetails = () => {
  const [invoiceDetails, setInvoiceDetails] = useState("");
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <>
      <div className="alert alert-info">
        <i className="fa fa-info-circle mx-1"></i>مشخصات صورت حساب شماره
        <b className="text-danger mx-1">{invoiceDetails.Id}</b>
        <a href="/AdminPanel/InvoiceList" className="btn btn-sm btn-primary float-end">
          بازگشت
        </a>
      </div>
      {invoiceDetails !== "" ? (
        <div>
          {/* Tracking Codes--------------------------------------------------------- */}
          <div className="card shadow-lg mb-3">
            <div className="card-header">
              <h5>کد پیگیری</h5>
            </div>
            <div className="card-body">
            <div className="row row-cols-1 row-cols-sm-2 mb-4">
                <div className="col my-2">
                  <span className="mb-2 mx-1">سریال صورتحساب:</span>
                  <strong id="refNumber">{invoiceDetails.InvoiceSerial}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">شماره مالیاتی صورتحساب:</span>
                  <strong id="refNumber">{invoiceDetails.TaxId}</strong>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 mb-4">
                <div className="col my-2">
                  <span className="mb-2 mx-1">شماره رفرنس:</span>
                  <strong id="refNumber">{invoiceDetails.ReferenceNumber}</strong>
                  <button type="button" onClick={() => copyRefNumber()}>
                    <i className="fa fa-copy text-danger mx-1" title="کپی"></i>
                  </button>
                </div>
                <div className="col my-2">
                  <bdi className="mb-2 mx-1">کد Uid:</bdi>
                  <strong id="uidNumber">{invoiceDetails.Uid}</strong>
                  <button type="button" onClick={() => copyUidNumber()}>
                    <i className="fa fa-copy text-danger mx-1" title="کپی"></i>
                  </button>
                </div>
                </div>
                
            </div>
          </div>

          {/* Invoice Types--------------------------------------------------------- */}
          <div className="card shadow-lg mb-3">
            <div className="card-header">
              <h5>نوع صورتحساب</h5>
            </div>
            <div className="card-body">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 mb-4">
                <div className="col my-2">
                  <span className="mb-2 mx-1">نوع صورتحساب:</span>
                  <strong>{invoiceDetails.InvoiceType}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">موضوع صورتحساب:</span>
                  <strong>{invoiceDetails.InvoiceSubject}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">نوع طرف حساب:</span>
                  <strong>{invoiceDetails.BuyerType}</strong>
                </div>

                <div className="col my-2">
                  <span className="mb-2 mx-1">ماهیت صورت حساب:</span>
                  <strong>{invoiceDetails.SellingType}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">روش پرداخت:</span>
                  <strong>{invoiceDetails.SettlementMethod}</strong>
                </div>
              </div>
            </div>
          </div>

          {/* Invoice Details--------------------------------------------------------- */}
          <div className="card shadow-lg mb-3">
            <div className="card-header">
              <h5>جزییات صورتحساب</h5>
            </div>
            <div className="card-body">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                <div className="col my-2">
                  <span className="mb-2 mx-1">نام:</span>
                  <strong>{invoiceDetails.Fullname}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">کد ملی:</span>
                  <strong>{invoiceDetails.NationalCode}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">کد پستی:</span>
                  <strong>{invoiceDetails.PostCode}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">استان:</span>
                  <strong>{invoiceDetails.Province}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">شهر:</span>
                  <strong>{invoiceDetails.City}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">شماره تماس:</span>
                  <strong>{invoiceDetails.PhoneNumber}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">شماره فاکتور:</span>
                  <strong>{invoiceDetails.InvoiceNumber}</strong>
                </div>                
                <div className="col my-2">
                  <span className="mb-2 mx-1">تاریخ صدور:</span>
                  <strong>{invoiceDetails.IssueDate}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12 my-2">
                  <span className="mb-2 mx-1">آدرس:</span>
                  <strong>{invoiceDetails.Address}</strong>
                </div>
              </div>
            </div>
          </div>
          {/* Invoice Product list--------------------------------------------------------- */}
          <div className="card shadow-lg table-responsive mb-3">
            <div className="card-header">
              <span className="h5">لیست کالا و خدمات</span>
            </div>
            <div className="card-body table-responsive">
              <table className="table table-striped text-center">
                <thead>
                  <tr>
                    <th>ردیف</th>
                    <th>شناسه کالا</th>
                    <th>شرح کالا</th>
                    <th>واحد</th>
                    <th>تعداد/مقدار</th>
                    <th>مبلغ واحد</th>
                    <th>مبلغ قبل از تخفیف</th>
                    <th>مبلغ تخفیف</th>
                    <th>مبلغ بعد از تخفیف</th>
                    <th>نرخ مالیات بر ارزش افزوده</th>
                    <th>مبلغ ارزش افزوده</th>
                    <th>مبلغ کل</th>
                  </tr>
                </thead>
                {invoiceDetails.InvoiceProducts.length > 0 ? (
                  <>
                    <tbody>
                      {invoiceDetails.InvoiceProducts.map((product, index) => (
                        <tr key={product.Id}>
                          <td>{index + 1}</td>
                          <td>{product.ProductId}</td>
                          <td>{product.DescriptionOfID}</td>
                          <td>{product.Unit}</td>
                          <td>{product.Quantity}</td>
                          <td>{product.Price}</td>
                          <td>{product.PriceBeforeDiscount}</td>
                          <td>{product.Discount}</td>
                          <td>{product.PriceAfterDiscount}</td>
                          <td>{product.Vat}%</td>
                          <td>{product.VatPrice}</td>
                          <td>{product.FinalPrice}</td>
                        </tr>
                      ))}
                    </tbody>

                    <tfoot className="alert alert-primary">
                      <tr>
                        <th>{invoiceDetails.InvoiceProducts.length}</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>{invoiceDetails.InvoiceTotals.QuantitySum}</th>
                        <th>{invoiceDetails.InvoiceTotals.PriceSum}</th>
                        <th>{invoiceDetails.InvoiceTotals.PriceBeforeDiscountSum}</th>
                        <th>{invoiceDetails.InvoiceTotals.DiscountSum}</th>
                        <th>{invoiceDetails.InvoiceTotals.PriceAfterDiscountSum}</th>
                        <th>-</th>
                        <th>{invoiceDetails.InvoiceTotals.VatPriceSum}</th>
                        <th>{invoiceDetails.InvoiceTotals.FinalPriceSum}</th>
                      </tr>
                    </tfoot>
                  </>
                ) : (
                  ""
                )}
              </table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
  //------------------------------------------------------------------
  async function getDetails() {
    try {
      const invoiceId = window.location.pathname.split("/").pop();
      const result = await axiosConfig.get(`/AdminPanel/Invoices/GetInvoice/${invoiceId}`);
      if (result !== null) {
        if (result.data.status === "success") setInvoiceDetails(result.data.data);
        else toast.error(result.data.message);
      }
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
};
function copyRefNumber() {
  const refNumber = document.getElementById("refNumber").innerHTML;
  navigator.clipboard.writeText(refNumber);
  toast.success(PublicConsts.Info_CopyReferenceNumber);
}
function copyUidNumber() {
  const uidNumber = document.getElementById("uidNumber").innerHTML;
  navigator.clipboard.writeText(uidNumber);
  toast.success(PublicConsts.Info_CopyUidNumber);
}

export default InvoiceDetails;
