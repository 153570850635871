import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";
import FormInput from "../../htmlForm/formInput";
import FormTextArea from "../../htmlForm/formTextArea";

const TicketDetails = () => {
  const [ticketDetails, setTicketDetails] = useState("");
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <>
      <div className="alert alert-info">
        <i className="fa fa-info-circle mx-1"></i>مشخصات تیکت شماره
        <b className="text-danger mx-1">{ticketDetails.Id}</b>
        <a href="/Client/Tickets" className="btn btn-sm btn-primary float-end">
          بازگشت
        </a>
        
      </div>
      {ticketDetails !== "" ? (
        <div>

          {/* Ticket  Details--------------------------------------------------------- */}
          <div className="card shadow-lg mb-3">
            <div className="card-header">
              <h5>جزییات تیکت</h5>
            </div>
            <div className="card-body">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                <div className="col my-2">
                  <span className="mb-2 mx-1">شماره:</span>
                  <strong>{ticketDetails.Id}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">عنوان:</span>
                  <strong>{ticketDetails.Title}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">تاریخ ثبت:</span>
                  <strong><bdi>{ticketDetails.Date}</bdi></strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">آخریت آپدیت:</span>
                  <strong><bdi>{ticketDetails.LastUpdateDate}</bdi></strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">وضعیت:</span>
                  <strong>{ticketDetails.Status}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12 my-2">
                  <span className="mb-2 mx-1">توضیحات:</span>
                  <strong>{ticketDetails.Content}</strong>
                </div>
              </div>
            </div>
          </div>
          {/* Invoice Product list--------------------------------------------------------- */}
          <div className="card shadow-lg mb-3">
            <div className="card-header">
              <span className="h5">پاسخ ها</span>
              <button
              type="button"
              className="btn btn-primary btn-sm float-end"
              data-bs-toggle="modal"
              data-bs-target="#replayModal"
            >
              <i className="fa fa-plus mx-1"></i>ثبت پاسخ
            </button>
            </div>
            <div className="card-body">
            {ticketDetails.Replies.length > 0 ? (
                  
                  ticketDetails.Replies.map((replay) => (
                    <div className="card mb-3 shadow-lg" key={replay.Id}>
                      <div className="card-body">
                        <div>{replay.Content}</div>
                        <div className="mt-3 border-top">
                          <small>
                          <bdi>{replay.Date}</bdi>
                          <span className="float-end">{replay.Username}</span>
                          </small>
                          </div>
                      </div>
                    </div>
                      
                     ) )) : (

                      <div className="alert alert-warning">پاسخی ثبت نشده است</div>
                )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* Modal------------------------------------------------------------------- */}
      <div className="modal fade" id="replayModal">
        <div className="modal-dialog">
          <div className="modal-content">
          <div className="modal-header bg-info text-white">
              <h5 className="modal-title">پاسخ جدید</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#replayModal"
              ></button>
            </div>
            <div className="modal-body">
            <form id="newReplayForm">
              
              <FormTextArea
                id="Content"
                name="Content"
                placeHolder="متن پاسخ"
              />
              <FormInput type="file" disabled={true}></FormInput>
              <FormInput type="hidden" name="TicketId" defValue={ticketDetails.Id} />
              <button
                id="addReplay"
                type="button"
                className="btn btn-success"
                onClick={addReplay}
              >
                ثبت پاسخ
                <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
              </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  //------------------------------------------------------------------
  async function getDetails() {
    try {
      const ticketId = window.location.pathname.split("/").pop();
      const result = await axiosConfig.get(`/Client/Tickets/GetTicket/${ticketId}`);
      if (result !== null) {
        if (result.data.status === "success") setTicketDetails(result.data.data);
        else toast.error(result.data.message);
      }
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
  async function addReplay(e) {

    const spinner = document.getElementsByClassName("fa-spinner")[0];

    try {
      e.target.classList.add("disabled");
      spinner.classList.remove("d-none");
      //-----------------------------------
      const form = document.getElementById("newReplayForm");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      await axiosConfig
        .post(`/Client/Tickets/NewReplay/`, formDetails)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            form.reset();
            getDetails();
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          return error.message;
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
};

export default TicketDetails;
