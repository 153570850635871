import { useEffect, useState } from "react";
import { PublicConsts } from "../../publicConsts";
import axiosConfig from "../../axiosConfig";


const PaymentResult = () => {

  //Google Recaptcha ---------------------------------------------------------------------------
  const reCaptchaSiteKey = "6LdV42QnAAAAAMJ8hnnj8hjJpTK7zkUeGvqigQM1";

//Add google recaptcha script to header
  useEffect(() => {

    const loadReCaptchaScript = () => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        getVerifyResult();
      };

      document.head.appendChild(script);
    };

    // Call this function to load the script
    loadReCaptchaScript();
  }, []);

  const [result, setResult] = useState([]);

  //-------------------------------------------------------------------------------------------

    return (
      <div className="pt-3">
      <div id="loadingContainer" className="alert alert-danger d-none">
        در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
      </div>
      <div>
        <div className="card">
          <div className={`card-header bg-info text-white ${result.className}`}>
            <h5>نتیجه پرداخت</h5>
          </div>
          <div className="card-body">
          {(result.refId? 
          <>
          <div className="alert alert-success">{result.message}</div>
          <div className="mb-3">کد پیگیری : <span className="text-success fw-bold">{result.refId}</span></div>
          <a href="/Login">انتقال به صفحه ورود</a>
          </>
          :
          <div className="alert alert-danger">{result.message}</div>
          )}
          </div>
        </div>
      </div>
      <div className="mt-3">
      <a href='/' className=' btn tbn-sm btn-primary'>صفحه اصلی</a>
    </div>
    </div>
    );
    async function getVerifyResult(){
      const loadingContainer = document.getElementById("loadingContainer");
      loadingContainer.classList.remove("d-none");

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const status = urlParams.get('Status')
      const authority = urlParams.get('Authority')

      const recaptchaToken = await getRecaptchaToken();
      
      if(status === "OK")
      {
        //VerifyPayment
        try {
          await axiosConfig
            .post(`/Checkout/VerifyPayment?authority=${authority}&recaptchaToken=${recaptchaToken}`)
            .then((result) => {
              if (result.data.status === "success") {
                setResult(
                  {
                    className:"bg-success", 
                    message: PublicConsts.Payment_Result_Success,
                    refId: result.data.data
                })
              } else {
                setResult({className:"bg-danger", message: result.data.message})
              }
            })
            .catch((error) => {
              console.log(error)
            });
        } catch (error) {
          console.log(error)
        }
      }
      else if(status === "NOK")
      {
        setResult({className:"bg-danger", message: PublicConsts.Payment_Result_Fail})
      }
      
      loadingContainer.classList.add("d-none");
    }

    function getRecaptchaToken() {
      return new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(reCaptchaSiteKey, { action: 'submit' })
            .then(token => {
              resolve(token);
            })
            .catch(error => {
              reject(error);
            });
        });
      });
    }
    //---------------------------------------------------
  };
  
  export default PaymentResult;
  