import FormInput from "../../../htmlForm/formInput";
import FormTextArea from "../../../htmlForm/formTextArea";

const NewTicket = (props) =>{
    return(
        <>
        <div className="modal-header bg-info">
              <h5 className="modal-title text-white">تیکت جدید</h5>
              <button type="button" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#publicModal"><i className="fa fa-close text-white"></i></button>
            </div>
            <div className="modal-body">
            <form id="newTicketForm">
              <FormInput
                id="Title"
                name="Title"
                placeHolder="موضوع تیکت"
              />
              <FormTextArea
                id="Content"
                name="Content"
                placeHolder="متن تیکت"
              />
              <FormInput type="file" disabled={true}></FormInput>
              <button
                id="addTicket"
                type="button"
                className="btn btn-success"
                onClick={addTicket}
              >
                <i className="fa fa-send mx-1"></i>
                ثبت تیکت
                <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
              </button>
              </form>
            </div>
        </>
    )
    //--------------------------------------------
    function  addTicket(e){
        props.addTicketFunction(e);
    }
}

export default NewTicket;
