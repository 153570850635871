import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const InvoiceReport = () => {
  const [itemStatus, setItemStatus] = useState("");
  const [invoiceTrackingGroups, setInvoiceTrackingGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  useEffect(() => {
    getInvoiceTrackings(false);
  }, []);


  return (
    <>
    {process.env.NODE_ENV === "development"? <div className="alert alert-danger mb-3">در حالت Development  صورتحساب ها در SandBox ذخیره می شوند</div>:""}
    <div id="result-error-message" className="alert alert-danger d-none mb-3"></div>
      <div className="card shadow-lg">
        <div className="card-header py-3">
          <span className="h5 text-primary m-0 fw-bold">گزارشات ارسال صورتحساب</span>
          <span className="btn btn-sm btn-outline-primary float-end" onClick={() => getInvoiceTrackings(false)}>
            <i className="fa fa-refresh mx-1"></i>بروزرسانی
          </span>
        </div>
        <div className="card-body bg-light">
          <div id="loadingContainer" className="alert alert-danger d-none sticky-top mb-3">
            در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
          </div>
          <div className="input-group mb-3">
            <input type="text" className="form-control rounded-start" id="search" placeholder="شماره صورتحساب را وارد کنید"/>
              <button type="button" className="btn btn-primary" onClick={()=>getInvoiceTrackings(true)}><i className="fa fa-search mx-1"></i>جستجو کن</button>
          </div>
              <div className="accordion" id="reportListAccordion">
                {invoiceTrackingGroups.length > 0 ? (
                  invoiceTrackingGroups.map((invoice) => (
                    
                  <div className="accordion-item bg-white rounded mb-5 border-top" key={invoice.InvoiceId}>
                    <h2 className="accordion-header">
                      <button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target={`#collapse-${invoice.InvoiceId}`} >
                        <span className="text-primary">صورتحساب شماره <span className="text-warning">({invoice.InvoiceId})</span></span>
                        <span className="text-primary ms-3">تعداد ویرایش و ارسال <span className="text-warning">({invoice.TrackingCodes.length})</span></span>
                      </button>
                    </h2>
                    <div id={`collapse-${invoice.InvoiceId}`} className={`accordion-collapse collapse show`}>
                      <div className="accordion-body">
                      <div className="table-responsive">
                        <table className="table table-striped table-hover text-center">
                          <thead>
                            <tr>
                              <th>شماره</th>
                              <th>شماره فاکتور</th>
                              <th>سریال</th>
                              <th>نام</th>
                              <th>مبلغ کل</th>
                              <th>تاریخ  ثبت</th>
                              <th>ساعت</th>
                              <th>کد UID</th>
                              <th>شماره رفرنس</th>
                              <th>وضعیت</th>
                              <th>عملیات</th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoice.TrackingCodes.map(item => (
                              <tr key={item.Id}>
                                <td>{item.InvoiceId}</td>
                                <td>{item.Invoice.InvoiceNumber}</td>
                                <td>{item.InvoiceSerial}</td>
                                <td className="text-primary font-semibold">{item.Invoice.Fullname}</td>
                                <td>{item.Invoice.FinalPrice.toLocaleString()}</td>
                                <td>{item.InvoiceDate}</td>
                                <td>{item.InvoiceTime}</td>
                                <td><button className="btn btn-sm btn-outline-primary" onClick={(e) => copyUidNumber(e)} ><span className="d-none">{item.Uid}</span>کپی کن</button></td>
                                <td><button className="btn btn-sm btn-outline-primary" onClick={(e) => copyRefNumber(e)}><span className="d-none">{item.ReferenceNumber}</span>کپی کن</button></td>
                                <td>
                                  {item.Status === "موفق" ? (
                                    <span className="badge bg-success">{item.Status}</span>
                                  ) : item.Status === "ناموفق" ? (
                                    <span className="badge bg-danger">{item.Status}</span>
                                  ) : (
                                    <span key={item.Id} className="badge bg-primary">
                                      {itemStatus[item.Id]}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-warning"
                                    onClick={(e) => invoiceInquery(e, item.Id)}
                                  >
                                    <span className="refNumber d-none">{item.ReferenceNumber}</span>
                                    <i className="fa fa-info-circle mx-1"></i>استعلام وضعیت
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </div>
                    ))
                  ) : (
                    <div className="alert alert-warning">موردی یافت نشد</div>
                )}
                <nav>
                  <ul className="pagination">
                  {Array.from({ length: totalPages }, (_, index) => (
                      <li className="page-item" key={index + 1}>
                        <button className={`page-link ${currentPage === index+1? "active":""}`} onClick={() => setPaginationNumber(index + 1)}>
                          {index + 1}
                        </button>
                      </li>
                  ))}
                  </ul>
                </nav>
              </div>
        </div>
      </div>
    </>
  );
  //------------------------------------------------------------------
  async function getInvoiceTrackings(isSearch = false) {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");

      let searchText = "";
      if(isSearch) searchText = document.getElementById("search").value;

      await axiosConfig
        .get(`/Client/Invoices/GetTrackingCodes?search=${searchText}&page=${currentPage}`)
        .then((result) => {

          if (result.data.status === "success")
          {
            setInvoiceTrackingGroups(result.data.data.TrackingCodeGroups);
            setTotalPages(result.data.data.TotalPages)
          } 
          else{
            if(result.data.message !== "")toast.error(result.data.message);
          }
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function invoiceInquery(e, invoiceId) {
    const loadingContainer = document.getElementById("loadingContainer");
    const errorMessageContainer = document.getElementById("result-error-message");
    try {
      loadingContainer.classList.remove("d-none");
      errorMessageContainer.classList.add("d-none");
      const refNumber = e.target.firstChild.innerHTML;
      
      await axiosConfig
        .get(
          `/Client/Tax/InqueryByReferenceNumber?referenceNumber=${refNumber}&onlyReturnStatus=true`
        )
        .then((result) => {
          if (result.data.status === "success") {
            const resultStatus = result.data.data;
            toast.success(resultStatus);
            setItemStatus({ [invoiceId]: resultStatus });
          }else if(result.data.status === "pending"){
            toast.warning(result.data.message);
          } else{
            toast.error(result.data.message);
            
            const errorMessage = result.data.message.replace(/\r\n/g, "<br>")
            errorMessageContainer.innerHTML = errorMessage;
            errorMessageContainer.classList.remove("d-none")
          } 
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  function copyRefNumber(e) {
    const refNumber = e.target.firstChild.innerHTML;
    navigator.clipboard.writeText(refNumber);
    toast.success(PublicConsts.Info_CopyReferenceNumber);
  }
  function copyUidNumber(e) {
    const uidNumber = e.target.firstChild.innerHTML;
    navigator.clipboard.writeText(uidNumber);
    toast.success(PublicConsts.Info_CopyUidNumber);
  }
  function setPaginationNumber(page)
  {
    setCurrentPage(page);
    getInvoiceTrackings(false)

  }
  //------------------------------------------------------------------
};

export default InvoiceReport;
