import { Route, Routes } from "react-router-dom";

import AdminInquiryByRfn from "./components/pages/AdminPanel/inquiryByRfn";
import AdminInvoiceList from "./components/pages/AdminPanel/invoiceList";
import AdminInvoiceDetails from "./components/pages/AdminPanel/invoiceDetails";
import AdminSentInvoiceReport from "./components/pages/AdminPanel/sentInvoiceReport";
import AdminUserList from "./components/pages/AdminPanel/userList";
import AdminProducts from "./components/pages/AdminPanel/products";
import AdminTicketList from "./components/pages/AdminPanel/tickets";
import AdminTicketDetails from "./components/pages/AdminPanel/ticketDetails";
import AdminPricePlans from "./components/pages/AdminPanel/pricePlans";
import AdminSaleAgents from "./components/pages/AdminPanel/saleAgents";
import AdminDiscounts from "./components/pages/AdminPanel/discounts";
import AdminDashboard from "./components/pages/AdminPanel/dashboard";

import ClientDashboard from "./components/pages/Client/dashboard";
import ClientCreateInvoice from "./components/pages/Client/createInvoice";
import ClientCompanies from "./components/pages/Client/childCompanies";
import ClientCustomers from "./components/pages/Client/customers";
import ClientGetEcoCodeInfo from "./components/pages/Client/getEcoCodeInfo";
import ClientGetFiscalInfo from "./components/pages/Client/getFiscalInfo";
import ClientGetServiceStuffList from "./components/pages/Client/getServiceStuffList";
import ClientInvoiceList from "./components/pages/Client/invoiceList";
import ClientInquiryByTimeRange from "./components/pages/Client/inquiryByTimeRange";
import ClientInquiryByRfn from "./components/pages/Client/inquiryByRfn";
import ClientInquiryByTime from "./components/pages/Client/inquiryByTime";
import ClientInquiryByUid from "./components/pages/Client/inquiryByUid";
import ClientInvoiceDetails from "./components/pages/Client/invoiceDetails";
import ClientInvoicePrint from "./components/pages/Client/invoicePrint";
import ClientSentInvoiceReport from "./components/pages/Client/sentInvoiceReport";
import ClientUserSettings from "./components/pages/Client/userSettings";
import ClientTicketList from "./components/pages/Client/tickets";
import ClientTicketDetails from "./components/pages/Client/ticketDetails";

import NotFound from "./components/pages/notFound";
import Error403 from "./components/pages/error403";
import Login from "./components/pages/login";
import Register from "./components/pages/register";
import Supervisor from "./components/pages/supervisor";
import Landing from "./components/pages/landing";
import Checkout from "./components/pages/checkout";
import PaymentResult from "./components/pages/paymentResult";
import RegisterResult from "./components/pages/registerResult";
import AgentDetails from "./components/pages/agentDetails";

const RoutingPaths = () => {
  return (
    <Routes>
      <Route path="/AdminPanel/inquiryByRfn" Component={AdminInquiryByRfn} />
      <Route path="/AdminPanel/invoiceList" Component={AdminInvoiceList} />
      <Route path="/AdminPanel/invoiceDetails/:id" Component={AdminInvoiceDetails} />
      <Route path="/AdminPanel/sentInvoiceReport" Component={AdminSentInvoiceReport} />
      <Route path="/AdminPanel/userList" Component={AdminUserList} />
      <Route path="/AdminPanel/products" Component={AdminProducts} />
      <Route path="/AdminPanel/tickets" Component={AdminTicketList} />
      <Route path="/AdminPanel/ticketDetails/:id" Component={AdminTicketDetails} />
      <Route path="/AdminPanel/pricePlans" Component={AdminPricePlans} />
      <Route path="/AdminPanel/saleAgents" Component={AdminSaleAgents} />
      <Route path="/AdminPanel/discounts" Component={AdminDiscounts} />
      <Route path="/AdminPanel/dashboard" Component={AdminDashboard} />

      <Route path="/Client/dashboard" Component={ClientDashboard} />
      <Route path="/Client/createInvoice/:id?" Component={ClientCreateInvoice} />
      <Route path="/Client/companies" Component={ClientCompanies} />
      <Route path="/Client/customers" Component={ClientCustomers} />
      <Route path="/Client/getEcoCodeInfo" Component={ClientGetEcoCodeInfo} />
      <Route path="/Client/getFiscalInfo" Component={ClientGetFiscalInfo} />
      <Route path="/Client/getServiceStuffList" Component={ClientGetServiceStuffList} />
      <Route path="/Client/invoiceList" Component={ClientInvoiceList} />
      <Route path="/Client/inquiryByTimeRange" Component={ClientInquiryByTimeRange} />
      <Route path="/Client/inquiryByRfn" Component={ClientInquiryByRfn} />
      <Route path="/Client/inquiryByTime" Component={ClientInquiryByTime} />
      <Route path="/Client/inquiryByUid" Component={ClientInquiryByUid} />
      <Route path="/Client/invoiceDetails/:id" Component={ClientInvoiceDetails} />
      <Route path="/Client/invoicePrint/:id" Component={ClientInvoicePrint} />
      <Route path="/Client/sentInvoiceReport" Component={ClientSentInvoiceReport} />
      <Route path="/Client/settings" Component={ClientUserSettings} />
      <Route path="/Client/tickets" Component={ClientTicketList} />
      <Route path="/Client/ticketDetails/:id" Component={ClientTicketDetails} />

      <Route path="/Login" exact Component={Login} />
      <Route path="/Register" exact Component={Register} />
      <Route path="/Supervisor" exact Component={Supervisor} />
      <Route path="/Landing" exact Component={Landing} />
      <Route path="/Checkout" exact Component={Checkout} />
      <Route path="/PaymentResult" exact Component={PaymentResult} />
      <Route path="/RegisterResult" exact Component={RegisterResult} />
      <Route path="/Error403" exact Component={Error403} />
      <Route path="/AgentDetails/:id" exact Component={AgentDetails} />
      <Route path="/" exact Component={Login} />
      <Route path="*" Component={NotFound} />
    </Routes>
  );
};

export default RoutingPaths;
