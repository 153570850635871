import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const Dashboard = () => {
  const [statistics, setStatistics] = useState([]);
  const [invoices, setInvoices] = useState([]);
  useEffect(() => {
    getStatistics();
  }, []);
  return (
    <>
      <div id="loadingContainer" className="alert alert-danger d-none mb-2">
          در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
      </div>
      <div className="alert alert-warning p-2">
        <i className="fa fa-info-circle mx-1"></i>
        جهت ثبت شناسه یکتا و کلید خصوصی، از منوی
        <a className="btn btn-sm btn-danger mx-1" href="/Client/Settings">
          تنظیمات
        </a>
        استفاده کنید.
      </div>
      <hr />
      {/* ------------------------------------------------------------------------------------ */}      
      <div className="row g-6 mb-6">
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card shadow border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">صورتحساب ها</span>
                                        <span className="h3 font-bold mb-0">{statistics.InvoiceCount} عدد</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                            <i className="bi bi-credit-card"></i>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mt-2 mb-0 text-sm">
                                    <span className="badge badge-pill bg-soft-success text-success me-2">
                                        <i className="bi bi-arrow-up me-1"></i>13%
                                    </span>
                                    <span className="text-nowrap text-xs text-muted">از آخرین ماه</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card shadow border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">حقوقی</span>
                                        <span className="h3 font-bold mb-0">{statistics.LegalInvoiceCount} عدد</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                        <i className="fa fa-balance-scale"></i>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mt-2 mb-0 text-sm">
                                    <span className="badge badge-pill bg-soft-success text-success me-2">
                                        <i className="bi bi-arrow-up me-1"></i>30%
                                    </span>
                                    <span className="text-nowrap text-xs text-muted">از آخرین ماه</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card shadow border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">حقیقی</span>
                                        <span className="h3 font-bold mb-0">{statistics.IllegalInvoiceCount} عدد</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-info text-white text-lg rounded-circle">
                                        <i className="fa fa-user"></i>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mt-2 mb-0 text-sm">
                                    <span className="badge badge-pill bg-soft-danger text-danger me-2">
                                        <i className="bi bi-arrow-down me-1"></i>-5%
                                    </span>
                                    <span className="text-nowrap text-xs text-muted">از آخرین ماه</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card shadow border-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <span className="h6 font-semibold text-muted text-sm d-block mb-2">ارسال شده</span>
                                        <span className="h3 font-bold mb-0">{statistics.SentInvoiceCount} عدد</span>
                                    </div>
                                    <div className="col-auto">
                                        <div className="icon icon-shape bg-warning text-white text-lg rounded-circle">
                                            <i className="bi bi-send"></i>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="mt-2 mb-0 text-sm">
                                    <span className="badge badge-pill bg-soft-success text-success me-2">
                                        <i className="bi bi-arrow-up me-1"></i>10%
                                    </span>
                                    <span className="text-nowrap text-xs text-muted">از آخرین ماه</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
      </div>
      {/* ------------------------------------------------------------------------------------ */}

      <div className="card shadow-lg">
        <div className="card-header py-3">
          <span className="text-primary m-0 fw-bold">صورتحساب های اخیر</span>
          <a
            className="text-primary m-0 float-end"
            href="/InvoiceList"
          >
            نمایش همه
          </a>
        </div>
        <div className="card-body">
          <div className="table-responsive mt-2">
            <table className="table table-hover table-nowrap text-center">
              <thead className="thead-light">
                <tr>
                <th>شماره</th>
                  <th>نام</th>
                  <th>نوع</th>
                  <th>موضوع</th>
                  <th>طرف حساب</th>
                  <th>ماهیت صورت حساب</th>
                  <th>آخرین وضعیت</th>
                  <th>مبلغ کل</th>
                  <th>تاریخ</th>
                  <th>ساعت</th>
                  <th>وضعیت ویرایش</th>
                  <th>عملیات</th>
                </tr>
              </thead>
              <tbody>
              {invoices.length > 0 ? (
                  invoices.map((invoice) => (
                    <tr key={invoice.Id}>
                      <td>{invoice.Id}</td>
                      <td className="text-heading font-semibold">{invoice.Fullname}</td>
                      <td>{invoice.InvoiceType}</td>
                      <td>
                      <span className="badge badge-lg badge-dot">
                        <i className={
                            invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_New
                            ? "bg-primary"
                            : invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_Edit
                            ? "bg-warning"
                            : invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_Cancel
                            ? "bg-danger"
                            : invoice.InvoiceSubject === PublicConsts.Invoice_Subject_Fa_Return
                            ? "bg-primary"
                            : ""
                          }></i>
                        {invoice.InvoiceSubject}</span>
                        </td>
                      <td>{invoice.BuyerType}</td>
                      <td>{invoice.SellingType}</td>
                      <td>
                        <span
                          className={
                            invoice.Status === "ارسال شده"
                              ? "badge bg-warning"
                              : invoice.Status === "موفق"
                              ? "badge bg-success"
                              : "badge bg-danger"
                          }
                        >
                          {invoice.Status}
                        </span>
                      </td>
                      <td>{invoice.FinalPrice.toLocaleString()}</td>
                      <td>{invoice.InvoiceDate}</td>
                      <td>{invoice.InvoiceTime}</td>

                      <td>
                        {invoice.IsInUpdateMode ? (
                          <span className="badge bg-warning">ویرایش شده</span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <a
                          href={"/Client/InvoiceDetails/" + invoice.Id}
                          className="btn btn-sm btn-primary text-white mx-1 mb-1"
                          title="مشاهده صورت حساب"
                        >
                          <i className="fa fa-eye"></i>
                        </a>

                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="13">
                      <div className="alert alert-danger">موردی یافت نشد</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

  //-----------------------------------------
  async function getStatistics() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      //Statistics---------------------------------------------------
      await axiosConfig
        .get(`/Client/Dashboard/GetStatistics`)
        .then((result) => {
          if (result.data.status === "success") {
            setStatistics(result.data.data);
                        
            if (localStorage.getItem('name') !== result.data.data.CompanyName) {
              localStorage.setItem('name', result.data.data.CompanyName);
              window.location.reload();
            }

          } else toast.error(result.data.message);
        })
        .catch((error) => {
          //console.log(error);
        });

        //Invoices---------------------------------------------------
        await axiosConfig
        .get(`/Client/Invoices/GetAllInvoices?page=1&limit=5`)
        .then((result) => {
          if (result.data.status === "success") {
            setInvoices(result.data.data.Invoices);
          } else {
            if(result.data.message !== "")toast.error(result.data.message);
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  //-----------------------------------------
};

export default Dashboard;
