import { useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import FormInput from "../../htmlForm/formInput";
import { PublicConsts } from "../../../publicConsts";

const GetEcoCodeInfo = () => {
  const [response, setResponse] = useState("");
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>دریافت اطلاعات کد اقتصادی</h5>
        </div>
        <div className="card-body">
          <FormInput label="کد اقتصادی" name="economicCoce" />
          <button className="btn btn-sm btn-success" onClick={getData}>
          <i className="bi bi-search mx-1"></i>
            دریافت اطلاعات <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
          </button>
          <hr />
          <div>
            <div className="text-primary my-3">نتیجه درخواست: </div>
            <div id="resultContainer" className="d-none">
              <div className="mb-1">
                <span>نام تجاری: </span>
                <span className="fw-bold">{response.NameTrade}</span>
              </div>
              <div className="mb-1">
                <span>آدرس: </span>
                <span className="fw-bold">{response.AddressTaxpayer}</span>
              </div>
              <div className="mb-1">
                <span>کد پستی: </span>
                <span className="fw-bold">{response.PostalcodeTaxpayer}</span>
              </div>
              <div className="mb-1">
                <span>نوع: </span>
                <span className="fw-bold">
                  {response.TaxpayerType === "LEGAL" ? (
                    <span>حقوقی</span>
                  ) : response.TaxpayerType === "ILLEGAL" ? (
                    <span>حقیقی</span>
                  ) : (
                    ""
                  )}
                </span>
              </div>

              <div className="mb-1">
                <span>وضعیت: </span>
                <span className="fw-bold">
                  {response.TaxpayerStatus === "ENABLED" ? (
                    <span className="text-success">فعال</span>
                  ) : response.TaxpayerStatus === "DISABLED" ? (
                    <span className="text-danger">غیرفعال</span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  //-----------------------------------------
  async function getData(e) {
    const resultContainer = document.getElementById("resultContainer");
    const spinner = document.getElementsByClassName("fa-spinner");
    try {
      const economicCoce = document.getElementById("economicCoce").value;
      if (economicCoce === null || economicCoce === "")
        return toast.error(PublicConsts.Validation_EnterEconomicCode);

      e.target.classList.add("disabled");
      spinner[0].classList.remove("d-none");

      await axiosConfig
        .get(`/Client/Tax/GetEconomicCodeInformation?economicCode=${economicCoce}`)
        .then((result) => {
          if (result.data.status === "success") {
            setResponse(result.data.data);
            toast.success(result.data.message);
            resultContainer.classList.remove("d-none");
          } else {
            toast.error(result.data.message);
            resultContainer.classList.add("d-none");
          }
        })
        .catch((error) => {});
    } catch (error) {
      resultContainer.classList.add("d-none");
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }

    e.target.classList.remove("disabled");
    spinner[0].classList.add("d-none");
  }
  //-----------------------------------------
};

export default GetEcoCodeInfo;
