import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";
import axiosConfig from "../../../axiosConfig";
import FormInput from "../../htmlForm/formInput";


const Discounts = () => {

  const [discounts, setDiscounts] = useState([]);
  const [validationErrors, setvalidationErrors] = useState([]);
  const [agentList, setAgentList] = useState([]);
  // for Update------------------------------------------------------------
  const [discountUpdateDetails, setDiscountUpdateDetails] = useState([]);

  useEffect(() => {
    getDiscounts();
  }, []);

  return (
    <>
    <div className="card shadow-lg table-responsive mb-3">
      <div className="card-header">
        <span className="h5">لیست تخفیف های فروش</span>
        <button
          type="button"
          className="btn btn-primary float-end"
          data-bs-toggle="modal"
          data-bs-target="#discountModal"
          onClick={() => {
            setDiscountUpdateDetails([]);
          }}
        >
          <i className="fa fa-plus mx-1"></i>کد تخفیف جدید
        </button>
      </div>
      <div className="card-body">
        <div id="loadingContainer" className="alert alert-danger d-none">
          در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
        </div>
        <table className="table table-striped text-center">
          <thead>
            <tr>
              <th>نماینده فروش</th>
              <th>کد تخفیف</th>
              <th>درصد تخفیف</th>
              <th>تاریخ ایجاد</th>
              <th>تاریخ اکسپایری</th>
              <th>تعداد استفاده</th>
              <th>عملیات</th>
            </tr>
          </thead>
          <tbody>
            {discounts.length !== 0 ? (
              <>
                {discounts.map((discount) => (
                  <tr key={discount.Id}>
                    <td>{discount.SaleAgent}</td>
                    <td>{discount.Code}</td>
                    <td>{discount.DiscountPercent}</td>
                    <td>{discount.CreateDate}</td>
                    <td>{discount.ExpireDate}</td>
                    <td>{discount.UsedCount}</td>
                    <td>
                    <button
                        type="button"
                        className="btn btn-sm btn-danger text-white mx-1 mb-1"
                        title="حذف"
                        onClick={() => {deleteDiscount(discount.Id);}}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                    {/* <button
                        type="button"
                        className="btn btn-sm btn-warning text-white mx-1 mb-1"
                        title="ویرایش"
                        onClick={() => {
                          updateDiscountModal(discount.Id);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#discountModal"
                      >
                        <i className="fa fa-edit"></i>
                      </button> */}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="12">
                  <div className="alert alert-danger">موردی یافت نشد</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>

    {/* Modal------------------------------------------------------------------- */}
    <div className="modal fade" id="discountModal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                افزودن کد تخفیف
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#discountModal"
              ></button>
            </div>
            <div className="modal-body">
              {validationErrors.length > 0 ? (
                <div className="alert alert-danger">
                  {validationErrors.map((item) => (
                    <ul>
                      <li>{item}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                ""
              )}
              <form id="formData">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                  <div className="col">
                    <label className="mb-2">اختصاص کد تخفیف به نماینده (یا مدیر سامانه)</label>
                    <select
                      className="form-control border-success mb-3 fw-bold text-success"
                      id="agentList"
                      name="SaleAgentId"
                    >
                      <option value="">- انتخاب نماینده فروش</option>
                      {agentList.map((agent) => (
                        <option key={agent.Id} value={agent.Id}>
                          {agent.Name + " " + agent.Family}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col">
                    <FormInput
                      label="کد تخفیف"
                      name="Code"
                      defValue={discountUpdateDetails.Code}
                      required
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="درصد تخفیف"
                      name="DiscountPercent"
                      defValue={discountUpdateDetails.DiscountPercent}
                      required
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="تاریخ انقضا"
                      name="ExpireDate"
                      defValue={discountUpdateDetails.ExpireDate}
                      required
                    />
                  </div>
                </div>

                {discountUpdateDetails.Id ? (
                  <>
                    <input type="hidden" name="Id" value={discountUpdateDetails.Id} />
                    {/* <button
                      type="button"
                      className="btn btn-success mx-1 mb-1"
                      onClick={updateDiscount}
                    >
                      <i className="fa fa-edit mx-1"></i>
                      ویرایش <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button> */}
                    <button
                      type="button"
                      className="btn btn-danger mx-1 mb-1"
                      onClick={() => deleteDiscount(discountUpdateDetails.Id)}
                    >
                      <i className="fa fa-trash mx-1"></i>
                      حذف <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button>
                  </>
                ) : (
                  <>
                    <input type="hidden" name="userId" value={0} />
                    <button
                      type="button"
                      className="btn btn-success mx-1 mb-1"
                      onClick={createDiscount}
                    >
                      <i className="fa fa-send mx-1"></i>
                      ثبت <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>  );
  //-------------------------------------------------------
  async function getDiscounts() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/AdminPanel/Discounts/GetDiscounts/`)
        .then((result) => {
          if (result.data.status === "success") 
          {
            setDiscounts(result.data.data);
            getAgentList();
          }
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch(error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function createDiscount(e) {
    const spinner = document.getElementsByClassName("fa-spinner")[0];

    try {
      e.target.classList.add("disabled");
      spinner.classList.remove("d-none");
      //-----------------------------------
      const form = document.getElementById("formData");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      await axiosConfig
        .post(`/AdminPanel/Discounts/CreateDiscount/`, formDetails)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            getDiscounts();
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          const errorsMessages = [];

          if (error.response.data.title === "One or more validation errors occurred.") {
            toast.error(PublicConsts.Validation_CompleteAllRequirements);

            //Convert Errors(json) to Array
            Object.keys(errors).forEach((key) => errorsMessages.push(errors[key][0]));

            setvalidationErrors(errorsMessages);
          }
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  async function updateDiscountModal(id) {

    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/AdminPanel/Discounts/GetDiscount/${id}`)
        .then((result) => {
          if (result.data.status === "success"){
            setDiscountUpdateDetails(result.data.data);
            document.getElementById("agentList").setAttribute("disabled","disabled")
          } 
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function updateDiscount() {
    try {
      const form = document.getElementById("formData");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      let result = await axiosConfig.put(`/AdminPanel/Discounts/UpdateDiscount`, formDetails);
      if (result.data.status === "success") {
        toast.success(result.data.message);
        // setInvoiceProductList([]);
        // form.reset();
      } else toast.error(result.data.message);
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
  async function getAgentList(){
    try {
      await axiosConfig
        .get(`/AdminPanel/SaleAgents/GetAgents`)
        .then((result) => {
          if (result.data.status === "success"){
            setAgentList(result.data.data);
          } 
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
  async function deleteDiscount(id) {
var confirm = window.confirm(PublicConsts.Confirm_DeleteDiscountCode);
if(confirm)
{
  const loadingContainer = document.getElementById("loadingContainer");
  try {
    loadingContainer.classList.remove("d-none");
    await axiosConfig
      .get(`/AdminPanel/Discounts/DeleteDiscount/${id}`)
      .then((result) => {
        if (result.data.status === "success"){
          toast.success(result.data.message)
          getDiscounts();
        } 
        else toast.error(result.data.message);
      })
      .catch((error) => {});
  } catch {
    toast.error(PublicConsts.TryCatch_ErrorMessage);
  }
  loadingContainer.classList.add("d-none");

}
    
  }
  };
  export default Discounts;
  