import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";
import "../../../assets/css/invoice-details.css";


const InvoiceDetails = () => {
  const [invoiceDetails, setInvoiceDetails] = useState("");
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <>
      <div className="alert alert-info mb-3">
        <i className="fa fa-info-circle mx-1"></i>مشخصات صورت حساب شماره
        <b className="text-danger mx-1">{invoiceDetails.Id}</b>
        <span className="no-print d-flex d-md-inline mt-2">
        <a href="/Client/InvoiceList" className="btn btn-sm btn-primary float-end">
          بازگشت
        </a>
        <a href={"/Client/InvoicePrint/" + invoiceDetails.Id} className="btn btn-sm btn-info float-end mx-1">
        <i className="fa fa-print mx-1"></i>
          پرینت
        </a>
        </span>
        
      </div>
      <div id="loadingContainer" className="alert alert-danger d-none">
          در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
        </div>
      {invoiceDetails !== "" ? (
        <div>
          {/* Tracking Codes--------------------------------------------------------- */}
          <div className="card shadow-lg mb-3">
            <div className="card-header">
              <h5>کد پیگیری</h5>
            </div>
            <div className="card-body">
              <div className="row row-cols-1 row-cols-sm-2 mb-4">
                <div className="col my-2">
                  <span className="mb-2 mx-1">سریال صورتحساب:</span>
                  <strong id="refNumber">{invoiceDetails.InvoiceSerial}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">شماره مالیاتی صورتحساب:</span>
                  <strong id="refNumber">{invoiceDetails.TaxId}</strong>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-sm-2 mb-4">
                <div className="col my-2">
                  <span className="mb-2 mx-1">شماره رفرنس:</span>
                  <strong id="refNumber">{invoiceDetails.ReferenceNumber}</strong>
                    <i className="btn fa fa-copy text-danger mx-1" title="کپی" onClick={() => copyRefNumber()}></i>
                </div>

                <div className="col my-2">
                  <bdi className="mb-2 mx-1">کد Uid:</bdi>
                  <strong id="uidNumber">{invoiceDetails.Uid}</strong>
                    <i className="btn fa fa-copy text-danger mx-1" title="کپی" onClick={() => copyUidNumber()}></i>
                </div>
              </div>
            </div>
          </div>

          {/* Invoice Types--------------------------------------------------------- */}
          <div className="card shadow-lg mb-3">
            <div className="card-header">
              <h5>نوع صورتحساب</h5>
            </div>
            <div className="card-body">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 mb-4">
                <div className="col my-2">
                  <span className="mb-2 mx-1">نوع صورتحساب:</span>
                  <strong>{invoiceDetails.InvoiceType}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">موضوع صورتحساب:</span>
                  <strong>{invoiceDetails.InvoiceSubject}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">الگوی صورتحساب:</span>
                  <strong>{invoiceDetails.InvoicePattern}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">نوع طرف حساب:</span>
                  <strong>{invoiceDetails.BuyerType}</strong>
                </div>

                <div className="col my-2">
                  <span className="mb-2 mx-1">ماهیت صورت حساب:</span>
                  <strong>{invoiceDetails.SellingType}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">روش پرداخت:</span>
                  <strong>{invoiceDetails.SettlementMethod}</strong>
                </div>
              </div>
            </div>
          </div>

          {/* Invoice Details--------------------------------------------------------- */}
          <div className="card shadow-lg mb-3">
            <div className="card-header">
              <h5>جزییات صورتحساب</h5>
            </div>
            <div className="card-body">
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
                <div className="col my-2">
                  <span className="mb-2 mx-1">نام:</span>
                  <strong>{invoiceDetails.Fullname}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">کد ملی:</span>
                  <strong>{invoiceDetails.NationalCode}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">کد پستی:</span>
                  <strong>{invoiceDetails.PostCode}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">استان:</span>
                  <strong>{invoiceDetails.Province}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">شهر:</span>
                  <strong>{invoiceDetails.City}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">شماره تماس:</span>
                  <strong>{invoiceDetails.PhoneNumber}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">شماره فاکتور:</span>
                  <strong>{invoiceDetails.InvoiceNumber}</strong>
                </div>
                <div className="col my-2">
                  <span className="mb-2 mx-1">تاریخ صدور:</span>
                  <strong>{invoiceDetails.IssueDate}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12 my-2">
                  <span className="mb-2 mx-1">آدرس:</span>
                  <strong>{invoiceDetails.Address}</strong>
                </div>
              </div>
            </div>
          </div>
          {/* Invoice Product list--------------------------------------------------------- */}
          <div className="card shadow-lg mb-3">
            <div className="card-header">
              <span className="h5">لیست کالا و خدمات</span>
            </div>
            <div className="card-body table-responsive">
              <table className="table table-striped text-center">
                <thead>
                  <tr>
                    <th>ردیف</th>
                    <th>شناسه کالا</th>
                    <th>نام کالا</th>
                    <th>شرح کالا</th>
                    <th>کد واحد</th>
                    <th>تعداد/مقدار</th>
                    <th>مبلغ واحد</th>
                    <th>مبلغ قبل از تخفیف</th>
                    <th>مبلغ تخفیف</th>
                    <th>مبلغ بعد از تخفیف</th>
                    <th>نرخ مالیات بر ارزش افزوده</th>
                    <th>مبلغ ارزش افزوده</th>
                    <th>مبلغ کل</th>
                  </tr>
                </thead>
                {invoiceDetails.InvoiceProducts.length > 0 ? (
                  <>
                    <tbody>
                      {invoiceDetails.InvoiceProducts.map((product, index) => (
                        <tr key={product.Id}>
                          <td>{index + 1}</td>
                          <td>{product.ProductId}</td>
                          <td>{product.ProductName}</td>
                          <td>{product.Description}</td>
                          <td>{product.UnitCode}</td>
                          <td>{product.Quantity}</td>
                          <td>{product.Price.toLocaleString()}</td>
                          <td>{product.PriceBeforeDiscount.toLocaleString()}</td>
                          <td>{product.Discount.toLocaleString()}</td>
                          <td>{product.PriceAfterDiscount.toLocaleString()}</td>
                          <td>{product.Vat}%</td>
                          <td>{product.VatPrice.toLocaleString()}</td>
                          <td>{product.FinalPrice.toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>

                    <tfoot className="alert alert-primary">
                      <tr>
                        <th>{invoiceDetails.InvoiceProducts.length}</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>-</th>
                        <th>{invoiceDetails.InvoiceTotals.QuantitySum.toLocaleString()}</th>
                        <th>{invoiceDetails.InvoiceTotals.PriceSum.toLocaleString()}</th>
                        <th>{invoiceDetails.InvoiceTotals.PriceBeforeDiscountSum.toLocaleString()}</th>
                        <th>{invoiceDetails.InvoiceTotals.DiscountSum.toLocaleString()}</th>
                        <th>{invoiceDetails.InvoiceTotals.PriceAfterDiscountSum.toLocaleString()}</th>
                        <th>-</th>
                        <th>{invoiceDetails.InvoiceTotals.VatPriceSum.toLocaleString()}</th>
                        <th>{invoiceDetails.InvoiceTotals.FinalPriceSum.toLocaleString()}</th>
                      </tr>
                    </tfoot>
                  </>
                ) : (
                  ""
                )}
              </table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
  //------------------------------------------------------------------
  async function getDetails() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      const invoiceId = window.location.pathname.split("/").pop();
      await axiosConfig
        .get(`/Client/Invoices/GetInvoice/${invoiceId}`)
        .then((result) => {
        if (result.data.status === "success") {
            setInvoiceDetails(result.data.data)
        } else {
            toast.error(result.data.message);
        }
        })
        .catch((error) => {});

    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  function copyRefNumber() {
    const refNumber = document.getElementById("refNumber").innerHTML;
    navigator.clipboard.writeText(refNumber);
    toast.success(PublicConsts.Info_CopyReferenceNumber);
  }
  function copyUidNumber() {
    const uidNumber = document.getElementById("uidNumber").innerHTML;
    navigator.clipboard.writeText(uidNumber);
    toast.success(PublicConsts.Info_CopyUidNumber);
  }
};

export default InvoiceDetails;
