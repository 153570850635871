import { useEffect, useState } from "react";
import FormInput from "../../htmlForm/formInput";
import FormTextArea from "../../htmlForm/formTextArea";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const UserSettings = () => {
  const [setting, setSetting] = useState([]);
  const [names, setNames] = useState([]);
  useEffect(() => {
    getSetting();
  }, []);
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h5>تنظیمات</h5>
        </div>
        <div className="card-body">
          <div id="loadingContainer" className="alert alert-danger d-none">
            در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
          </div>
          <form id="namesForm">
            <FormInput
              label="نام و نام خانوادگی"
              name="FullName"
              defValue={names.FullName}
              required={true}
            />
            <FormInput
              label="نام شرکت"
              name="CompanyName"
              defValue={names.CompanyName}
              required={true}
            />
          </form>
          <form id="settingForm">
            <FormInput
              label="شماره ملی/ شناسه ملی"
              name="EconomicCode"
              defValue={setting.EconomicCode}
              required={true}
            />
            <FormInput
              label="شناسه یکتا"
              name="ClientId"
              defValue={setting.ClientId}
              required={true}
            />
            <FormTextArea
              label="کلید خصوصی (به دلیل امنیت، کلید خصوصی نمایش داده نمی شود)"
              name="Privatekey"
              defValue={setting.PrivateKey}
              required={true}
              placeHolder="بدون -----BEGIN PRIVATE KEY----- و -----END PRIVATE KEY----- وارد شود"
            />
            <FormInput type="hidden" name="UserId" defValue="0" />
            <FormInput type="hidden" name="Id" defValue="0" />
            <button type="button" className="btn btn-success text-white" onClick={UpdateSetting}>
            <i className="fa fa-save mx-1"></i>
              ثبت تغییرات <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
  //-------------------------------------------------------------------------
  async function getSetting() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/Client/Settings/GetUserSetting`)
        .then((result) => {
          if (result.data.status === "success") {
            setSetting(result.data.data.DefaultSettings);
            setNames(result.data.data.NameProperties);
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          //console.log(error);
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function UpdateSetting(e) {
    const spinner = document.getElementsByClassName("fa-spinner")[0];
    try {
      e.target.classList.add("disabled");
      spinner.classList.remove("d-none");
      const settingForm = document.getElementById("settingForm");
      const settingFormData = new FormData(settingForm);
      const settingFormDetails = Object.fromEntries(settingFormData.entries());
      const namesForm = document.getElementById("namesForm");
      const namesFormData = new FormData(namesForm);
      const namesFormDetails = Object.fromEntries(namesFormData.entries());
      //-----------------------------------
      const allSetting = {
        DefaultSettings: settingFormDetails,
        NameProperties: namesFormDetails,
      };
      //-----------------------------------
      let result = await axiosConfig
        .put(`/Client/Settings/UpdateUserSetting`, allSetting)
        .catch((error) => {
          //console.log(error);
        });
      if (result.data.status === "success") {
        toast.success(result.data.message);
        // setInvoiceProductList([]);
        // form.reset();
      } else toast.error(result.data.message);
    } catch (err) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    e.target.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  //-------------------------------------------------------------------------
};

export default UserSettings;
