import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import axiosConfig from "../../axiosConfig";
import FormInput from "../htmlForm/formInput";
import { toast } from "react-toastify";
//import "../../assets/css/dashboard.rtl.css";

const UserPanelLayout = ({ mainContent }) => {
  useEffect(() => {
    autoCloseSidebarOnMobile();
  }, []);
  return (
    <>
      <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow text-center">
        <span className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6">
          پنل مدیریت
        </span>
        <button
          className="navbar-toggler position-absolute d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* <input
          className="form-control form-control-dark w-100 rounded-0 border-0"
          type="text"
          placeholder="جستجو"
        /> */}
        <span
          className="nav-link px-3 d-none d-md-block btn"
          onClick={logout}
          title="خروج"
        >
          <i className="fa fa-flip-horizontal fa-sign-out text-danger" />
        </span>
      </header>

      <div className="container-fluid h-100 p-0">
        <div className="row h-100">
          <nav
            id="sidebarMenu"
            className="col-8 col-md-3 col-lg-2 d-md-block sidebar collapse bg-white top-0"
          >
            <div className="position-sticky pt-3 sidebar-sticky">
              <ul className="nav flex-column p-0 pe-2">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/AdminPanel/Dashboard">
                    <i className="fa fa-dashboard px-1"></i>
                    <span>داشبورد</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/AdminPanel/InvoiceList">
                    <i className="fa fa-list px-1"></i>
                    <span>صورتحساب ها</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/AdminPanel/SentInvoiceReport"
                  >
                    <i className="fa fa-list px-1"></i>
                    <span>گزارش ارسال</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/AdminPanel/InquiryByRfn">
                    <i className="fa fa-info-circle px-1"></i>
                    <span>استعلام با شماره رفرنس</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/AdminPanel/Discounts">
                    <i className="fa fa-tags px-1"></i>
                    <span>تخفیف ها</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/AdminPanel/SaleAgents">
                    <i className="fa fa-user-secret px-1"></i>
                    <span>نماینده های فروش</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/AdminPanel/PricePlans">
                    <i className="fa fa-list-ol px-1"></i>
                    <span>تعرفه های فروش</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/AdminPanel/UserList">
                    <i className="fa fa-users px-1"></i>
                    <span>کاربرها</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/AdminPanel/Products">
                    <i className="fa fa-cubes px-1"></i>
                    <span>کالا و خدمات</span>
                  </NavLink>
                </li>
                {/* ------------------------------ */}
                <li className="nav-item">
                  <button
                    className="btn btn-toggle align-items-center rounded"
                    data-bs-toggle="collapse"
                    data-bs-target="#support-collapse"
                  >
                    <i className="fa fa-gears px-1"></i>
                    پشتیبانی
                  </button>
                  <i className="float-end fa fa-angle-left"></i>
                  <div className="collapse" id="support-collapse">
                    <ul className="btn-toggle-nav rounded bg-light">
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/AdminPanel/Tickets">
                          <i className="fa fa-user px-1"></i>
                          <span>تیکت</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                {/* ------------------------------ */}

                {/* ------------------------------ */}
                <li className="nav-item">
                  <button
                    className="btn btn-toggle align-items-center rounded"
                    data-bs-toggle="collapse"
                    data-bs-target="#setting-collapse"
                  >
                    <i className="fa fa-gears px-1"></i>
                    تنظیمات
                  </button>
                  <i className="float-end fa fa-angle-left"></i>
                  <div className="collapse" id="setting-collapse">
                    <ul className="btn-toggle-nav rounded bg-light">
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/AdminPanel/Settings">
                          <i className="fa fa-user px-1"></i>
                          <span>اطلاعات کاربری</span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/ChangePassword"
                          data-bs-toggle="modal"
                          data-bs-target="#changePasswordModal"
                        >
                          <i className="fa fa-lock px-1" />
                          تغییر رمز عبور
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                {/* ------------------------------ */}

                <li className="nav-item">
                        <a className="nav-link text-danger" href="/logout" onClick={logout}>
                            <i className="bi bi-box-arrow-left text-danger"></i> خروج
                        </a>
                    </li>
              </ul>
            </div>
          </nav>

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 pt-4 h-100 bg-light">
            {mainContent}
          </main>
        </div>
      </div>
      <footer className="text-center mt-5 p-3" style={{background: "#ded9d966"}}>Copyright 2023</footer>
      {/* Modal------------------------------------------------------------------- */}
      <div className="modal fade" id="changePasswordModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">تغییر پسورد</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#changePasswordModal"
              ></button>
            </div>
            <div className="modal-body">
              <form>
              <FormInput
                id="username"
                name="username"
                placeHolder="شماره موبایل را وارد کنید"
              />
              <FormInput
                type="password"
                id="currentPassword"
                name="currentPassword"
                autoComplete="off"
                placeHolder="رمز عبور فعلی را وارد کنید"
              />
              <FormInput
                type="password"
                id="newPassword"
                name="newPassword"
                autoComplete="off"
                placeHolder="رمز عبور جدید را وارد کنید"
              />
              <button
                id="changePasswordButton"
                type="button"
                className="btn btn-success"
                onClick={() => changePassword()}
              >
                تغییر رمز عبور{" "}
                <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
              </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  //----------------------------------------------------------------------
  function logout() {
    localStorage.removeItem("token");
    window.location = "/";
  }
  async function changePassword() {
    const spinner = document.getElementsByClassName("fa-spinner")[0];
    const button = document.getElementById("changePasswordButton");
    try {
      button.classList.add("disabled");
      spinner.classList.remove("d-none");

      const username = document.getElementById("username").value;
      const curPass = document.getElementById("currentPassword").value;
      const newPass = document.getElementById("newPassword").value;

      // const passwordDetails = {
      //   username: username,
      //   currentPassword: curPass,
      //   newPassword: newPass,
      // };

      await axiosConfig
        .post(
          `/Account/ChangePassword?username=${username}&currentPassword=${curPass}&newPassword=${newPass}`
        )
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          toast.error("رخداد خطا");
        });
    } catch (error) {
      if (error.message === "Network Error")
        toast.error("خطا در ارتباط با سرور");
      else toast.error("رخداد خطا");
    }

    button.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  function autoCloseSidebarOnMobile() {
    const screenWidth = window.screen.width;
    const menuItems = document.getElementsByClassName("nav-item");
    if (screenWidth < 768) {
      for (let item of menuItems) {
        item.setAttribute("data-bs-toggle", "collapse");
        item.setAttribute("data-bs-target", "#sidebarMenu");
      }
    }
  }
  //----------------------------------------------------------------------
};

export default UserPanelLayout;
