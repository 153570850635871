const NotFound = () => {
  return (
    <div>
      <div className="card mt-5">
        <div className="card-header bg-danger text-white">
          <h5>صفحه مورد نظر یافت نشد.</h5>
        </div>
        <div className="card-body">آدرس وارد شده را مجدد بررسی کنید.</div>
      </div>
    </div>
  );
};

export default NotFound;
