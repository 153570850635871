import { useEffect, useState } from "react";
import FormInput from "../../htmlForm/formInput";
import FormRadio from "../../htmlForm/formRadio";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const CreateInvoice = () => {
  const [invoiceProductList, setInvoiceProductList] = useState([]);
  const [searchedProductList, setSearchedProductList] = useState([]);
  const [invoiceTotals, setInvoiceTotals] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [productUnits, setProductUnits] = useState([]);
  const [validationErrors, setvalidationErrors] = useState([]);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(1)

  // for Update------------------------------------------------------------
  const [InvoiceUpdateDetails, setInvoiceUpdateDetails] = useState([]);
  const [ProductToUpdate, setProductToUpdate] = useState(null);
  let IsUpdateMode = false;
  if(InvoiceUpdateDetails.length !== 0)
  IsUpdateMode = true;
  //----------------------------------------------------------------------

  useEffect(() => {
    loadDataToUpdateInvoice();
    getCustomers();
    GetProductUnits();
  }, []);

  useEffect(() => {
    sumOfInvoiceProducts();
  }, [invoiceProductList]);

  useEffect(() => {
    handleRadioButtons();
  }, [InvoiceUpdateDetails]);

  return (
    <div className="pb-5">
      <div id="loadingContainer" className="alert alert-danger mb-3 d-none">
        در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
      </div>
      {validationErrors.length > 0 ? (
        <div className="alert alert-danger mb-2">
          <ul>
          {validationErrors.map((item, index) => (
              <li key={index}>{item}</li>
          ))}
          </ul>
        </div>
      ) : (
        ""
      )}
      <form
        id="formData"
        onSubmit={(e) => {
          return e.preventDefault();
        }}
      >
        {/* Invoice Types--------------------------------------------------------- */}
        <div className="card shadow-lg mb-3">
          <div className="card-header">
            <h5>مشخصات صورتحساب</h5>
          </div>
          <div className="card-body">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 mb-4">
              <div className="col mb-3">
                <div className="mb-2 fw-bold">موضوع صورتحساب:</div>
                <span className="radio-group-container">
                  {(InvoiceUpdateDetails.ReferenceNumber? 
                  <>
                  <label className="first disabled-custom">اصلی</label>
                  <FormRadio lableClassName="last" label="اصلاحی" id="edit" name="InvoiceSubject" value="2"  defChecked={true} required/>
                  {/* <FormRadio label="ابطالی" id="cancel" name="InvoiceSubject" value="3" required/>
                  <FormRadio label="بازگشتی" id="return" name="InvoiceSubject" value="4" required  lableClassName="last"/> */}
                  </>
                  :
                  <>
                  <FormRadio label="اصلی" id="new" name="InvoiceSubject" value="1" required  lableClassName="first" defChecked={true}/>
                  <label className="last disabled-custom">اصلاحی</label>
                  {/* <label className="disabled-custom">ابطالی</label>
                  <label className="last disabled-custom">بازگشتی</label> */}
                  </>
                  )}
                  
                </span>
              </div>
              <div className="col mb-3">
                <div className="mb-2 fw-bold">الگوی صورت حساب:</div>
                <span className="radio-group-container">
                  <FormRadio
                    label="فروش"
                    lableClassName="first"
                    id="patternSale"
                    name="InvoicePattern"
                    value="1"
                    required
                    defChecked={true}
                  />
                  <label className="last disabled-custom">فروش ارزی</label>
                </span>
              </div>

              <div className="col mb-3">
                <div className="mb-2 fw-bold">ماهیت صورت حساب:</div>
                <span className="radio-group-container">
                  <FormRadio
                    label="فروش"
                    lableClassName="first"
                    id="tapeSale"
                    name="SellingType"
                    value="1"
                    required
                    defChecked={true}
                  />
                  <label className="last disabled-custom">خرید</label>
                  {/* <FormRadio
                    label="خرید"
                    lableClassName="last"
                    id="buy"
                    name="SellingType"
                    value="2"
                    required
                  /> */}
                </span>
              </div>

              <div className="col mb-3">
                <div className="mb-2 fw-bold">نوع صورتحساب:</div>
                <span className="radio-group-container">
                  <FormRadio
                    label="نوع اول"
                    lableClassName="first"
                    id="firstMethod"
                    name="InvoiceType"
                    value="1"
                    required
                    onChange={() => setSelectedInvoiceType(1)}
                  />
                  <FormRadio
                    label="نوع دوم"
                    lableClassName="last"
                    id="secondMethod"
                    name="InvoiceType"
                    value="2"
                    required
                    onChange={() => setSelectedInvoiceType(2)}
                  />
                </span>
              </div>

              <div className="col mb-3">
                <div className="mb-2 fw-bold">نوع طرف حساب:</div>
                <span className="radio-group-container">
                  <FormRadio
                    label="حقیقی"
                    lableClassName="first"
                    id="illegal"
                    name="BuyerType"
                    value="1"
                    required
                  />
                  <FormRadio
                    label="حقوقی"
                    lableClassName="last"
                    id="legal"
                    name="BuyerType"
                    value="2"
                    required
                  />
                </span>
              </div>

              <div className="col mb-3">
                <div className="mb-2 fw-bold">روش تسویه:</div>
                <span className="radio-group-container">
                  <FormRadio
                    label="نقد"
                    lableClassName="first"
                    id="cash"
                    name="SettlementMethod"
                    value="1"
                    required
                  />
                  <FormRadio label="نسیه" id="credit" name="SettlementMethod" value="2" required />
                  <FormRadio
                    label="نقد/نسیه"
                    lableClassName="last"
                    id="cashCredit"
                    name="SettlementMethod"
                    value="3"
                    required
                  />
                </span>
              </div>

              
            </div>
          </div>
        </div>

        {/* Invoice Details--------------------------------------------------------- */}
        <div className="card shadow-lg mb-3">
          <div className="card-header">
            <h5>مشخصات طرف حساب</h5>
          </div>
          {selectedInvoiceType === 1?(
            <div className="card-body">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
              <select
                className="form-control border-primary mb-3 fw-bold text-primary"
                id="customerList"
                onChange={() => getCustomerDetails()}
              >
                <option value="">- انتخاب طرف حساب</option>
                {customerList.map((customer) => (
                  <option key={customer.Id} value={customer.Id}>
                    {customer.Fullname}
                  </option>
                ))}
              </select>
              <div className="col">
                <FormInput
                  label="نام کامل/ نام شرکت"
                  name="Fullname"
                  defValue={InvoiceUpdateDetails.Fullname}
                  required
                />
              </div>
              <div className="col">
                <FormInput
                  label="شماره ملی/ شناسه ملی"
                  name="NationalCode"
                  defValue={InvoiceUpdateDetails.NationalCode}
                  required
                />
              </div>
              <div className="col">
                <FormInput
                  label="کد پستی"
                  name="PostCode"
                  defValue={InvoiceUpdateDetails.PostCode}
                  required
                />
              </div>
              <div className="col">
                <FormInput
                  label="استان"
                  name="Province"
                  defValue={InvoiceUpdateDetails.Province}
                  required
                />
              </div>
              <div className="col">
                <FormInput label="شهر" name="City" defValue={InvoiceUpdateDetails.City} required />
              </div>
              <div className="col">
                <FormInput
                  label="شماره تماس"
                  name="PhoneNumber"
                  defValue={InvoiceUpdateDetails.PhoneNumber}
                  required
                />
              </div>
              <div className="col">
                <div className="mb-3">
                    <label htmlFor="InvoiceNumber" className="form-label">شماره فاکتور</label>
                    <i className="fa fa-info-circle mx-1 text-danger" onClick={()=>toggleTooltip("invoiceNumberTooltip")}></i>
                    <div id="invoiceNumberTooltip" className="alert alert-danger position-absolute d-none"></div>
                    <input type="number" id="InvoiceNumber" name="InvoiceNumber" className="form-control" placeholder="مثال: 2502" defaultValue={InvoiceUpdateDetails.InvoiceNumber} />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="IssueDate" className="form-label">تاریخ صدور</label>
                  <i className="fa fa-info-circle mx-1 text-danger" onClick={()=>toggleTooltip("issueDateTooltip")}></i>
                  <div id="issueDateTooltip" className="alert alert-danger position-absolute d-none"></div>
                  <input id="IssueDate" name="IssueDate" placeholder="مثال: 1402/02/20" className="form-control" defaultValue={InvoiceUpdateDetails.IssueDate} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <FormInput
                  label="آدرس"
                  name="Address"
                  defValue={InvoiceUpdateDetails.Address}
                  required
                />
              </div>
            </div>
          </div>)
          : 
          selectedInvoiceType === 2? (
            <div className="card-body">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
              <div className="col">
                <div className="mb-3">
                    <label htmlFor="InvoiceNumber" className="form-label">شماره فاکتور</label>
                    <i className="fa fa-info-circle mx-1 text-danger" onClick={()=>toggleTooltip("invoiceNumberTooltip")}></i>
                    <div id="invoiceNumberTooltip" className="alert alert-danger position-absolute d-none"></div>
                    <input type="number" id="InvoiceNumber" name="InvoiceNumber" className="form-control" placeholder="مثال: 2502" defaultValue={InvoiceUpdateDetails.InvoiceNumber} />
                </div>
              </div>
              <div className="col">
                <div className="mb-3">
                  <label htmlFor="IssueDate" className="form-label">تاریخ صدور</label>
                  <i className="fa fa-info-circle mx-1 text-danger" onClick={()=>toggleTooltip("issueDateTooltip")}></i>
                  <div id="issueDateTooltip" className="alert alert-danger position-absolute d-none"></div>
                  <input id="IssueDate" name="IssueDate" placeholder="مثال: 1402/02/20" className="form-control" defaultValue={InvoiceUpdateDetails.IssueDate} />
                </div>
              </div>
            </div>
          </div>):""}
          
        </div>

        {/* Invoice Product list--------------------------------------------------------- */}
        <div className="card shadow-lg mb-3">
          <div className="card-header">
            <span className="h5">لیست کالا و خدمات</span>
            <button
              type="button"
              className="btn btn-primary float-end"
              data-bs-toggle="modal"
              data-bs-target="#addProductModal"
            >
              <i className="fa fa-plus mx-1"></i>افزودن کالا
            </button>
          </div>
          <div className="card-body">
            <div className="table-responsive">
            <table className="table table-striped text-center">
              <thead>
                <tr>
                  <th>ردیف</th>
                  <th>شناسه کالا</th>
                  <th>نام کالا</th>
                  <th>شرح کالا</th>
                  <th>واحد</th>
                  <th>تعداد/مقدار</th>
                  <th>مبلغ واحد</th>
                  <th>مبلغ قبل از تخفیف</th>
                  <th>مبلغ تخفیف</th>
                  <th>مبلغ بعد از تخفیف</th>
                  <th>نرخ مالیات بر ارزش افزوده</th>
                  <th>مبلغ ارزش افزوده</th>
                  <th>مبلغ کل</th>
                  <th>عملیات</th>
                </tr>
              </thead>
              <tbody>
                {/* Get invoice Products in Update Mode */}
                {InvoiceUpdateDetails.length !== 0 ? (
                  <>
                    {InvoiceUpdateDetails.InvoiceProducts.map((product, index) => (
                      <tr key={product.Id}>
                        <td>{index + 1}</td>
                        <td>{product.ProductId}</td>
                        <td>{product.ProductName}</td>
                        <td>{product.Description}</td>
                        <td>{product.UnitName}</td>
                        <td>{product.Quantity}</td>
                        <td>{product.Price.toLocaleString()}</td>
                        <td>{product.PriceBeforeDiscount.toLocaleString()}</td>
                        <td>{product.Discount.toLocaleString()}</td>
                        <td>{product.PriceAfterDiscount.toLocaleString()}</td>
                        <td>{product.Vat}%</td>
                        <td>{product.VatPrice.toLocaleString()}</td>
                        <td>{product.FinalPrice.toLocaleString()}</td>
                        <td>
                          <button className="btn btn-sm btn-warning mx-1 mb-1" 
                          onClick={() => updateProductModal(product.Id)} 
                          title="ویرایش" 
                          data-bs-toggle="modal"
                          data-bs-target="#editProductModal"
                           >
                            <i className="fa fa-edit"></i>
                          </button>
                          <button className="btn btn-sm btn-danger mx-1 mb-1" onClick={() => removeProductFromInvoice(product.Id)} title="حذف" >
                              <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  ""
                )}

                {/* Add Products to Invoices */}
                {invoiceProductList.length > 0 ? (
                  <>
                    {invoiceProductList.map((product, index) => (
                      <tr key={product.Id}>
                        <td>{index + 1}</td>
                        <td>{product.ProductId}</td>
                        <td>{product.ProductName}</td>
                        <td>{product.Description}</td>
                        <td>{product.UnitName}</td>
                        <td>{product.Quantity}</td>
                        <td>{Number(product.Price).toLocaleString()}</td>
                        <td>{product.PriceBeforeDiscount.toLocaleString()}</td>
                        <td>{product.Discount.toLocaleString()}</td>
                        <td>{product.PriceAfterDiscount.toLocaleString()}</td>
                        <td>{product.Vat}%</td>
                        <td>{product.VatPrice.toLocaleString()}</td>
                        <td>{product.FinalPrice.toLocaleString()}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            type="button"
                            onClick={() => removeProductFromInvoice(product.Id)}
                          >
                            حذف
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </tbody>
              {/* Invoice Totals */}
              <tfoot className="alert alert-primary">
                {invoiceTotals.length !== 0? (
                    <tr>
                      <th>{invoiceTotals.Counter}</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>-</th>
                      <th>{invoiceTotals.QuantitySum}</th>
                      <th>{invoiceTotals.PriceSum.toLocaleString()}</th>
                      <th>{invoiceTotals.PriceBeforeDiscountSum.toLocaleString()}</th>
                      <th>{invoiceTotals.DiscountSum.toLocaleString()}</th>
                      <th>{invoiceTotals.PriceAfterDiscountSum.toLocaleString()}</th>
                      <th>-</th>
                      <th>{invoiceTotals.VatPriceSum.toLocaleString()}</th>
                      <th>{invoiceTotals.FinalPriceSum.toLocaleString()}</th>
                      <th>-</th>
                    </tr>
                ) : (
                  <tr><td></td></tr>
                )}
              </tfoot>
            </table>
            </div>
          </div>
        </div>

        <hr />
        {/* Buttons -------------------------------------------------------------------- */}
        {InvoiceUpdateDetails.length === 0 ? (
          <>
            <button type="submit" className="btn btn-success mx-1 mb-1" onClick={(e) => createInvoice(e)}>
              <i className="fa fa-edit mx-1"></i>
              ثبت در سیستم
              <i className="fa fa-spin fa-spinner d-none mx-1"></i>
            </button>
            <button type="button" className="btn btn-primary disabled mx-1">
              <i className="fa fa-send mx-1"></i>
              ارسال به سامانه مودیان
            </button>

            <input type="hidden" name="TaxId" value="0" />
            
          </>
        ) : (
          <>
            <button type="button" className="btn btn-success mx-1 mb-1" onClick={(e) => updateInvoice(e)}>
              <i className="fa fa-edit mx-1"></i>
              ویرایش کن
              <i className="fa fa-spin fa-spinner d-none mx-1"></i>
            </button>
            <input type="hidden" name="Id" value={InvoiceUpdateDetails.Id} />
            <input type="hidden" name="TaxId" value={InvoiceUpdateDetails.TaxId} />
            <input type="hidden" name="InvoiceSerial" value={InvoiceUpdateDetails.InvoiceSerial} />
          </>
        )}

        <input type="hidden" name="UserId" value="0" />
      </form>
      {/* Modal------------------------------------------------------------------- */}
      <div className="modal fade" id="addProductModal">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header bg-info py-3">
              <h5 className="modal-title text-white" id="addProductModal">
                افزودن کالا به صورتحساب
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#addProductModal"
              >
                <i className="fa fa-close text-white"></i>
              </button>
            </div>
            <div className="modal-body pt-0">
              <div className="mt-3"></div>
              <label htmlFor="searchProducts">جستجوی کالا</label>
              <div className="input-group my-3">
                {/* <FormInput
                  label="جستجوی کالا"
                  name="searchProducts"
                  placeHolder="نام یا کد کالا را وارد کنید"
                  //onChange={searchProducts}
                /> */}
                
                <input type="text" className="form-control form-control-lg fs-6" id="searchProducts" name="searchProducts" placeholder="نام یا کد کالا را وارد کنید"  onKeyDown={handleKeyDown} />
                <button type="button" className="btn btn-primary" onClick={searchProducts}><i className="fa fa-search mx-1"></i>جستجو کن</button>
              </div>
              <div id="modalLoadingContainer" className="alert alert-danger mb-3 d-none">
                در حال دریافت اطلاعات <i className="fa fa-spinner fa-spin mx-1"></i>
              </div>
              <div id="searchResult" className="table-responsive rounded p-2 d-none" style={{overflowX:"unset"}}>
                <table className="table table-striped text-center table-hover">
                  <thead className="position-sticky top-0 bg-white">
                    <tr>
                      <th>شناسه کالا</th>
                      <th>نام کالا</th>
                      <th>مشمول مالیات</th>
                      <th>نوع</th>
                      <th>ارزش افزوده</th>
                      <th className="col-1">واحد کالا</th>
                      <th className="col-1">مبلغ واحد</th>
                      <th className="col-1">تعداد/مقدار</th>
                      <th className="col-1">مبلغ تخفیف</th>
                      <th className="col-1">شرح کالا</th>
                      <th>افزودن</th>
                    </tr>
                  </thead>
                  {searchedProductList.length > 0 ? (
                    <tbody>
                      {searchedProductList.map((product) => (
                        <tr key={product.Id} id={`p-${product.Id}`}>
                          <td className="align-top">{product.ProductId}</td>
                          <td className="align-top">{product.ProductName}</td>
                          <td className="align-top">{product.TaxableOrFree}</td>
                          <td className="align-top">{product.Type}</td>
                          <td className="align-top"><FormInput type="number" id={`Vat-${product.Id}`} name="Vat" className="text-center" autoComplete="false" defValue={product.Vat} /></td>
                          <td className="align-top">
                            <div className="mb-3">
                            <select id="Unit" name="Unit" className="form-control">
                              <option value="" required>
                                انتخاب واحد
                              </option>
                              {productUnits.map((unit) => (
                                <option key={unit.Id} value={unit.UnitCode}>
                                  {unit.UnitName}
                                </option>
                              ))}
                            </select>
                            </div>
                          </td>
                          <td className="align-top">
                            <FormInput
                              id={`Price-${product.Id}`}
                              name="Price"
                              className="text-center"
                              onChange={numberPreview}
                              autoComplete="false"
                            />
                            <span id={`PricePreview-${product.Id}`} className="text-danger"></span>
                          </td>
                          <td className="align-top">
                            <FormInput name="Quantity" className="text-center" autoComplete="false" />
                          </td>
                          <td className="align-top">
                            <FormInput
                              id={`Discount-${product.Id}`}
                              name="Discount"
                              className="text-center"
                              onChange={numberPreview}
                              autoComplete="false"
                            />
                            <span
                              id={`DiscountPreview-${product.Id}`}
                              className="text-danger"
                            ></span>
                          </td>
                          <td className="align-top">
                            <FormInput
                              id={`Description-${product.Id}`}
                              name="Description"
                              className="text-center"
                              autoComplete="false"
                            />
                          </td>
                          <td className="align-top">
                            <button
                              className="btn btn-sm btn-success"
                              autoComplete="false"
                              onClick={() => {
                                addProductToInvoice(product.Id);
                              }}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr></tr>
                      <tr>
                        <td colSpan="11">
                        <div className="alert alert-warning">موردی یافت نشد</div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="editProductModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                ویرایش کالا
              </h5>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#editProductModal"
              >
                <i className="fa fa-close text-white"></i>
              </button>
            </div>
            <div className="modal-body">
              {(ProductToUpdate !== null)?
              <>
                <form>
                  <label>واحد کالا</label>
                  <select id={`Update-Unit-${ProductToUpdate.Id}`} name="Unit" className="form-control mb-3">
                    <option value="" required>
                      انتخاب واحد
                    </option>
                    {productUnits.map((unit) => (
                      <option key={unit.Id} value={unit.UnitCode} selected={(unit.UnitCode === ProductToUpdate.UnitCode? true:false)}>
                        {unit.UnitName}
                      </option>
                    ))}
                  </select>
                  <FormInput
                    id={`Update-Price-${ProductToUpdate.Id}`}
                    name="Price"
                    className="text-end"
                    onChange={numberPreviewInUpdateProduct}
                    defValue={ProductToUpdate.Price}
                    placeHolder="قیمت"
                    label={"قیمت کالا"}
                  />
                  <span id={`PricePreview-${ProductToUpdate.Id}`} className="text-danger text-end d-block"></span>

                  <FormInput 
                  id={`Update-Quantity-${ProductToUpdate.Id}`}
                  name="Quantity" 
                  className="text-end" 
                  defValue={ProductToUpdate.Quantity} 
                  placeHolder="تعداد"
                  label={"تعداد کالا"}
                  />

                  <FormInput
                    id={`Update-Discount-${ProductToUpdate.Id}`}
                    name="Discount"
                    className="text-end"
                    onChange={numberPreviewInUpdateProduct}
                    defValue={ProductToUpdate.Discount}
                    placeHolder="مبلغ تخفیف"
                    label={"مبلغ تخفیف"}
                  />
                  <span
                    id={`DiscountPreview-${ProductToUpdate.Id}`}
                    className="text-danger text-end d-block"
                  ></span>
                </form>
                <button
                className="btn btn-success mt-3"
                onClick={() => {
                  updateInvoiceProduct(ProductToUpdate.Id);
                }}
              >
                <i className="fa fa-edit mx-1"></i>
                ویرایش کن
              </button>
              </>
                :<div className="alert alert-danger">"خطا در دریافت اطلاعات"</div>
              }
            
             </div>
          </div>
        </div>
      </div>
  </div>
  );
  //-------------------------------------------------------------------------
  async function searchProducts() {
    const loadingContainer = document.getElementById("modalLoadingContainer");
    try {
      const searchString = document.getElementById("searchProducts").value;
      const searchResult = document.getElementById("searchResult");
      loadingContainer.classList.remove("d-none");
      const result = await axiosConfig.get(
        `/Client/Invoices/SearchProducts?searchString=${searchString}`
      );
      if (searchString) searchResult.classList.remove("d-none");
      else searchResult.classList.add("d-none");
      setSearchedProductList(result.data.data);
      loadingContainer.classList.add("d-none");
    } catch {
      loadingContainer.classList.add("d-none");
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
  async function addProductToInvoice(id) {
    try {
      // //prevent dublicate product
      // if (invoiceProductList.filter((p) => p.Id === id).length <= 0) {
      //   toast.error("این کالا قبلا به صورتحساب اضافه شده است");
      //   return;
      // }

      let result = await axiosConfig.get(`/Client/Invoices/GetProduct/${id}`);
      let product = result.data.data;
      //--------------------------
      const price = document.querySelector(`#p-${id} #Price-${id}`).value;
      const quantity = document.querySelector(`#p-${id} #Quantity`).value;
      const discount = document.querySelector(`#p-${id} #Discount-${id}`).value;
      const description = document.querySelector(`#p-${id} #Description-${id}`).value;
      const vat = document.querySelector(`#p-${id} #Vat-${id}`).value;

      const unitElement = document.querySelector(`#p-${id} #Unit`);
      const unitCode = unitElement.value;
      const unitName = unitElement.options[unitElement.selectedIndex].text;

      if (price === "" || quantity === "" || discount === "" || unitCode === "" || vat === "")
        return toast.error(PublicConsts.Validation_CompleteAllRequirements);

      // const calcutedVat = Math.round((price * quantity - discount) * (product.Vat / 100));
      let calcutedVat = (price * quantity - discount) * (vat / 100);
      calcutedVat = Math.trunc(calcutedVat);
      
      const newProduct = {
        Id: product.Id,
        ProductId: product.ProductId,
        ProductName: product.ProductName,
        Description: description,
        UnitName: unitName,
        UnitCode: unitCode,
        Price: price,
        Quantity: quantity,
        PriceBeforeDiscount: price * quantity,
        Discount: discount,
        PriceAfterDiscount: price * quantity - discount,
        Vat: vat,
        VatPrice: calcutedVat,
        FinalPrice:
          price * quantity - discount + calcutedVat,
      };
      //New Invoice mode(Add to UI table)--------------------------
      if(InvoiceUpdateDetails.length === 0){
        setInvoiceProductList([...invoiceProductList, newProduct]);
        toast.success(PublicConsts.Info_AddProductToInvoice);
      }
      //Update Invoice mode(Add to Database Table)----------------------
      else{
        newProduct.InvoiceId = InvoiceUpdateDetails.Id;

        await axiosConfig
        .post(`/Client/Invoices/CreateInvoiceProduct`, newProduct)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            loadDataToUpdateInvoice();
          } else toast.error(result.data.message);
        });
      }

    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
  async function removeProductFromInvoice(id) {
    try {
      if(window.confirm("از حذف کالا اطمینان دارید؟"))
    {
      if(InvoiceUpdateDetails.length === 0)
      {
        const filterdProducts = invoiceProductList.filter((p) => p.Id !== id);
        setInvoiceProductList(filterdProducts);
        sumOfInvoiceProducts();
        toast.success(PublicConsts.Info_RemoveProductFromInvoice);
      }
      else{
        await axiosConfig
        .post(`/Client/Invoices/DeleteInvoiceProduct/${id}`)
        .then((result) => {
          if (result.data.status === "success"){
            toast.success(result.data.message);
            loadDataToUpdateInvoice();
          } 
            
          else toast.error(result.data.message);
        })
        .catch((error) => {});
      }
    } 
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
  function sumOfInvoiceProducts() {
    let counter = 0;
    let quantitySum = 0;
    let priceSum = 0;
    let priceBeforeDiscountSum = 0;
    let discountSum = 0;
    let priceAfterDiscountSum = 0;
    let vatSum = 0;
    let finalPriceSum = 0;

    const filteredProductsWithQuantity = invoiceProductList.filter((p) => p.Quantity > 0);
    Object.entries(filteredProductsWithQuantity).forEach(([key, value]) => {
      counter = parseInt(key) + 1;
      quantitySum += parseInt(value.Quantity);
      priceSum += parseInt(value.Price);
      priceBeforeDiscountSum += parseInt(value.PriceBeforeDiscount);
      discountSum += parseInt(value.Discount);
      priceAfterDiscountSum += parseInt(value.PriceAfterDiscount);
      vatSum += parseInt(value.VatPrice);
      finalPriceSum += parseInt(value.FinalPrice);
    });

    const total = {
      Counter: counter,
      QuantitySum: quantitySum,
      PriceSum: priceSum,
      PriceBeforeDiscountSum: priceBeforeDiscountSum,
      DiscountSum: discountSum,
      PriceAfterDiscountSum: priceAfterDiscountSum,
      VatPriceSum: vatSum,
      FinalPriceSum: finalPriceSum,
    };
    setInvoiceTotals(total);
  }
  async function createInvoice(e) {

    const submitButton = e.target
    const spinner = submitButton.querySelector(".fa-spinner")
    try {

      submitButton.classList.add("disabled");
      spinner.classList.remove("d-none")

      const form = document.getElementById("formData");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());

      // formDetails.IsLegalType = formDetails.IsLegalType === "true";
      // formDetails.IsBuyType = formDetails.IsBuyType === "true";
      //-----------------------------------
      const invoiceDetail = {
        InvoiceDetails: formDetails,
        InvoiceProducts: invoiceProductList,
      };
      //-----------------------------------

      await axiosConfig
        .post(`/Client/Invoices/CreateInvoice`, invoiceDetail)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            setInvoiceProductList([]);
            form.reset();
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          const errorsMessages = [];
          if ((error.response.data.title = "One or more validation errors occurred.")) {
            toast.error(PublicConsts.Validation_CompleteAllRequirements);

            //Convert Errors(json) to Array
            Object.keys(errors).forEach((key) => errorsMessages.push(errors[key][0]));

            setvalidationErrors(errorsMessages);
          } else toast.error(PublicConsts.Info_InvoiceRegistrationError);
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    submitButton.classList.remove("disabled");
    spinner.classList.add("d-none")
  }
  async function loadDataToUpdateInvoice() {
    try {
      const query = new URLSearchParams(window.location.search);
      const invoiceId = query.get("id");
      if (invoiceId !== null) {
        await axiosConfig
          .get(`/Client/Invoices/GetInvoice/${invoiceId}`)
          .then((result) => {
            if (result !== null) {
              if (result.data.status === "success"){
                setInvoiceUpdateDetails(result.data.data);
                setInvoiceTotals(result.data.data.InvoiceTotals);
              } 
              else toast.error(result.data.message);
            } else toast.error(PublicConsts.Info_GettingDataError);
          })
          .catch((error) => {});
      }
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
  }
  async function updateInvoice(e) {

    const submitButton = e.target
    const spinner = submitButton.querySelector(".fa-spinner")

    try {
      
      submitButton.classList.add("disabled");
      spinner.classList.remove("d-none")

      const form = document.getElementById("formData");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      const invoiceDetail = {
        InvoiceDetails: formDetails,
        InvoiceProducts: [],
      };
      //-----------------------------------
      await axiosConfig
        .put(`/Client/Invoices/UpdateInvoice`, invoiceDetail)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          const errorsMessages = [];
          if ((error.response.data.title = "One or more validation errors occurred.")) {
            toast.error(PublicConsts.Validation_CompleteAllRequirements);

            //Convert Errors(json) to Array
            Object.keys(errors).forEach((key) => errorsMessages.push(errors[key][0]));

            setvalidationErrors(errorsMessages);
          } else toast.error(PublicConsts.Info_InvoiceRegistrationError);
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage + error);
    }
    submitButton.classList.remove("disabled");
    spinner.classList.add("d-none")
  }
  async function getCustomers() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/Client/Customers/GetCustomers?all=true`)
        .then((result) => {
          if (result.data.status === "success") setCustomerList(result.data.data.Customers);
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function getCustomerDetails() {
    const loadingContainer = document.getElementById("loadingContainer");
    const customerId = document.getElementById("customerList").value;

    try {
      loadingContainer.classList.remove("d-none");
      const result = await axiosConfig.get(`/Client/Customers/GetCustomer/${customerId}`);
      if (result.data.status === "success") {
        const details = result.data.data;
        document.getElementById("Fullname").value = details.Fullname;
        document.getElementById("NationalCode").value = details.NationalCode;
        document.getElementById("PostCode").value = details.PostCode;
        document.getElementById("Province").value = details.Province;
        document.getElementById("City").value = details.City;
        document.getElementById("PhoneNumber").value = details.PhoneNumber;
        document.getElementById("Address").value = details.Address;
      } else toast.error(result.data.message);
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function GetProductUnits() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/Client/Invoices/GetProductUnits`)
        .then((result) => {
          if (result.data.status === "success") setProductUnits(result.data.data);
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  function numberPreview(e) {
    const productId = e.target.id.replace("Price-", "").replace("Discount-", "");
    const previewElement = document.getElementById(e.target.name + "Preview-" + productId);
    var number = e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (number.length > 0) previewElement.innerHTML = number + " ریال";
    else previewElement.innerHTML = "";
  }
  function numberPreviewInUpdateProduct(e) {
    const productId = e.target.id.replace("Update-Price-", "").replace("Update-Discount-", "");
    const previewElement = document.getElementById(e.target.name + "Preview-" + productId);
    var number = e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (number.length > 0) previewElement.innerHTML = number + " ریال";
    else previewElement.innerHTML = "";
  }
  function handleRadioButtons() {
    const _IUD = InvoiceUpdateDetails;

    const invoiceSubject_edit = document.getElementById("edit");
    const invoiceType_first = document.getElementById("firstMethod");
    const invoiceType_second = document.getElementById("secondMethod");
    const buyerType_legal = document.getElementById("legal");
    const buyerType_illegal = document.getElementById("illegal");
    const settlementMethod_cash = document.getElementById("cash");
    const settlementMethod_credit = document.getElementById("credit");
    const settlementMethod_cashCredit = document.getElementById("cashCredit");

    if (_IUD.InvoiceSubject === "اصلاحی") invoiceSubject_edit.checked = true;
    //.........
    if (_IUD.InvoiceType === "نوع اول") invoiceType_first.checked = true;
    else if (_IUD.InvoiceType === "نوع دوم") invoiceType_second.checked = true;
    //.........
    if (_IUD.BuyerType === "حقیقی") buyerType_illegal.checked = true;
    else if (_IUD.BuyerType === "حقوقی") buyerType_legal.checked = true;
    //.........
    // if (_IUD.SellingType === "فروش") sellingType_sale.checked = true;
    // else if (_IUD.SellingType === "خرید") sellingType_buy.checked = true;
    //.........
    if (_IUD.SettlementMethod === "نقد") settlementMethod_cash.checked = true;
    else if (_IUD.SettlementMethod === "نسیه")
      settlementMethod_credit.checked = true;
    else if (_IUD.SettlementMethod === "نقد/نسیه")
      settlementMethod_cashCredit.checked = true;
    //.........
  }
  function toggleTooltip(toolTipId){
    const toolTipElement = document.getElementById(toolTipId);

    if(toolTipId === "invoiceNumberTooltip")
      toolTipElement.innerHTML = "- <b>شماره فاکتور</b> جهت ثبت سیستمی بود و تاثیری در <b>سامانه مودیان مالیاتی</b> ندارد.<br/> - تعداد ارقام محدودیتی ندارد ولی <b>شماره فاکتور</b> نباید تکراری باشد."
    else if(toolTipId === "issueDateTooltip")
      toolTipElement.innerHTML = "<b>تاریخ صدور</b> با <b>تاریخ ثبت در سامانه</b> متفاوت است"

    if(toolTipElement.classList.contains("d-none"))
      toolTipElement.classList.remove("d-none")
    else
    toolTipElement.classList.add("d-none")

  }
  function updateProductModal(id){
    var product = InvoiceUpdateDetails.InvoiceProducts.find(p => p.Id === id);
    setProductToUpdate(product)
  }
  async function updateInvoiceProduct(id){
    const price = document.querySelector(`#Update-Price-${id}`).value;
    const quantity = document.querySelector(`#Update-Quantity-${id}`).value;
    const discount = document.querySelector(`#Update-Discount-${id}`).value;

    const unitElement = document.querySelector(`#Update-Unit-${id}`);
    const unitCode = unitElement.value;
    // const unitName = unitElement.options[unitElement.selectedIndex].text;

    if (price === "" || quantity === "" || discount === "" || unitCode === "")
      return toast.error(PublicConsts.Validation_CompleteAllRequirements);


     //-----------------------------------
     const productDetail = {
      Id: id,
      InvoiceId: InvoiceUpdateDetails.Id,
      Fee: price,
      Am: quantity,
      Dis: discount,
      Mu: unitCode
    };
    //-----------------------------------
    let result = await axiosConfig
      .put(`/Client/Invoices/UpdateInvoiceProduct`, productDetail)
      .catch((error) => {
        //console.log(error);
      });
    if (result.data.status === "success") {
      toast.success(result.data.message);
      loadDataToUpdateInvoice();
    } else toast.error(result.data.message);


  }
  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      searchProducts();
    }
  };

  //-------------------------------------------------------------------------
  //-------------------------------------------------------------------------
  //-------------------------------------------------------------------------
};

export default CreateInvoice;
