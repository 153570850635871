
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import axiosConfig from "../../axiosConfig";
import { PublicConsts } from "../../publicConsts";
import FormInput from '../htmlForm/formInput';
import "../../assets/css/checkout.scss";


const Checkout = () => {

  const inputStyle = {
    width: "100%",
    margin: "10px 0",
    height: "41px",
    border: "1px solid #e1e9ea",
    padding: "5px",
    backgroundColor: "#f7fbfc",
  };

  const [validationErrors, setvalidationErrors] = useState([]);
  let [countdown, setCountdown] = useState(120);
  let [countdownInterval, setCountdownInterval] = useState(null);


  useEffect(() => {
    setPrices();
  }, []);

  //Google Recaptcha
  //---------------------------------------------------------------------------
   //---------------------------------------------------------------------------

//Add google recaptcha script to header
  useEffect(() => {
    const loadReCaptchaScript = () => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${PublicConsts.ReCaptchaSiteKey}`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    };

    // Call this function to load the script
    loadReCaptchaScript();
  });
  //-------------------------------------------------------------------------------------------

  return (
    <div className='checkout-container'>
    <ToastContainer rtl />
    <header id="header" className="header fixed-top bg-white">
          <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
            <a href="/" className="logo d-flex align-items-center me-4">
              <img src="assets/img/landing-new/main-logo.png" alt="logo" />
            </a>
            <a href='/' className='float-end'>بازگشت <i className='fa fa-arrow-left mx-1'></i></a>
          </div>
        </header>
    <div className="container mt-5 pt-5">
    {validationErrors.length > 0 ? (
        <div className="alert alert-danger">
          {validationErrors.map((item) => (
            <ul>
              <li>{item}</li>
            </ul>
          ))}
        </div>
      ) : (
        ""
      )}
      <main>
        <div className="row">
          <div className="col-md-5 col-lg-4 order-md-last">
            <div className="card mb-3">
              <div className="card-body">
                <ul className="mb-3">
                  <li className="d-flex justify-content-between lh-sm mb-3">
                    <div>
                      <h6 className="my-0 fw-bold mb-2">محصول</h6>
                      <small id="planName" className="text-muted"></small>
                    </div>
                    <span id="planPrice" className="text-muted fw-bold">0</span>
                  </li>
                  <li className="d-flex justify-content-between fw-bold">
                    <span>مجموع (تومان)</span>
                    <strong id="finalPrice">0</strong>
                  </li>
                </ul>
                <hr/>
                <div className="input-group mt-4">
                    <input type="text" id="DiscountCode" name="DiscountCode" className="form-control" placeholder="کد تخفیف" />
                    <button type="button" id='discountButton' className="btn button-submit" onClick={applyDiscount}>
                      اعمال
                      <i id="discountButtonSpinner" className='fa fa-spin fa-spinner mx-1 d-none'></i>
                      </button>
                  </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-lg-8">
            <div className="card mb-3">
              <div className="card-body">
                <form id="formData" className="needs-validation" noValidate="">
                  <div className="row g-3">
                    <div className="input-group col-12">
                        <span className="input-group-text">
                          <i className="fa fa-user-circle"></i>
                        </span>
                      <input type="text" className="form-control input-rounded" id="name" name='Name' placeholder="نام کامل/ نام شرکت" required="" />
                      <div className="invalid-feedback">نام کامل/ نام شرکت را وارد کنید.</div>
                    </div>    
                    <div className="input-group col-12">
                        <span className="input-group-text">
                          <i className="fa fa-phone"></i>
                        </span>
                        <input type="text" className="form-control input-rounded" id="mobile" name="Mobile" placeholder="شماره موبایل" required="" maxLength="11"
                          onChange={mobileCheck} />
                        <div id="mobileError" className="d-none text-danger mb-3">
                          <small>شماره موبایل صحیح نمی باشد</small>
                        </div>
                    </div>    
                    <div className="input-group col-12">
                        <span className="input-group-text">
                          <i className="fa fa-envelope"></i>
                        </span>
                      <input type="email" className="form-control input-rounded text-start" id="email" name="Email" placeholder="ایمیل(اختیاری)" />
                      <div className="invalid-feedback">یک ایمیل معتبر وارد کنید.</div>
                    </div>  
                    
    
                  <hr className="my-4" />
                  
    
                  <div id="paymentMethods" className="my-3">
                  <h4 className="mb-3">پرداخت</h4>
                    <div className="form-check">
                      <input id="debit" name="PaymentMethod" value="zarinpal" type="radio" className="form-check-input" required="" defaultChecked/>
                      <label className="form-check-label" htmlFor="debit">کارت بانکی</label>
                    </div>
                    {/* <div className="form-check">
                      <input id="paypal" name="PaymentMethod" value="cart2cart" type="radio" className="form-check-input" required="" disabled/>
                      <label className="form-check-label" htmlFor="paypal">کارت به کارت</label>
                    </div> */}
                  </div>
    
                  <input type='hidden' id="PlanId" name="PlanId" />
    
                  <button id="submitButton" className="w-100 btn button-submit btn-lg" type="button" onClick={verifyFormInputs}>
                    انتقال به درگاه بانکی
                    <i id='submitButtonSpinner' className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="my-5 pt-5 text-muted text-center text-small">
        <p className="mb-1">© 2024 HesabHamrah.ir</p>
        {/* <ul className="list-inline">
          <li className="list-inline-item"><a href="#">درباره</a></li>
          <li className="list-inline-item"><a href="#">تماس</a></li>
          <li className="list-inline-item"><a href="#">راهنما</a></li>
        </ul> */}
      </footer>
    </div>

    {/* Modal------------------------------------------------------------------- */}
    <div className="modal fade" id="verifyMobileModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">تایید شماره موبایل</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target="#verifyMobileModal"
              ></button>
            </div>
            <div className="modal-body">
              <div className='alert alert-success'>
              <i className='fa fa-info-circle mx-1'></i>
                جهت ثبت نام در سامانه، شماره موبایل خود را تایید کنید
                <div><i className='fa fa-mobile mx-1'></i><span>شماره موبایل: </span><span id="mobileNumber" className='fw-bold'></span></div>
                </div>
              <FormInput
                id="verifyMobileCode"
                name="verifyMobileCode"
                className="d-none"
                style={inputStyle}
                autoComplete="off"
                placeHolder="کد تایید دریافت شده را وارد کنید"
              />
              <button
                id="sendVerifyCodeButton"
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => registerAndSendVerifyCode()}
              >
                ارسال کد تایید
                <i id="sendVerifyCodeSpinner" className="fa fa-spin fa-spinner mx-1 d-none"></i>
                <span id="countDownDisplay" className='mx-1'></span>
              </button>
              <button
                id="verifyMobileCodeButton"
                type="button"
                className="btn btn-sm btn-success mx-1 d-none"
                onClick={() => verifyMobile()}
              >
                تایید موبایل
                <i id="verifyMobileCodeSpinner" className="fa fa-spin fa-spinner mx-1 d-none"></i>
              </button>
              
            </div>
          </div>
        </div>
      </div>
    </div>    
  );
  
  function setPrices(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const planName = urlParams.get('pname')
    const planId = urlParams.get('id')
    const planPrice = urlParams.get('price')
    

    document.getElementById("planName").innerHTML = planName
    document.getElementById("PlanId").value = planId
    document.getElementById("planPrice").innerHTML = Number(planPrice).toLocaleString()
    document.getElementById("finalPrice").innerHTML = Number(planPrice).toLocaleString()

    if(planPrice === '0'){
      document.getElementById("submitButton").innerHTML = "تکمیل ثبت نام";
      document.getElementById("paymentMethods").classList.add("d-none");
    }
  }

  async function applyDiscount(e){
    e.preventDefault();

    const codeElement = document.getElementById("DiscountCode");
    if(codeElement.value === "" || codeElement.value === null)
      return toast.error("کد تخفیف وارد نشده است");

    const submitButton = document.getElementById("discountButton");
    const spinner = document.getElementById("discountButtonSpinner");
    try {
      submitButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      //---------------------------------------------------------------------
      const planId = document.getElementById("PlanId").value
      const recaptchaToken = await getRecaptchaToken();
      //---------------------------------------------------------------------
      await axiosConfig
        .post(`/Checkout/ApplyDiscount?code=${codeElement.value}&planId=${planId}&recaptchaToken=${recaptchaToken}`)
        .then((result) => {

          if (result.data.status === "success") {

            toast.success(result.data.message);
            document.getElementById("finalPrice").innerHTML = Number(result.data.data).toLocaleString();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error)
          toast.error(PublicConsts.TryCatch_ErrorMessage);
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
      console.log(error)
    }
    submitButton.classList.remove("disabled");
    spinner.classList.add("d-none");
  }

  function verifyFormInputs(e){
    e.preventDefault()
    const username = document.getElementById("mobile").value;
    const fullName = document.getElementById("name").value;
    const submitButton = document.getElementById("submitButton");
    const mobileNumberText = document.getElementById("mobileNumber");
      if(username === "" || fullName === "")
        return toast.error("مشخصات خواسته شده را وارد کنید");
      else
      submitButton.setAttribute("data-bs-toggle", "modal")
      submitButton.setAttribute("data-bs-target","#verifyMobileModal")
      mobileNumberText.innerHTML = username;
      submitButton.click();
  }

  async function registerAndSendVerifyCode() {
    const sendCodeButton = document.getElementById("sendVerifyCodeButton");
    const spinner = document.getElementById("sendVerifyCodeSpinner");
    const username = document.getElementById("mobile").value;
    try {

      let recaptchaToken = await getRecaptchaToken();
      sendCodeButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      //---------------------------------------------------------------------
      const details = {
        Username: username,
        ReCaptchaToken: recaptchaToken,
      };
      await axiosConfig
        .post(`/Account/Register`, details)
        .then((result) => {
          if (result.data.status === PublicConsts.Result_Success) {
            toast.success(result.data.message);
            
            //countdown
            //----------------------------------
            verifyButtonCountdown();
            setCountdownInterval(setInterval(verifyButtonCountdown, 1000));
            setTimeout(() => {
              clearInterval(countdownInterval);
              sendCodeButton.classList.remove("disabled");
            }, countdown * 1000);
            //--------------------------------

            const verifyCodeInput = document.getElementById("verifyMobileCode");
            const verifyMobileButton = document.getElementById("verifyMobileCodeButton");
            verifyCodeInput.classList.remove("d-none");
            verifyMobileButton.classList.remove("d-none");
          } else {
            toast.error(result.data.message);
            sendCodeButton.classList.remove("disabled");
          }
        })
        .catch((error) => {
          console.log(error);
          sendCodeButton.classList.remove("disabled");
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
      sendCodeButton.classList.remove("disabled");

    }
    spinner.classList.add("d-none");
  }

  async function verifyMobile() {
    const submitButton = document.getElementById("verifyMobileCodeButton");
    const spinner = document.getElementById("verifyMobileCodeSpinner");
    try {
      let reCaptchaToken = await getRecaptchaToken();
      submitButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      const username = document.getElementById("mobile").value;
      const verifyCode = document.getElementById("verifyMobileCode").value;
      //---------------------------------------------------------------------
      const details = {
        username: username,
        verifyCode: verifyCode,
        reCaptchaToken: reCaptchaToken,
      };
      await axiosConfig
        .post(`/Account/VerifyMobile`, details)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            toast.info(PublicConsts.Paymeny_Info_GoingToGateway);
            payment();
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    submitButton.classList.remove("disabled");
    spinner.classList.add("d-none");
  }

  async function payment(){

    const submitButton = document.getElementById("submitButton");
    const spinner = document.getElementById("submitButtonSpinner");
    try {
      submitButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      setvalidationErrors([]);
      //---------------------------------------------------------------------
      const form = document.getElementById("formData");
      const formData = new FormData(form);
      const formDetails = Object.fromEntries(formData.entries());
      const DiscountCode = document.getElementById("DiscountCode").value;
      const recaptchaToken = await getRecaptchaToken();
      //---------------------------------------------------------------------
      await axiosConfig
        .post(`/Checkout/Payment`, {
          order: formDetails,
          discountCode: DiscountCode,
          recaptchaToken: recaptchaToken
        })
        .then((result) => {

          if (result.data.status === "success") {
            //toast.success(result.data.message);
            window.location = result.data.data;
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          const errorsMessages = [];
          if ((error.response.data.title = "One or more validation errors occurred.")) {
            toast.error(PublicConsts.Validation_CompleteAllRequirements);

            //Convert Errors(json) to Array
            Object.keys(errors).forEach((key) => errorsMessages.push(errors[key][0]));

            setvalidationErrors(errorsMessages);
          } else toast.error(PublicConsts.TryCatch_ErrorMessage);

          submitButton.classList.remove("disabled");
    spinner.classList.add("d-none");
        });
    } catch (error) {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
      console.log(error)
      submitButton.classList.remove("disabled");
    spinner.classList.add("d-none");
    }
    
  }

  function mobileCheck(e) {
    const text = e.target.value;

    if (e.target.id === "mobile") {
      const moblieError = document.getElementById("mobileError");
      const submitButton = document.getElementById("submitButton");

      if (text.length !== 11 || text.startsWith("09") === false) {
        moblieError.classList.remove("d-none");
        submitButton.classList.add("disabled");
      } else {
        moblieError.classList.add("d-none");
        submitButton.classList.remove("disabled");
      }
    }
  }

  function verifyButtonCountdown() {
    const element = document.getElementById('countDownDisplay');
    setCountdown(120);
    if (countdown > 0) {
      element.innerHTML = `${countdown}`;
      setCountdown(countdown--);
    } else {
      element.innerHTML = '';
    }
  }

  function getRecaptchaToken() {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(PublicConsts.ReCaptchaSiteKey, { action: 'submit' })
          .then(token => {
            resolve(token);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
  }  
  //-------------------------------------------------
};

export default Checkout;
